import { useEffect, useState } from 'react';
import { Container, Label, RadioInput } from './style'
import { UserTypeAccess } from 'types/UsersTypeAccessType';

interface ShowMoreUsersProps {
  usersTypeAccess?: UserTypeAccess[];
  setUsersToShow?: (arr: UserTypeAccess[]) => void;
  pageSize: number;
}
export const ShowMoreUsers = ({ pageSize, usersTypeAccess, setUsersToShow }: ShowMoreUsersProps) => {
  const [checked, setChecked] = useState(false);
  const [current, setCurrent] = useState(pageSize);
  
  const handleCheckInput = () => {
    setChecked(true);
    
    setTimeout(() => {
      setChecked(false);
      
      setCurrent(current + pageSize);
    }, 1000);
  }

  useEffect(() => {
    if (usersTypeAccess && setUsersToShow) {
      setUsersToShow(usersTypeAccess.slice(0, current));
    }
  }, [current])

  return (
    <Container onClick={handleCheckInput}>
      <RadioInput checked={checked}/>
      <Label>Carregar mais</Label>
    </Container>
  )
}
import styled from 'styled-components'; 

export const Container = styled.div`
  padding: 16px 18px;
  background-color: var(--background);
  
  &:nth-child(even) {
    background-color: var(--searchBackground);
  }
`;

export const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
`;

export const Order = styled.span`
  font-family: 'Roboto', serif;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  text-decoration-line: underline;
  color: #4F4F4F;
`;

export const DateSpan = styled.span`
  font-family: 'Roboto', serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #6D6E71;
`;

export const DownloadContainer = styled.div`
  display: flex;
`;
import { HiCheckCircle } from 'react-icons/hi';
import { Container, Message } from './styles';

export const CopiedToClipboard = () => {
  return (
    <Container>
      <HiCheckCircle color='#00E13F' size={16}/>

      <Message>Copiado!</Message>
    </Container>
  )
}
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useContext } from 'react';
import { NotesContext } from 'contexts/NotesContext/NotesContext';
import { NotesContextType } from 'types/NotesContextType';

const useNotes = () => {
  return useContext<NotesContextType>(NotesContext as any);
};

export default useNotes;

/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Container,
  EmailContent,
  EmailContentBox,
  EmailWrapper,
  ButtonWrapper,
  StyledLink,
  StyledImage,
  Title,
  StyledHiArrowRight,
  NavContainer,
  Tab,
  StyledPricingLink,
} from './styles';
import Financeiro from 'assets/financeiro.svg';
import { capitalize, formatCNPJ, formatCPF } from 'utils';
import useAuth from 'hooks/useAuth';
import { useState } from 'react';
import { REQUEST_TABS } from 'pages/Customer/utils/tabs';
import { OrderDetail } from 'types/OrderDetail';
import { getPricingLink } from 'pages/Customer/utils/getPricingLink';
import PDFMerger from 'pdf-merger-js';
import { SpacedRow } from 'components/CustomersList/styles';
import { StyledHiOutlineDocumentDuplicate } from 'components/CustomerHistoryCard/styles';
import { CopiedToClipboard } from 'components/CopiedToClipboard';
import copy from 'copy-to-clipboard';
import { copyFullMessageCreditAnalysis, fullMessageCreditAnalysis, fullMessageMaterial, copyFullMessageMaterial } from './../../../utils/templateEmailUtil';
import useUsersTypeAccess from 'hooks/useUsersTypeAccess';

export const Requests = ({ name, cpf, cnpj, email, address, clientCategory }: { name: string, cpf?: string, cnpj?: string, email?: string, clientCategory: string, address?: string }) => {
  const [activeTab, setActiveTab] = useState('analytics');
  const [hasCopied, setHasCopied] = useState(false);

  const { auth } = useAuth();

  const { getMyHierarchySuperior } = useUsersTypeAccess();

  const mailToSuperior = getMyHierarchySuperior();

  let endereco = '';
  let cep = '';

  if (address) {
    const addressParts = address.split(',');
    endereco = addressParts.slice(0, -1).join(',');
    cep = addressParts[addressParts.length - 1].trim();
  }

  const handleCopyToClipboard = (type: string) => {
    if (type == 'analytics') {
      copy(copyFullMessageCreditAnalysis(name, cpf, cnpj, auth?.name) as string);
      setHasCopied(true);
    } else {
      copy(copyFullMessageMaterial(name, email ? email : '', endereco, cep, cpf, cnpj, auth?.name) as string);
      setHasCopied(true);
    }

    setTimeout(() => {
      setHasCopied(false);
    }, 2000);
  }

  const checkLink = async (e: any) => {
    e.preventDefault()

    const link = getPricingLink(clientCategory);

    if (link.length === 0) {
      return null
    }

    const merger = new PDFMerger();

    if (Array.isArray(link)) {

      for (const file of link) {
        await merger.add(file);
      }

      await merger.save('tabelas-de-preço');

      return null
    }

    await merger.add(link);
    await merger.save('tabelas-de-preço');
    return null
  }

  const renderActiveTab = () => {
    switch (activeTab) {
      case 'analytics':
        return (
          <Container>
            <EmailWrapper>
              <Title>Análise de crédito</Title>

              <EmailContentBox>
                <SpacedRow>
                  <EmailContent>Olá!</EmailContent>

                  {!hasCopied ? (
                    <StyledHiOutlineDocumentDuplicate
                      size={24}
                      color='#6D6E71'
                      onClick={() => handleCopyToClipboard('analytics')}
                    />
                  ) : (
                    <CopiedToClipboard />
                  )}
                </SpacedRow>

                <EmailContent> Solicito análise de crédito para o cliente abaixo:</EmailContent>

                <EmailContent style={{ marginTop: '18px' }}>Cliente: {capitalize(name.toLowerCase())}</EmailContent>

                <EmailContent>Documento: {cpf ? 'CPF' : 'CNPJ'} {cpf ? formatCPF(cpf) : cnpj ? formatCNPJ(cnpj) : null}</EmailContent>

                <EmailContent>Valor total de R$ (Informe o valor)</EmailContent>

                <EmailContent>Condições de pagamento: (Informe a condição de pagamento)</EmailContent>

                <EmailContent style={{ marginTop: '18px' }}>*Solicitação enviada a partir do MedSearch.</EmailContent>
              </EmailContentBox>

              <ButtonWrapper>
                {clientCategory ? (
                  <StyledPricingLink onClick={(e: any) => checkLink(e)}>Ver tabela de preços</StyledPricingLink>
                ) : (
                  <div />
                )
                }

                <StyledLink href={`mailto:credito@medbeauty.com.br?subject=Análise de crédito&body=${fullMessageCreditAnalysis(name, cpf, cnpj, auth?.name)}`} target='_blank' rel="noreferrer">Abrir e-mail <StyledHiArrowRight size={16} /></StyledLink>
              </ButtonWrapper>
            </EmailWrapper>

            <StyledImage src={Financeiro} />
          </Container>
        )

      case 'material':
        return (
          <Container>
            <EmailWrapper>
              <Title>Solicitação de material</Title>

              <EmailContentBox>
                <SpacedRow>
                  <EmailContent>Olá!</EmailContent>

                  {!hasCopied ? (
                    <StyledHiOutlineDocumentDuplicate
                      size={24}
                      color='#6D6E71'
                      onClick={() => handleCopyToClipboard('material')}
                    />
                  ) : (
                    <CopiedToClipboard />
                  )}
                </SpacedRow>

                <EmailContent> Solicito envio de material para o cliente abaixo:</EmailContent>

                <EmailContent style={{ marginTop: '18px' }}>Cliente: {capitalize(name.toLowerCase())}</EmailContent>

                <EmailContent>Documento: {cpf ? 'CPF' : 'CNPJ'} {cpf ? formatCPF(cpf) : cnpj ? formatCNPJ(cnpj) : null}</EmailContent>

                <EmailContent>Email: {email}</EmailContent>

                <EmailContent style={{ marginTop: '18px' }}>Material: [variável a ser inclusa pelo consultor]</EmailContent>

                <EmailContent>Endereço: {endereco}</EmailContent>

                <EmailContent>CEP: {cep}</EmailContent>

                <EmailContent style={{ marginTop: '36px' }}>*Solicitação enviada a partir do MedSearch.</EmailContent>
              </EmailContentBox>

              <ButtonWrapper>
                <StyledLink href={`mailto:${mailToSuperior ? mailToSuperior : ''}?subject=Solicitação de material&body=${fullMessageMaterial(
                  name, email ? email : '', endereco, cep, cpf, cnpj, auth?.name)}`} target='_blank' rel="noreferrer">Abrir e-mail <StyledHiArrowRight size={16} /></StyledLink>
              </ButtonWrapper>
            </EmailWrapper>

            <StyledImage src={Financeiro} />
          </Container>
        )
    }
  }

  return (
    <>
      <NavContainer>
        {REQUEST_TABS.map(({ type, name }) => {
          return (
            <Tab onClick={() => setActiveTab(type)} key={type} style={activeTab === type ? { fontWeight: 600, color: '#6460AA', background: '#fff' } : {}}>{name}</Tab>
          )
        })}
      </NavContainer>

      {renderActiveTab()}
    </>
  )
}
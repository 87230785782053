import { useEffect, useState } from 'react';
import { SmallScreenView } from './Mobile/SmallScreenView';
import { DesktopView } from './Desktop/DesktopView';
import useCustomers from 'hooks/useCustomers';
import { Loader } from 'components/Loader';
import { Header } from 'components/Header';
import { Customer as CustomerType } from 'types/CustomersContextType';
import { ErrorScreen } from 'components/ErrorScreen';
import useNotes from 'hooks/useNotes';
import useUsersTypeAccess from 'hooks/useUsersTypeAccess';

export const Customer = () => {
  const [width, setWidth] = useState<number>(window.innerWidth);
  const [currentTab, setCurrentTab] = useState('details');
  
  const { isAdmin, isStandard, isManager, isFranchise } = useUsersTypeAccess();
  const { singleCustomer, loading, dateOffset, getCustomerByDocument, setSingleCustomer } = useCustomers();
  const { note, profile, noteDeprecated } = useNotes();

  const customerId = window.location.pathname.split('/')[2].toString();

  useEffect(() => {
    getCustomerByDocument(customerId);
  }, [customerId, dateOffset]);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);

    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);

  const isDesktop = width > 1025;

  if (loading) {
    return (
      <>
        <Header />

        <Loader 
          message1='Estamos carregando os'
          message2='detalhes do cliente...'
        />
      </>
    )
  }

  if (!singleCustomer.isMyClient && !isAdmin() && !isStandard() && !isManager() && (singleCustomer.clientCategory && !isFranchise(singleCustomer.clientCategory))) {
    return (
      <>
        <Header />

        <ErrorScreen 
          title="Algo deu errado..."
          message="Mas logo será resolvido."
          status={403}
        />
      </>
    )
  }

  return singleCustomer ?
    isDesktop 
      ? <DesktopView 
          id={singleCustomer.id}
          name={singleCustomer.name}
          clientCategory={singleCustomer.clientCategory}
          cpf={singleCustomer.cpf}
          cnpj={singleCustomer.cnpj}
          lastShopping={singleCustomer.lastShopping}
          averageTicket={singleCustomer.averageTicket}
          birthdate={singleCustomer.birthdate}
          phone={singleCustomer.phone}
          email={singleCustomer.email}
          whatsapp={singleCustomer.whatsapp}
          address={singleCustomer.address}
          registerDate={singleCustomer.registerDate}
          cashback={singleCustomer.cashback}
          orders={singleCustomer.orders}
          onClick={(customer: CustomerType) => setSingleCustomer(customer)}
          isMyClient={singleCustomer.isMyClient}
          mostShoppedItems={singleCustomer.mostShoppedItems}
          currentTab={currentTab}
          setCurrentTab={setCurrentTab}
          note={note}
          noteDeprecated={noteDeprecated}
          profile={profile}
        /> 
      : <SmallScreenView
          id={singleCustomer.id}
          name={singleCustomer.name}
          clientCategory={singleCustomer.clientCategory}
          cpf={singleCustomer.cpf}
          cnpj={singleCustomer.cnpj}
          lastShopping={singleCustomer.lastShopping}
          averageTicket={singleCustomer.averageTicket}
          birthdate={singleCustomer.birthdate}
          phone={singleCustomer.phone}
          email={singleCustomer.email}
          whatsapp={singleCustomer.whatsapp}
          address={singleCustomer.address}
          registerDate={singleCustomer.registerDate}
          cashback={singleCustomer.cashback}
          orders={singleCustomer.orders}
          onClick={(customer: CustomerType) => setSingleCustomer(customer)}
          isMyClient={singleCustomer.isMyClient}
          mostShoppedItems={singleCustomer.mostShoppedItems}
          currentTab={currentTab}
          setCurrentTab={setCurrentTab}
          note={note}
          noteDeprecated={noteDeprecated}
          profile={profile}
        />
    : (
      <>
        <Header />

        <ErrorScreen 
          title="Algo deu errado..."
          message="Mas logo será resolvido."
          status={400}
        />
      </>
    );
}
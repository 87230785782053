import styled from 'styled-components';
import { HiChevronDown } from 'react-icons/hi';

export const Container = styled.div`
  padding: 0 12px;
  height: 32px;
  border: 2px solid var(--dividerColor);
  background-color: var(--background);
  border-radius: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  position: relative;
`;

export const Label = styled.span`
  color: var(--primaryTextColor);
  font-family: 'Roboto', serif;
  font-weight: 400;
  font-size: 16px;
  margin-right: 6px;
`;

export const StyledFilterIcon = styled(HiChevronDown)`
`;

export const DropdownContainer = styled.div`
  background-color: var(--background);
  box-shadow: 0px 4px 12px rgba(146, 140, 151, 0.25);
  border-radius: 4px;
  position: absolute;
  top: 40px;
  right: 10px;
  z-index: 3;
`;

export const FilterList = styled.ul`
  display: flex;
  flex-direction: column;
`;

export const FilterItem = styled.li`
  padding: 13px 16px;
  color: var(--primaryTextColor);
  cursor: pointer;

  &:hover {
    background-color: var(--searchBackground);
  }
`;
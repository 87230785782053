import { Button } from 'components/Button';
import styled from 'styled-components';

export const Dimmer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(17, 24, 39, 0.8);
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Container = styled.div`
  background: var(--background);
  width: 70%;
  border-radius: 8px;
  padding: 24px 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 30px;

  /* Desktop */
  @media (min-width:1024px) {
    width: 450px;
  }
`;

export const Message = styled.span`
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  margin-top: 4px;
  color: var(--primaryTextColor);
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  margin-top: 16px;
`;

export const Cancel = styled.span`
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 14px;
  text-decoration-line: underline;
  color: var(--highlightColor);
  cursor: pointer;
`;

export const StyledButton = styled(Button)`
  max-width: 150px;
`;
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useContext } from 'react';
import { CustomersContext } from 'contexts/CustomersContext/CustomersContext';
import { CustomersContextType } from 'types/CustomersContextType';

const useCustomers = () => {
  return useContext<CustomersContextType>(CustomersContext as any);
};

export default useCustomers;

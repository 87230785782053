import { HiOutlineChevronLeft, HiOutlineChevronRight } from 'react-icons/hi';
import styled from 'styled-components';

export const Container = styled.div<{ isCheckIn: boolean }>`
  display: flex;
  justify-content: end;
  align-items: center;
  padding: 8px;
  
  /* Tablet */
  @media (min-width:768px) {
    width: 95%;
  }
  
  /* Desktop */
  @media (min-width:1025px) {
    width: ${({ isCheckIn}) => isCheckIn ? '100%' : '750px'};
  }
`;

export const NumberPages = styled.span<{ isActive?: boolean, isDots?: boolean }>`
  font-family: 'Roboto';
  font-style: normal;
  font-size: 14px;
  line-height: 150%;
  text-align: center;
  cursor: ${({ isDots }) => (isDots ? 'undefined' : 'pointer')};
  color: ${({ isActive }) => (isActive ? 'var(--highlightColor)' : 'var(--primaryTextColor)')};
  font-weight: ${({ isActive }) => (isActive ? '700' : '400')};
  padding: 0 8px;
`;

export const ArrowBack = styled(HiOutlineChevronLeft)<{cursorPointer?: boolean}>`
cursor: ${({ cursorPointer }) => (cursorPointer ? 'pointer' : 'undefined')};
  height: 18px;
  width: 18px;
  color: #6D6E71;
`;

export const ArrowForward = styled(HiOutlineChevronRight)<{cursorPointer?: boolean}>`
  cursor: ${({ cursorPointer }) => (cursorPointer ? 'pointer' : 'undefined')};
  height: 18px;
  width: 18px;
  color: #6D6E71;
`;
import { GoAlert } from 'react-icons/go';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 12px;
  height: 36px;
  background: var(--searchBackground);
  border: 1px solid var(--dividerColor);
  border-radius: 4px;
  cursor: pointer;
  margin-right: 12px;

  /* Desktop */
  @media (min-width:1025px) {
    height: calc(46px - 16px);  
  }
`;

export const Text = styled.span`
  color: var(--highlightColor);
  margin-right: 12px;
  margin-left: 5px;
`;

export const TooltipContainer = styled.div`
  visibility: hidden;
  position: absolute;
  color: var(--primaryTextColor); 
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  line-height: 150%;

  &:before {
    content: "";
    width: 0;
    height: 0;
    right: 20px;
    top: 62px;
    position: absolute;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.1);
    border: 10px solid transparent;
    transform: rotate(315deg);
    transition: border 0.4s ease-in-out;
  }
`;

export const StyledGoAlert = styled(GoAlert)`
  cursor: pointer;
`;

export const Wrapper = styled.div`
display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: relative;

  & ${StyledGoAlert}:hover + ${TooltipContainer} {
    visibility: visible;
    background: var(--background);
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.1);
    padding: 16px;
    width: 200px;
    border-radius: 8px;
    margin-top: -105px;
    margin-right: -20px;
    
    &:before {
      border-color: transparent transparent var(--background) var(--background);
    }
  }
`;

export const TooltipText = styled.span``;

export const HighlightedText = styled.span`
  font-weight: bold;
`;
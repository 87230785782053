import styled from 'styled-components';
import { Button } from 'components/Button';

export const EmptyListWrapper = styled.div`
  margin: 0 16px;
  text-align: center;
  height: auto;
  word-wrap: break-word;
  width: 95%;
  
  /* Desktop */
  @media (min-width:1025px) {
    width: 750px;
  }
`;

export const EmptyList = styled.span`
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  line-height: 150%;
  color: var(--tertiaryTextColor);
`;

export const ContainerUser = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 100px;
  
  /* Desktop */
  @media (min-width:1025px) {
    margin-left: 200px;
    margin-top: 110px;
  }

  background: white;
  padding: 40px 0;
`;

export const ContainerLogo = styled.section`
  padding: 16px 0px;
  background: var(--searchBackground);
  width: calc(100% - 200px);
  margin-left: 200px;
  position: fixed;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const LogoMedSearch = styled.img`
  width: 194px;
  height: 72px;
`;

export const UserButtonContainer = styled.div`
  display: flex;
  justify-content: start;
  width: 95%;
  
  /* Desktop */
  @media (min-width:1025px) {
    width: 780px;
  }
`;

export const ContainerTitleDesc = styled.div`
  display: flex;
  flex-direction column;
  width: 780px; 
  margin-bottom: 40px;
  gap: 20px;
`;

export const TextTitle = styled.span`
  color: #6D6E71;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; 
`
;

export const TextDesc = styled.span`
  color: #6D6E71;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
`;

export const ReportButton = styled(Button)`
  height: 46px;
  justify-content: center;
  padding: 0 16px;
  max-width: 223px;
  font-family: 'Roboto';
  font-weight: 700;
  font-size: 16px;
  line-height: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 16px;

  /* Mobile */
  @media (max-width:767px) {
    max-width: 100%;
  }
`;

export const TextButton = styled.span`
  /* Mobile */
  @media (max-width:767px) {
    margin-left: 10px;
  }
`;

export const ContainerSearchUser = styled.div`
  margin: 30px 0;
  display: flex;
  justify-content: start;
  flex-direction: column;
  width: 95%;
  top: 0;
  
  /* Desktop */
  @media (min-width:1025px) {
    width: 780px;
  }
`;

export const SearchTitle = styled.span`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  color: var(--primaryTextColor);
`;

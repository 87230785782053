import styled from 'styled-components';
import { HiArrowRight } from 'react-icons/hi';

export const Container = styled.div`
  background-color: var(--background);
  padding: 32px 16px;
  display: flex;
  flex-direction: column;

  /* Tablet */
  @media (min-width:768px) {
    margin: 0;
    padding: 32px;
  }
`;

export const Title = styled.h1`
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  color: var(--secondaryTextColor);
  margin-bottom: 12px;
  margin-left: 8px;
`;

export const EmailContentBox = styled.div`
  border: 1px solid var(--searchBackground);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.06), 0px 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 16px;
  margin-bottom: 24px;
`;

export const EmailContent = styled.p`
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #4f4f4f;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  width: 100%;
`;

export const StyledPricingLink = styled.span`
  width: 100%;
  height: 42px;
  background: #FFF;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #6460AA;
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  font-size: 16px;
  cursor: pointer;
  transition: opacity 0.2s;
  border: 2px solid #6460AA;
  margin-right: 24px;

  &:hover {
    opacity: 0.9;
  }
`;

export const StyledLink = styled.a`
  height: 46px;
  width: 100%;
  background: #6460AA;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  font-size: 16px;
  cursor: pointer;
  transition: opacity 0.2s;

  &:hover {
    opacity: 0.9;
  }
`;

export const StyledImage = styled.img`
  height: 345px;
`;

export const StyledHiArrowRight = styled(HiArrowRight)`
  margin-left: 10px;
`;

export const NavContainer = styled.nav`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--background);
  margin-top: 40px;
  padding: 24px 35px;

  /* Tablet */
  @media (min-width:768px) {
    margin-top: 0;
    justify-content: flex-start;
    background-color: var(--dividerColor);
    height: 70px;
    padding: 0;
  }
`;

export const Tab = styled.div`
  color: var(--secondaryTextColor);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Source Sans Pro', serif;
  font-weight: 400;
  font-size: 16px;
  color: var(--secondaryTextColor);
  border: 1px solid var(--dividerColor);
  border-radius: 8px 0px 0px 8px;
  height: 40px;
  width: 153px;
  padding: 0 12px;

  &:last-child {
    border-radius: 0px 8px 8px 0px;
  }

  @media (min-width:768px) {
    padding: 0 30px;
    height: 100%;
    margin-right: 20px;
    min-width: 150px;
    font-size: 20px;
    border-radius: 0;
    border: none;
  }
`;

export const Divider = styled.div`
  border: 1px solid var(--dividerColor);
  margin: 16px -24px;
`;

export const FilterContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  background-color: var(--searchBackground);
  padding: 24px 15px;

  /* Tablet */
  @media (min-width:768px) {
    padding: 24px 32px;
  }
`;

import styled from 'styled-components';
import { Button } from 'components/Button';
import { HiChevronRight, HiOutlineDocumentDuplicate } from 'react-icons/hi';

export const ContactsContainer = styled.div`
  margin-top: 30px;
  background-color: var(--background);

  /* Tablet */
  @media (min-width:768px) {
    margin-top: 0;
    border: 1px solid #F5F5F5;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    padding: 8px 32px;
  }
`;

export const ContactsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  border-top: 1px solid var(--dividerColor);
  border-bottom: 1px solid var(--dividerColor);

  /* Tablet */
  @media (min-width:768px) {
    border-top: none;
    border-bottom: none;
    padding: 16px 0;
  }
`;

export const ContactItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  /* Tablet */
  @media (min-width:768px) {
    max-width: 400px;
  }
`;

export const ContactText = styled.span`
  margin-left: 8px;
  font-size: 16px;
  line-height: 125%;
  color: var(--secondaryTextColor);
  
  /* Tablet */
  @media (min-width:768px) {
    font-size: 20px;
  }
`;

export const EmailWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  border-bottom: 1px solid var(--dividerColor);

  /* Tablet */
  @media (min-width:768px) {
    justify-content: flex-start;
    border-bottom: none;
    padding: 16px 0;
  }
`;

export const StyledHiOutlineDocumentDuplicate = styled(HiOutlineDocumentDuplicate)`
  /* Tablet */
  @media (min-width:768px) {
    margin-left: 36px;
  }
`;

export const AddressAndButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  border-bottom: 1px solid var(--dividerColor);

  /* Tablet */
  @media (min-width:768px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: none;
    padding: 16px 0;
  }
`;

export const AddressText = styled.span`
  margin-left: 8px;
  font-size: 16px;
  line-height: 125%;
  color: var(--secondaryTextColor);
`;

export const StyledButton = styled(Button)`
  margin-top: 24px;

  /* Tablet */
  @media (min-width:768px) {
    width: 163px;
    margin-top: 0;
  }
`;

export const MostShoppedItemsContainer = styled.div`
  padding: 16px 12px;
  padding-bottom: 27px;
  background-color: var(--background);
  border: 1px solid #F5F5F5;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  margin: 32px 16px;
  margin-bottom: 40px;

  /* Tablet */
  @media (min-width:768px) {
    padding: 24px 32px;
    margin: 32px 0;
    margin-bottom: 40px;
  }
`;

export const MostShoppedItemsHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
`;

export const MostShoppedItemsTitle = styled.span`
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  color: var(--secondaryTextColor);
`;

export const MostShoppedItemsMessage = styled.span`
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: var(--primaryTextColor);
`;

export const MostShoppedItemsWrapper = styled.ul`

`;

export const MostShoppedItemsFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 11px;
  cursor: pointer;

  /* Tablet */
  @media (min-width:768px) {
    justify-content: space-between;
  }
`;

export const MostShoppedItemsFooterText = styled.span`
  text-decoration-line: underline;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;

  /* Tablet */
  @media (min-width:768px) {
    text-align: left;
    text-decoration-line: none;
  }
`;

export const StyledHiChevronRight = styled(HiChevronRight)``;

export const MostShoppedEmptyList = styled.span`
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: var(--primaryTextColor);
  display: block;
  text-align: center;
  margin-bottom: -11px;
`;

export const ComercialData = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 16px;

  /* Tablet */
  @media (min-width:768px) {
    flex-direction: row;
    justify-content: space-evenly;
  }
`;

export const Subtitle = styled.span`
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: var(--primaryTextColor);
  margin-top: 12px;
  text-align: center;
  margin-bottom: 40px;
  display: block;
`;

export const SubtitleMark = styled.span`
  color: var(--alert);
`;

export const CustomerHistoryContainer = styled.section`
  margin-top: 40px;
`;

export const HistoryHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
  padding: 0 16px;
`;

export const HistoryTitle = styled.span`
  color: var(--secondaryTextColor);
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
`;

export const HistoryWrapper = styled.div`
  padding: 0 16px;
`;

import styled from 'styled-components';
import { Button } from 'components/Button';
import { FiEdit } from 'react-icons/fi';

export const NotesContainer = styled.div`
  height: 100%;
  background: var(--background);
  padding: 24px;
  display: flex;
  flex-direction: column;
`;

export const NotesHeader = styled.span`
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  color: var(--secondaryTextColor);
  align-self: flex-start;
`;

export const StyledFiEdit = styled(FiEdit)`
  cursor: pointer;
`;

export const StyledButton = styled(Button)`
  margin-top: 24px;
  margin-bottom: 48px;
  width: 163px;

  /* Tablet */
  @media (min-width:768px) {
    width: 163px;
    margin-top: 0;
  }
`;

export const ScrollableList = styled.div`
  margin-top: 48px;
  max-height: 500px;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background: #D9D9D9;
    border-radius: 16px;
  }
`;

export const Message = styled.span`
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  margin-bottom: 24px;
  color: var(--primaryTextColor);
`;


export const NavContainer = styled.nav`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--background);
  margin-top: 40px;
  padding: 24px 35px;

  /* Tablet */
  @media (min-width:768px) {
    margin-top: 0;
    justify-content: flex-start;
    background-color: var(--dividerColor);
    height: 70px;
    padding: 0;
  }
`;

export const Tab = styled.div`
  color: var(--secondaryTextColor);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Source Sans Pro', serif;
  font-weight: 400;
  font-size: 16px;
  color: var(--secondaryTextColor);
  border: 1px solid var(--dividerColor);
  border-radius: 8px 0px 0px 8px;
  height: 40px;
  width: 153px;
  padding: 0 12px;

  &:last-child {
    border-radius: 0px 8px 8px 0px;
  }

  @media (min-width:768px) {
    padding: 0 30px;
    height: 100%;
    margin-right: 20px;
    min-width: 150px;
    font-size: 20px;
    border-radius: 0;
    border: none;
  }
`;

export const EmptyProfileContainer = styled.div`
  background: var(--background);
  border-radius: 8px;
  padding: 40px 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const ProfileMessage = styled.span`
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  text-align: center;
  color: var(--primaryTextColor);
`;


export const ProfileContainer = styled.div`
  background: var(--background);
  border-radius: 8px;
  padding: 40px 32px;
  min-width: 360px;
`;


export const ProfileHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 24px;
`;

export const ProfileTitle = styled.span`
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  color: var(--secondaryTextColor);
  align-self: flex-start;
`;

export const ProfileContent = styled.p`
  width: 100%;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: var(--primaryTextColor);
  margin-bottom: 32px;
`;

export const ProfileInterests = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-top: 12px;
`;

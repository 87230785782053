import Avatar from 'react-avatar';
import { HiOutlineBell, HiBell } from 'react-icons/hi2';
import {
  Bell,
  Container,
  ContainerButton,
  ContainerModalOption,
  Dimmer,
  NoteAuthor,
  NoteMessage,
  NoteMessageWrapper,
  NoteWrapper,
  OptionButton,
  OptionCancelButton,
  SeeMoreButton,
  StyledBsBell,
  StyledBsFillBellFill,
  TextTitleModal,
} from './styles';
import { handleName, getNormalizedDate, truncate } from 'utils';
import { useEffect, useRef, useState } from 'react';
import { useWindowDimensions } from 'hooks/useWindowDimensions';
import { api } from 'config/api';
import { ClipLoader } from 'react-spinners';
import { NotesModal } from 'components/NotesModal';
import useNotes from 'hooks/useNotes';

interface NoteProps {
  id?: string;
  name: string;
  customerName?: string;
  customerDocument?: string;
  createAt: string;
  annotation: string;
  isNotification?: boolean;
}

export function Note({ id, name, customerName, customerDocument, createAt, annotation, isNotification }: NoteProps) {
  const [isAlertCancelNotification, setIsAlertCancelNotification] = useState(false);
  const [isModalNotificationOpen, setIsModalNotificationOpen] = useState(false);
  const [isSeeingMore, setIsSeeingMore] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isNotificationAux, setIsNotificationAux] = useState(isNotification ? isNotification : false);
  const [isNotificationCanceled, setIsNotificationCanceled] = useState(false);

  const { notification, setNotification } = useNotes();

  const { width } = useWindowDimensions();

  const isMobile = width < 768;

  const bellRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const bellElement = bellRef.current;

    if (bellElement) {
      if (isNotificationAux) {
        bellElement.classList.add('animate');
      } else {
        bellElement.classList.remove('animate');
      }
    }
  }, [isNotificationAux]);

  const handleBellClick = async () => {
    if (isNotificationAux) {
      setIsAlertCancelNotification(true);
    } else {
      setIsModalNotificationOpen(true);
    }
  };

  const updateNoteState = async () => {
    try {
      setLoading(true);

      const body = {
        active: false,
        isNotification: false,
      }

      await api.put(`/customer/annotation/${id}`, body);

    } catch (error) {
      console.log(error)
    } finally {
      setTimeout(() => {
        setLoading(false);
        setIsNotificationAux(!isNotificationAux);

        const updatedList = notification.filter(item => item.id !== id);
        setNotification(updatedList);
      }, 1500);
    }
  }

  const seeMore = () => {
    return (
      <>
        {truncate(annotation, isSeeingMore ? 280 : 50)}

        <SeeMoreButton onClick={() => setIsSeeingMore(!isSeeingMore)}>{isSeeingMore ? '  menos' : '  mais'}</SeeMoreButton>
      </>
    )
  }

  const renderAlertCancelNotificationModal = () => {
    return (
      <Dimmer onClick={() => setIsAlertCancelNotification(false)}>
        <ContainerModalOption onClick={(e: React.MouseEvent) => e.stopPropagation()}>
          <TextTitleModal>
            Esta ação excluirá o lembrete desta notificação. Deseja continuar?
          </TextTitleModal>

          <ContainerButton>
            <OptionCancelButton onClick={() => setIsAlertCancelNotification(false)}>
              Cancelar
            </OptionCancelButton>

            <OptionButton onClick={() => { setIsAlertCancelNotification(false); updateNoteState() }}>
              Excluir
            </OptionButton>
          </ContainerButton>

        </ContainerModalOption>
      </Dimmer>
    )
  }

  const renderNotificationModal = () => {
    return (
      <NotesModal
        onCloseModal={() => setIsModalNotificationOpen(false)}
        customerName={customerName ? customerName : ''}
        customerDocument={customerDocument}
        annotation={annotation}
        id={id}
        onClick={() => setIsNotificationAux(!isNotificationAux)}
      />
    )
  }

  return (
    <>
      <Container>
        <NoteWrapper>
          <Avatar name={handleName(name)} round size='40px' style={{ fontSize: '16px', fontWeight: 600 }} />

          <NoteMessageWrapper>
            <NoteAuthor>{name} - {getNormalizedDate(createAt, true)}</NoteAuthor>

            <NoteMessage>{isMobile && annotation.length > 50 ? seeMore() : annotation}</NoteMessage>
          </NoteMessageWrapper>

        </NoteWrapper>

        {isNotification !== undefined && (
          <>
            {
              loading ? (
                <ClipLoader
                  color='#6460AA'
                  size={14}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                />
              ) : <Bell isClicked={isNotificationAux} ref={bellRef}>
                {isNotificationAux ?
                  <HiBell color='#6460AA' size={24} onClick={() => handleBellClick()} />
                  :
                  <HiOutlineBell color='#6460AA' size={24} onClick={() => handleBellClick()} />
                }
              </Bell>}
          </>

        )}


      </Container>

      {isAlertCancelNotification && renderAlertCancelNotificationModal()}
      {isModalNotificationOpen && renderNotificationModal()}
    </>
  )
}

import { HiOutlineInformationCircle } from 'react-icons/hi';
import styled from 'styled-components';

export const ContainerItem = styled.div<{ isOutOfDate?: boolean }>`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-left: ${({ isOutOfDate }) => (isOutOfDate ? '3px solid #6D6E71' : '3px solid #F3716D')};
  padding: 5px 0 5px 10px;
  margin-bottom: 16px;
  cursor: pointer;

  &:hover  {
    background-color: #ECECEC;
  }
`;

export const ContentItem = styled.div`
  display: flex;
  flex-direction: column;
  width: 85%;
  gap: 3px;
`;

export const StyledHiOutlineInformationCircle = styled(HiOutlineInformationCircle)`
  }
`;

export const TooltipText = styled.span`
  font-weight: 900;
  display: block;
`;

export const HighlightedText = styled.span`
  font-weight: 900;
  font-size: 16px;
  color: black;
  display: block;
`;

export const TooltipContainer = styled.div`
  visibility: hidden;
  position: absolute;
  color: var(--primaryTextColor); 
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  z-index: 1000000;
  display: flex;
  flex-direction: column; 

`;

export const TooltipWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: relative;
  & ${StyledHiOutlineInformationCircle}:hover + ${TooltipContainer} {
    visibility: visible;
    background: var(--searchBackground);
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.1);
    padding: 16px;
    width: 150px;
    border-radius: 8px;
    top: -20px;
    right: 50px;
  }
`;

export const WrapperIcon = styled.div`
  padding: 5px;
`;

export const ItemTitleName = styled.span<{ isOutOfDate?: boolean }>`
  color: ${({ isOutOfDate }) => (isOutOfDate ? '#B4B4B4' : '#6460AA')};
  font-size: 16px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const ItemAnnotation = styled.div<{ isOutOfDate?: boolean }>`
  color: ${({ isOutOfDate }) => (isOutOfDate ? '#B9B9BB' : '#6D6E71')};
  font-size: 14px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const DropdownMenu = styled.div`
  position: absolute;
  top: -30px;
  right: 30px;
  width: 146px;
  z-index: 10;
  background: #FFFFFF;
  box-shadow: 0px 4px 12px rgba(146, 140, 151, 0.25);
  border-radius: 4px;
  border: none;
  margin-top: 8px;
  list-style: none;
`;

export const DropdownItem = styled.div<{ active?: boolean }>`
  padding: 10px 16px;
  cursor: pointer;
  font-family: Source Sans Pro;
  font-weight: 400;
  font-size: 16px;
  color:  var(--primaryTextColor);

  &:hover {
    background-color: #ECECEC;
  }
`;
/* eslint-disable @typescript-eslint/no-explicit-any */
import { HighlightedElement } from 'components/HighlightedElement';
import { useEffect } from 'react';
import { HiX } from 'react-icons/hi';
import { ModalProps } from 'types/ModalProps';
import { Product } from 'types/Product';
import { 
  Container, 
  Dimmer,
  ModalHeader,
  Empty,
  Title,
  Clickable,
  Row,
  TypeLabel,
  Description,
  Divider,
  ProductsContainer,
  Label,
  PaymentMethodLabel,
  ProductRow,
  PrimaryText,
  HighlightedLabel,
  ImpactText,
} from './styles';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const Modal = ({ code, items, saleType, discount, shippingAddress, deliveryFee, paymentMethod, onClick, onCloseModal }: ModalProps) => {

  useEffect(() => {
    const close = (e: any) => {
      if(e.keyCode === 27){
        onCloseModal && onCloseModal()
      }
    }
    window.addEventListener('keydown', close)
    return () => window.removeEventListener('keydown', close)
  },[]);

  const getSubtotal = () => {
    return items && items.reduce((acc, product) => {
      return acc + product.price * (product.quantity || 1);
    }, 0);
  }

  const getDiscount = () => {
    return items && items.reduce((acc, product) => {
      return acc + (product.discount || 0) * (product.quantity || 1);
    }, 0);
  }

  const getTotal = () => {
    return getSubtotal() + deliveryFee - getDiscount();
  }

  return (
    <Dimmer onClick={onCloseModal}>
      <Container onClick={(e:React.MouseEvent) => e.stopPropagation()}>
        <ModalHeader>
          <Empty />

          <Title>N° {code}</Title>

          <Clickable onClick={onClick}>
            <HighlightedElement 
              primaryColor='#6460AA'
              icon={HiX}
              r='100'
              g='96'
              b='170'
              a='0.2'
            />
          </Clickable>
        </ModalHeader>

        <Row>
          <TypeLabel>Tipo</TypeLabel>

          <Description>{saleType}</Description>
        </Row>

        <Divider />

        <ProductsContainer>
          <Label>Produtos</Label>

          {items && items.map((product: Product) => {
            return (
              <ProductRow key={product.id}>
                <HighlightedElement
                  primaryColor='#2F3132'
                  r='100'
                  g='96'
                  b='170'
                  a='0.05'
                  text={product.quantity ? product.quantity.toString() : '0'}
                  customStyle={{ height: '25px', width: '25px' }}
                />

                <PrimaryText>{product.name}</PrimaryText>
              </ProductRow>
            )
          })}
        </ProductsContainer>

        <Divider />

        <Row>
          <Label>Subtotal</Label>
          
          <Label>{getSubtotal().toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</Label>
        </Row>

        <Row>
          <Label>Descontos</Label>

          <HighlightedLabel>{getDiscount().toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</HighlightedLabel>
        </Row>

        <Row>
          <Label>Taxa de entrega</Label>

          <HighlightedLabel>{deliveryFee ? deliveryFee.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : 'Grátis'}</HighlightedLabel>
        </Row>

        <Row>
          <ImpactText>Total</ImpactText>

          <ImpactText>{getTotal().toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</ImpactText>
        </Row>

        <Divider />

        <Row>
          <PaymentMethodLabel>Pago por</PaymentMethodLabel>

          <ImpactText>{paymentMethod}</ImpactText>
        </Row>

        <Divider />

        <Label>Endereço de entrega</Label>

        <PrimaryText>{shippingAddress}</PrimaryText>
      </Container>
    </Dimmer>
  );
}
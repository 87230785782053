import { useEffect, useState } from 'react';
import { ButtonsWrapper, CancelButton, Column, Container, ContentContainer, DateTitle, DateWrapper, Dimmer, Divider, Empty, ExportButton, Header, HeaderTitle, Label, PickedDate, RadioButton, RadioWrapper, Row, StyledHiX } from './styles';
import DateRangeCalendar from 'components/DateRangeCalendar';
import { ClipLoader } from 'react-spinners';
import { format, parseISO, subDays } from 'date-fns';
import { api } from 'config/api';
import useAuth from 'hooks/useAuth';
import { Toast } from 'components/Toast';

interface Props {
  onClose: () => void;
  onSuccess: () => void;
  filterReportType: 'access' | 'checkIn';
}

export function FilterReportModal({ onClose, onSuccess, filterReportType }: Props) {
  const [checked, setChecked] = useState(0);
  const [from, setFrom] = useState(new Date().toISOString());
  const [to, setTo] = useState(new Date().toISOString());
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [noContent, setNoContent] = useState(false);

  const { auth } = useAuth();

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const close = (e: any) => {
      if (e.keyCode === 27) {
        handleCancelModal()
      }
    }

    window.addEventListener('keydown', close)

    return () => window.removeEventListener('keydown', close)
  }, []);

  const handleCancelModal = (success?: boolean) => {
    setFrom('');
    setTo('');
    onClose();

    success && onSuccess();
  }

  const handleSelection = (interval: number) => {
    setChecked(interval);
    setFrom(subDays(new Date(), interval).toISOString());
    setTo(new Date().toISOString());
  }

  const handleDownloadReport = async (e: any) => {
    e.preventDefault();

    try {
      setLoading(true);
      const URL = filterReportType === 'access' ?
        `log/report?email=${auth?.username}&from=${from}&to=${to}`
        :
        `checkin/report?email=${auth?.username}&from=${from}&to=${to}`

      const { data } = await api.get(URL)

      if (data && data.url) {
        window.open(data.url);

        handleCancelModal(true);
      } else {
        setNoContent(true);
      }


    } catch (error) {
      setError(true);
    } finally {
      setLoading(false);

      setTimeout(() => {
        setNoContent(false);
        setError(false);
      }, 6000);
    }
  }

  return (
    <Dimmer onClick={() => handleCancelModal(false)}>
      {error && (
        <Toast
          type='error'
          text='Falha ao gerar link para download.'
          closeable
          secondaryText='Por favor, tente novamente.'
        />
      )}

      {noContent && (
        <Toast
          type='info'
          text='Não há registros para serem exportados.'
          closeable
        />
      )}

      <Container onClick={(e: React.MouseEvent) => e.stopPropagation()}>
        <Header onClick={() => handleCancelModal(false)}>
          <Empty />

          <HeaderTitle>Escolha um período</HeaderTitle>

          <StyledHiX size={20} color='#6460AA' />
        </Header>

        <ContentContainer>
          <Row>
            <RadioWrapper>
              <RadioButton id='15' checked={checked === 15} onClick={() => handleSelection(15)} />

              <Label htmlFor='15'>15 dias</Label>
            </RadioWrapper>

            <RadioWrapper>
              <RadioButton id='30' checked={checked === 30} onClick={() => handleSelection(30)} />

              <Label htmlFor='30'>30 dias</Label>
            </RadioWrapper>

            <RadioWrapper>
              <RadioButton id='60' checked={checked === 60} onClick={() => handleSelection(60)} />

              <Label htmlFor='60'>60 dias</Label>
            </RadioWrapper>
          </Row>

          <Row>
            <RadioWrapper>
              <RadioButton id='90' checked={checked === 90} onClick={() => handleSelection(90)} />

              <Label htmlFor='90'>90 dias</Label>
            </RadioWrapper>

            <RadioWrapper>
              <RadioButton id='0' checked={checked === 0} onClick={() => handleSelection(0)} />

              <Label htmlFor='0'>Escolher período</Label>
            </RadioWrapper>
          </Row>
        </ContentContainer>

        {checked === 0 && (
          <>
            <DateWrapper>
              <Column>
                <DateTitle>De</DateTitle>

                <PickedDate>{format(parseISO(from), 'dd/MM/yyyy')}</PickedDate>
              </Column>

              <Column>
                <DateTitle>Até</DateTitle>

                <PickedDate>{format(parseISO(to), 'dd/MM/yyyy')}</PickedDate>
              </Column>
            </DateWrapper>

            <Divider />

            <DateRangeCalendar
              from={from}
              to={to}
              setFrom={(e) => setFrom(e)}
              setTo={(e) => setTo(e)}
            />
          </>
        )}

        <ButtonsWrapper>
          <CancelButton onClick={() => handleCancelModal(false)}>Cancelar</CancelButton>

          <ExportButton onClick={handleDownloadReport}>
            {loading ? (
              <ClipLoader
                color='#fff'
                size={16}
                aria-label="Loading Spinner"
                data-testid="loader"
              />
            ) : 'Exportar'}
          </ExportButton>
        </ButtonsWrapper>
      </Container>
    </Dimmer>
  )
}

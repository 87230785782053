import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 36px;
  background: var(--background);
  border: 1px solid #F5F5F5;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 12px;

  /* Tablet */
  @media (min-width:768px) {
    height: 164px;
    width: 164px;
    flex-direction: column;
    justify-content: center;
  }

  /* Desktop */
  @media (min-width:1025px) {
    margin-left: 24px;
    margin-bottom: 24px;
    padding: 0;
  }
`;

export const TitleWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  /* Tablet */
  @media (min-width:768px) {
    flex-direction: column;
  }

  /* Desktop */
  @media (min-width:1025px) {
    
  }
`;

export const Title = styled.span`
  margin-left: 8px;
  color: var(--secondaryTextColor);
  font-size: 16px;
  font-family: 'Roboto', sans-serif;

    /* Tablet */
  @media (min-width:768px) {
    margin: 8px 0;
  }

  /* Desktop */
  @media (min-width:1025px) {
    
  }
`;

export const BoldText = styled.span`
  font-size: 16px;
  color: var(--secondaryTextColor);
  font-weight: 600;
`;

export const SubtitleMark = styled.span`
  color: var(--alert);
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 4px 0;
`;

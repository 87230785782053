import { useState } from 'react';
import { Annotation, AnnotationDeprecated, Profile } from 'types/CustomerDetails';
import { NotesContext } from './NotesContext';
import { api } from 'config/api';
import useAuth from 'hooks/useAuth';

type Props = {
  children?: React.ReactNode;
}

export const NotesProvider = ({ children }: Props) => {
  const [note, setNote] = useState<Annotation[]>([] as Annotation[]);
  const [noteDeprecated, setNoteDeprecated] = useState<AnnotationDeprecated>({} as AnnotationDeprecated);
  const [notification, setNotification] = useState<Annotation[]>([] as Annotation[]);
  const [notificationFilterByDay, setNotificationFilterByDay] = useState<Annotation[]>([] as Annotation[]);
  const [notificationBeforeAndAfterToday, setNotificationBeforeAndAfterToday] = useState<Annotation[]>([] as Annotation[]);
  const [profile, setProfile] = useState<Profile>({} as Profile);
  const [username, setUsername] = useState('');
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const { auth } = useAuth();

  const getNotification = async () => {
    try {
      setLoading(true);

      const response = await api.get(`customer/annotation?consultantEmail=${auth?.username ? auth.username : ''}`);

      setNotification(response.data);
    } catch (err) {
      setError(true);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  }

  const getNotificationLength = (): number => {
    return notification.filter(objeto => objeto.active.toString() === 'true').length;
  }

  const getNotificationFilterByDay = () => {
    const today = new Date().toISOString().split('T')[0];
    let filteredNotifications = [];

    filteredNotifications = notification.filter(
      (item) => String(item.dateNotification).split('T')[0] === today && item.active.toString() === 'true'
    );

    return filteredNotifications;
  };

  const getNotificationsBeforeAndAfterToday = () => {
    const today = new Date().toISOString().split('T')[0];
    let sortedNotifications = [];

    const previousNotifications = notification.filter(
      (item) =>
        item.dateNotification && String(item.dateNotification).split('T')[0] < today &&
        item.active.toString() === 'true'
    );
    const futureNotifications = notification.filter(
      (item) =>
        item.dateNotification && String(item.dateNotification).split('T')[0] > today &&
        item.active.toString() === 'true'
    );

    sortedNotifications = [...previousNotifications, ...futureNotifications].sort(
      (a, b) => {
        const dateA = new Date(a.dateNotification).getTime();
        const dateB = new Date(b.dateNotification).getTime();
        return dateA - dateB;
      }
    );

    return sortedNotifications;
  };

  return (
    <NotesContext.Provider value={{
      note, setNote, profile, setProfile, username, setUsername,
      getNotification, notification, setNotification, error, loading, getNotificationLength,
      getNotificationFilterByDay, getNotificationsBeforeAndAfterToday, notificationFilterByDay, setNotificationFilterByDay,
      notificationBeforeAndAfterToday, setNotificationBeforeAndAfterToday, noteDeprecated, setNoteDeprecated
    }}>
      {children}
    </NotesContext.Provider>
  );
};

export default NotesProvider;
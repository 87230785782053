import styled from 'styled-components';
import { HiOutlineInformationCircle, HiOutlineSwitchVertical } from 'react-icons/hi';
import { Link } from 'react-router-dom';

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

export const TableHead = styled.thead`
  font-family: 'Source Sans Pro', sans-serif;
`;

export const TableRow = styled.tr`
  height: 48px;

  &:nth-child(even) {
    background-color: #f5f5f5;
  }
`;

export const TableData = styled.td<{ 
  isLarge?: boolean, 
  isSmall?: boolean, 
  isBoundaryColumn?: boolean, 
  isClickable?: boolean, 
  isMyClient?: boolean, 
  isHeader?: boolean 
}>`
  color: ${({ isClickable, isMyClient, isHeader }) => !isMyClient && !isHeader 
    ? '#B9B9BB' 
    : isClickable 
      ? '#6460AA' 
      : '#2F3132'
  };
  text-decoration: ${({ isClickable, isMyClient }) => isClickable && isMyClient ? 'underline' : 'none'};
  cursor: ${({ isClickable, isMyClient, isHeader }) => !isMyClient && !isHeader 
    ? 'not-allowed' 
    : isClickable 
      ? 'pointer' 
      : 'default'
  };
  padding-left: ${({ isBoundaryColumn }) => isBoundaryColumn ? '8px' : '32px' };
  font-weight: 400;
  font-size: 14px;
  font-family: 'Roboto', sans-serif;
  overflow: ${({ isSmall }) => isSmall ? 'visible' : 'hidden'};
  text-overflow: ellipsis;
  max-width: ${({ isLarge }) => (isLarge ? '130px' : 'undefined')};
  white-space: ${({ isSmall }) => isSmall ? 'none' : 'nowrap'};
`;

export const BlockedCustomer = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  color: #B9B9BB;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; 
  text-decoration-line: underline;
`;

export const CustomerDocument = styled.span<{ isMyClient: boolean }>`
  color: ${({ isMyClient }) => isMyClient ? '#6D6E71' : '#B9B9BB'};
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: right;
  display: inline-block;
  width: 100%;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const TableHeader = styled.span<{ active?: boolean }>`
  font-weight: ${({ active }) => active ? 'bold' : 'normal'};
  max-width: 140px;
  white-space: nowrap;
`;

export const StyledHiOutlineSwitchVertical = styled(HiOutlineSwitchVertical)`
  margin-left: 12px;
  cursor: pointer;
`;

export const TableBody = styled.tbody``;

export const CustomerInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Clickable = styled(Link)`
  cursor: pointer;
  color: var(--highlightColor);
  text-decoration: underline;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const StyledHiOutlineInformationCircle = styled(HiOutlineInformationCircle)`
  margin-left: 16px;
`;

export const CardContainer = styled.div`
  background-color: var(--background);
  padding: 24px 16px;
  width: 100%;
  margin: 0 -16px;

  &:nth-child(odd) {
    background-color: var(--searchBackground);
  }
`;

export const SpacedRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 24px;

  &:first-child {
    margin-bottom: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

export const CustomerName = styled(Link)<{ isMyClient?: boolean }>`
  font-size: 16px;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  text-decoration-line: underline;
  color: ${({ isMyClient }) => isMyClient ? '#6460AA' : '#B9B9BB'};
`;

export const ValueText = styled.span<{ isClickable?: boolean, isMyClient?: boolean }>`
  font-size: 14px;
  font-family: 'Roboto', sans-serif;
  line-height: 150%;
  color: ${({ isClickable, isMyClient }) => isClickable ? '#6460AA' : !isMyClient ? '#B9B9BB' : '#6D6E71'};
  text-decoration: ${({ isClickable }) => isClickable ? 'underline' : 'none'};
  cursor: ${({ isClickable }) => isClickable ? 'pointer' : ''};
`;

export const Label = styled.span<{ isMyClient?: boolean }>`
  font-size: 16px;
  font-family: 'Roboto', sans-serif;
  line-height: 150%;
  color: ${({ isMyClient }) => isMyClient ? '#6D6E71' : '#B9B9BB'};
`;
import { Container, StyledHix, Text } from './styles';

interface ChipProps {
  text: string;
  removeable?: boolean;
  onClick?: () => void;
}

export const Chip = ({ text, removeable, onClick }: ChipProps) => {
  return (
    <Container onClick={onClick}>
      <Text>{text}</Text>

      {removeable && <StyledHix size={20} color='#6460AA' />}
    </Container>
  )
}

import styled from 'styled-components';
import { HiX } from 'react-icons/hi';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px 12px;
  height: 33px;
  border-radius: 19px;
  background-color: var(--searchBackground);
  margin-right: 12px;
  margin-bottom: 12px;
  cursor: pointer;
`;

export const Text = styled.span`
  font-family: 'Roboto', sans-serif;
  font-weight: 600;
  font-size: 14px;
  color: var(--primaryTextColor);
`;

export const StyledHix = styled(HiX)`
  margin-left: 13px;
`;
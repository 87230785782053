import styled from 'styled-components';

export const Dimmer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(17, 24, 39, 0.8);
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ContainerItem = styled.div<{ isOutOfDate?: boolean }>`
  display: flex;
  flex-direction: row;
  width: 80%;
  height: 200px;
  border-radius: 8px;
  justify-content: space-between;
  background-color: var(--background);
  border-left: ${({ isOutOfDate }) => (isOutOfDate ? '3px solid #6D6E71' : '3px solid #F3716D')};
  padding: 16px 10px;
  margin-bottom: 16px;

    /* Desktop */
  @media (min-width:1024px) {
    width: 450px;
  }
`;

export const ContentItem = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 3px;
  
`;

export const ItemTitleName = styled.span<{ isOutOfDate?: boolean }>`
  color: ${({ isOutOfDate }) => (isOutOfDate ? '#B4B4B4' : '#6460AA')};
  font-size: 18px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const Divider = styled.div`
  width: 100%;
  border: 1px solid var(--dividerColor);
  margin: 10px 0;
`;

export const ItemAnnotation = styled.div<{ isOutOfDate?: boolean }>`
  color: ${({ isOutOfDate }) => (isOutOfDate ? '#B9B9BB' : '#6D6E71')};
  font-size: 16px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  max-height: 150px;
  padding-right: 10px;
  overflow-y: auto;
  overflow-x: hidden;
`;

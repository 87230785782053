import styled from 'styled-components';

export const Dimmer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(17, 24, 39, 0.8);
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Container = styled.div`
  background: var(--background);
  border-radius: 8px;
  padding: 20px 16px;
  display: flex;
  flex-direction: column;
  width: 85%;
  max-height: 85vh;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 70%;

  /* Tablet */
  @media (min-width:768px) {
    max-width: 400px;
  }
`;

export const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 8px;
  margin-bottom: 24px;  
`;

export const Empty = styled.div`

`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Title = styled.h1`
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  color: var(--primaryTextColor);
  font-size: 16px;
`;

export const Subtitle = styled.div`
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  margin-top: 4px;
  color: var(--primaryTextColor);
`;

export const Clickable = styled.div`
  cursor: pointer;
`;

export const MostShoppedItemsWrapper = styled.ul`

`;

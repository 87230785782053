export const formatDate = (dateString: string, previousDateString?: string | null) => {
  const date = new Date(dateString);
  const today = new Date();

  if (date.toDateString() === today.toDateString()) {
    const formattedDate = new Intl.DateTimeFormat('pt-BR', { day: 'numeric', month: 'long' }).format(date);
    return 'Hoje, ' + formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1);
  } else {
    const formattedDate = new Intl.DateTimeFormat('pt-BR', { weekday: 'long', day: 'numeric', month: 'long' }).format(date);
    if (previousDateString && previousDateString === formattedDate) {
      return '';
    } else {
      return formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1);
    }
  }
}

/* eslint-disable @typescript-eslint/no-explicit-any */
import { Tag } from 'components/Tag';
import { getNormalizedDate } from 'utils';
import { handleFiles } from 'utils/handleFiles';
import { Container, DateSpan, DownloadContainer, Header, Order } from './styles';

interface Props {
  order: any;
  onClick: (e: any) => void;
}

export function BankSlip({ order, onClick }: Props) {
  return (
    <Container>
      <Header>
        <Order onClick={() => onClick(order.id)}>{order.code}</Order>

        <DateSpan>{getNormalizedDate(order.date)}</DateSpan>
      </Header>

      <DownloadContainer>
        <Tag
          text='NF-e'
          link={order.billingInfo?.danfe?.url}
          hasAlert={false}
          order={order.code}
        />
        <Tag
          text={`${order.billingInfo?.boletos?.length} ${order.billingInfo.boletos.length === 1 ? 'Boleto' : 'Boletos'}`}
          link={handleFiles(order.billingInfo?.boletos)}
          hasAlert={false}
          order={order.code}
        />
      </DownloadContainer>
    </Container>
  )
}

import styled, { css, keyframes } from 'styled-components';
import { BsBell, BsFillBellFill } from 'react-icons/bs';
import { Button } from 'components/Button';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 32px;
  overflow: hidden;
`;

export const NoteWrapper = styled.div`
  display: flex;
  width: 90%;
`;

export const NoteMessageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 16px;
  max-width: 80%;
  word-wrap: break-word; 
`;

export const NoteAuthor = styled.span`
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  font-size: 16px;
  line-height: 120%;
  color: #4f4f4f;
`;

export const NoteMessage = styled.p`
  margin: 8px 0;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #4f4f4f;
`;

const ringAnimation = keyframes`
  0% { transform: rotate(0); }
  1% { transform: rotate(20deg); }
  3% { transform: rotate(-18deg); }
  5% { transform: rotate(24deg); }
  7% { transform: rotate(-22deg); }
  9% { transform: rotate(20deg); }
  11% { transform: rotate(-18deg); }
  13% { transform: rotate(16deg); }
  15% { transform: rotate(-14deg); }
  17% { transform: rotate(12deg); }
  19% { transform: rotate(-10deg); }
  21% { transform: rotate(8deg); }
  23% { transform: rotate(-6deg); }
  25% { transform: rotate(4deg); }
  27% { transform: rotate(-2deg); }
  29% { transform: rotate(2deg); }
  31% { transform: rotate(-2deg); }
  33% { transform: rotate(4deg); }
  35% { transform: rotate(-2deg); }
  37% { transform: rotate(1deg); }
  39% { transform: rotate(-0.5deg); }
  41% { transform: rotate(0.5deg); }

  43% { transform: rotate(0); }
  100% { transform: rotate(0); }
`;

export const Bell = styled.div<{ isClicked: boolean }>`
  cursor: pointer;
  height: 0;

  &.animate {
    -webkit-animation: ${ringAnimation} 4s .2s ease-in-out;
    -webkit-transform-origin: 50% 4px;
    -moz-animation:  ${ringAnimation} 4s .2s ease-in-out;
    -moz-transform-origin: 50% 4px;
    animation:  ${ringAnimation} 4s .2s ease-in-out;
  }
`;

export const StyledBsBell = styled(BsBell)`
  cursor: pointer;
  margin-right: 28px;
`;

export const StyledBsFillBellFill = styled(BsFillBellFill)`
  cursor: pointer;
  margin-right: 28px;
`;

export const SeeMoreButton = styled.span`
  color: var(--primaryTextColor);
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 16px;
  text-decoration: underline;
  cursor: pointer;
`;

export const Dimmer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(17, 24, 39, 0.8);
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ContainerModalOption = styled.div`
  background: var(--background);
  border-radius: 8px;
  padding: 30px 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 327px;
  overflow: hidden;
  height: auto;
`;

export const TextTitleModal = styled.span`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  width: 239px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #6D6E71;
  margin-bottom: 30px;
`;

export const ContainerButton = styled.div`
  display: flex;
  justify-content: space-between;
  width: 280px;
  align-items: center;
`;

export const OptionCancelButton = styled.span`
  width: 55px;
  height: 21px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  display: flex;
  align-items: center;
  text-decoration-line: underline;
  color: #6460AA;
  cursor: pointer;
`;

export const OptionButton = styled(Button)`
  width: 163px;
  height: 46px;
`;
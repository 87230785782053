import { Routes, Route } from 'react-router-dom';
import { Customer } from 'pages/Customer';
import { Home } from 'pages/Home';
import { Notification } from 'pages/Notification';
import { Login } from 'pages/Login';
import { User } from 'pages/User';
import { Report } from 'pages/Report';
import { PersistLogin } from './PersistLogin';
import { ErrorScreen } from 'components/ErrorScreen';
import { Header } from 'components/Header';
import useUsersTypeAccess from 'hooks/useUsersTypeAccess';

export const ProjectRouter = () => {
  const { isAdmin, isManager } = useUsersTypeAccess();

  return (
    <Routes>
      <Route path="/login" element={<Login />} />

      <Route element={<PersistLogin />}>
        <Route path="/" element={<Home />} />
        <Route path="/notification" element={<Notification />} />
        <Route path="/customer/:id" element={<Customer />} />
        {isAdmin() && <Route path="/user" element={<User/>} />}
        {(isAdmin() || isManager()) && <Route path="/report" element={<Report/>} />}
      </Route>

      <Route path='*' element={
        <>
          <Header />

          <ErrorScreen 
            title="Página não encontrada"
            message="A página não existe ou foi removida"
            status={404}
          />
        </>
      } 
      />
    </Routes>
  )
}



import styled from 'styled-components';
import { Button } from 'components/Button';
import { FiEdit } from 'react-icons/fi';

export const NotesWrapper = styled.div`
  display: flex;
  padding-right: 34px;
  width: 100%;
`;

export const NotesContainer = styled.div`
  background: var(--background);
  border-radius: 8px;
  width: 100%;
  padding: 24px;
`;

export const ProfileContainer = styled.div`
  background: var(--background);
  border-radius: 8px;
  padding: 40px 32px;
  min-width: 360px;
  width: 30%;
  margin-left: 24px;
`;

export const NotesHeader = styled.h1`
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  color: var(--secondaryTextColor);
`;

export const StyledImage = styled.img`
  height: 313px;
  align-self: flex-end;
  margin-left: 40px;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Message = styled.span`
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  margin-bottom: -9px;
  color: var(--primaryTextColor);
`;

export const StyledButton = styled(Button)`
  width: 163px;
  margin-top: 24px;
  margin-bottom: 48px;
`;

export const ScrollableList = styled.div`
  max-height: 500px;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background: #D9D9D9;
    border-radius: 16px;
  }
`;

export const EmptyNotesContainer = styled.div`
  background: var(--background);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  width: 60%;
  padding: 24px;
`;

export const EmptyProfileContainer = styled.div`
  background: var(--background);
  border-radius: 8px;
  padding: 40px 32px;
  min-width: 360px;
  width: 30%;
  margin-left: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const ProfileMessage = styled.span`
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  text-align: center;
  color: var(--primaryTextColor);
`;

export const ProfileHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 24px;
`;

export const ProfileTitle = styled.span`
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  color: var(--secondaryTextColor);
  align-self: flex-start;
`;

export const StyledFiEdit = styled(FiEdit)`
  cursor: pointer;
`;

export const ProfileContent = styled.p`
  width: 100%;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: var(--primaryTextColor);
  margin-bottom: 32px;
`;

export const ProfileInterests = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-top: 12px;
`;
import { ClipLoader } from 'react-spinners';
import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 212px;
  height: 200px;

  /* Tablet */
  @media (min-width:768px) {
   margin-top: 320px;
  }

  /* Desktop */
  @media (min-width:1025px) {
    margin-top: 226px;
    margin-left: 200px;
    width: calc(100% - 200px);
  }
`;

export const StyledClipLoader = styled(ClipLoader)`
  margin-bottom: 6px;
`;

export const Message = styled.p`
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: var(--primaryTextColor);
`;
import { useEffect, useState } from 'react';
import { Pulsating } from 'components/Pulsating';
import { HiMicrophone } from 'react-icons/hi';
import { 
  Container, 
  Dimmer, 
  ClosingContainer, 
  StyledHiX, 
  ContentContainer, 
  Header, 
  MicContainer, 
  DotWrapper, 
  Dot, 
  Message 
} from './styles';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import { Toast } from 'components/Toast';

interface SpeakerModalProps {
  onCloseModal: () => void
  setSearch: (term: string) => void
  setInputValue?: (value: string) => void
}

export const SpeakerModal = ({ onCloseModal, setSearch, setInputValue }: SpeakerModalProps) => {
  const [isSecondDotVisible, setIsSecondDotVisible] = useState(false)
  const [isThirdDotVisible, setIsThirdDotVisible] = useState(false)
  const [speechToTextError, setSpeechToTextError] = useState(false);
  const [hasErrorOnListening, setHasErrorOnListening] = useState(false);

  const {
    transcript, 
    resetTranscript,
    browserSupportsSpeechRecognition
  } = useSpeechRecognition();

  useEffect(() => {
    setTimeout(() => {
      setIsSecondDotVisible(true)
    }, 500);

    setTimeout(() => {
      setIsThirdDotVisible(true)
    }, 1000);

    return () => {
      setIsSecondDotVisible(false)
      setIsThirdDotVisible(false)
    }
  }, [hasErrorOnListening]);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const close = (e: any) => {
      if(e.keyCode === 27){
        handleCancelModal()
      }
    }

    window.addEventListener('keydown', close)
    
    return () => window.removeEventListener('keydown', close)
  },[]);

   useEffect(() => {
    if(!browserSupportsSpeechRecognition) {
      setSpeechToTextError(true)
    }

    SpeechRecognition.startListening({continuous: true});
  }, []);

  const handleCloseModal = () => {
    if (transcript) {
      SpeechRecognition.stopListening();

      setInputValue && setInputValue(transcript);
      setSearch(transcript);
  
      resetTranscript();
  
      setHasErrorOnListening(false);

      onCloseModal();
    } else {
      setHasErrorOnListening(true)
    }
  }

  const handleCancelModal = () => {
    SpeechRecognition.stopListening();
    
    resetTranscript();

    setHasErrorOnListening(false);

    onCloseModal();
  }

  const reactivate = () => {
    setHasErrorOnListening(false);

    SpeechRecognition.startListening({continuous: true});
  }

  return (
    <Dimmer onClick={handleCancelModal}>
      {speechToTextError && (
        <Toast
          type='error'
          text='Mensagem de Erro'
          secondaryText='Seu navegador não suporta reconhecimento de voz'
          closeable
        />
      )}

      <Container onClick={(e:React.MouseEvent) => e.stopPropagation()}>
        <ClosingContainer onClick={handleCancelModal}>
          <StyledHiX size={12} color='#6460AA'/>
        </ClosingContainer>

        <ContentContainer>
          <Header>{hasErrorOnListening ? 'Não entendemos' : 'Fale agora'}</Header>

          <Pulsating>
            <MicContainer 
              onClick={hasErrorOnListening ? reactivate : handleCloseModal}
              hasErrorOnListening={!!hasErrorOnListening}
            >
              <HiMicrophone size={32} color={hasErrorOnListening ? '#E61D02' : '#FFF'}/>
            </MicContainer>
          </Pulsating>

          {!hasErrorOnListening ? (
            <DotWrapper>
              <Dot />
              {isSecondDotVisible && <Dot />}
              {isThirdDotVisible && <Dot />}
            </DotWrapper>
          ): <div />}
        </ContentContainer>

        <Message>{hasErrorOnListening ? 'Clique para tentar novamente' : 'E clique para buscar'}</Message>
      </Container>
    </Dimmer>
  )
}
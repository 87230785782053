import { HiOutlineInformationCircle } from 'react-icons/hi';
import styled from 'styled-components';

export const InfoText = styled.div`
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  line-height: 150%;
  color: var(--primaryTextColor);
`;

export const DisabledInfoText = styled(InfoText)`
  color: var(--tertiaryTextColor);
`;

export const TooltipContainer = styled.div`
  display: none;
  position: absolute;
  color: var(--primaryTextColor); 
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  line-height: 150%;

  &:before {
    content: "";
    width: 0;
    height: 0;
    right: 10px;
    top: 62px;
    position: absolute;
    border: 10px solid transparent;
    transform: rotate(315deg);
    transition: border 0.4s ease-in-out;
  }

  /* Tablet */
  @media (min-width:768px) {
    &:before {
      content: "";
      width: 0;
      height: 0;
      right: 23px;
      top: 62px;
      position: absolute;
      border: 10px solid transparent;
      transform: rotate(315deg);
      transition: border 0.4s ease-in-out;
    }  
  }
`;

export const StyledHiOutlineInformationCircle = styled(HiOutlineInformationCircle)<{ withoutMargin?: boolean, isCheckInTooltip?: boolean }>`
  cursor: pointer;
  margin-left: ${({ withoutMargin, isCheckInTooltip }) => ((withoutMargin || !isCheckInTooltip) ? 'undefined' : '4px')};
  margin-bottom: ${({ withoutMargin, isCheckInTooltip }) => ((withoutMargin || !isCheckInTooltip) ? 'undefined' : '16px')};

  /* Mobile */
  @media (max-width:767px) {
    margin-left: 10px;
  }
`;

export const TooltipWrapper = styled.div<{ withoutAlignment?: boolean, zIndexLow?: boolean, isCheckInTooltip?: boolean }>`
  display: flex;
  flex-direction: ${({ withoutAlignment }) => (withoutAlignment ? 'undefined' : 'column')};
  align-items: ${({ withoutAlignment }) => (withoutAlignment ? 'undefined' : 'flex-end')};
  position: relative;
  z-index: ${({ zIndexLow, withoutAlignment }) => (zIndexLow ? '0' : withoutAlignment ? '50' : 'undefined')};
  
  /* Mobile & Tablet */
  @media (max-width:1025px) {
    width: ${({ isCheckInTooltip }) => (isCheckInTooltip ? 'auto': '48px')};
    height: ${({ isCheckInTooltip }) => (isCheckInTooltip ? 'auto': '48px')};
  }

  // /* Mobile & Tablet */
  // @media (max-width:1025px) {
  //   width: ${({ isCheckInTooltip }) => (isCheckInTooltip ? '0': '48px')};
  //   height: ${({ isCheckInTooltip }) => (isCheckInTooltip ? '0': '48px')};
  // }

  & ${StyledHiOutlineInformationCircle}:hover + ${TooltipContainer} {
    display: block;
    background: var(--searchBackground);
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.1);
    padding: 16px;
    width: 150px;
    border-radius: 8px;
    margin-top: -85px;
    margin-right: -10px;

    /* Tablet */
    @media (min-width:768px) {
      margin-left: ${({ withoutAlignment }) => (withoutAlignment ? '-200px' : 'undefined')};
      margin-right: ${({ withoutAlignment }) => (withoutAlignment ? 'undefined' : '-20px')};
    }
    
    &:before {
      border-color: transparent transparent var(--searchBackground) var(--searchBackground);
    }
  }
`;

export const TooltipText = styled.span``;

export const HighlightedText = styled.span`
  font-weight: bold;
`;


import { useState } from 'react';
import { ToastContext } from './ToastContext';
import { Toast as ToastType } from 'types/ToastContextType';
import { Toast } from 'components/Toast';

type Props = {
  children?: React.ReactNode;
}

export const ToastProvider = ({ children }: Props) => {
  const [toast, setToast] = useState<ToastType>({} as ToastType);
  
  const renderToast = () => {
    return toast && toast.type && (
      <Toast 
        type={toast.type}
        text={toast.text}
        secondaryText={toast.secondaryText ? toast.secondaryText : ''}
        closeable={toast.closeable}
      />
    )
  }
  
  return (
    <ToastContext.Provider value={{ toast, setToast, renderToast }}>
      {children}
    </ToastContext.Provider>
  );
};

export default ToastProvider;

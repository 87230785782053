import styled from 'styled-components';

export const Dimmer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(17, 24, 39, 0.8);
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Container = styled.div`
  background: var(--background);
  border-radius: 8px;
  padding: 20px 16px;
  display: flex;
  flex-direction: column;
  width: 85%;
  max-height: 85vh;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 70%;

  /* Tablet */
  @media (min-width:768px) {
    max-width: 400px;
  }
`;

export const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 8px;
  margin-bottom: 25px;
`;

export const Empty = styled.div`

`;

export const Title = styled.h1`
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  color: var(--primaryTextColor);
  font-size: 14px;
`;

export const Clickable = styled.div`
  cursor: pointer;
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 8px;
`;

export const TypeLabel = styled.span`
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  color: var(--secondaryTextColor);
  line-height: 120%;
  font-size: 14px;
`;

export const Description = styled.span`
  color: var(--secondaryTextColor);
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
`;

export const Divider = styled.div`
  border: 1px solid #ECECEC;
  margin: 16px 0;
  width: 100%;
`;

export const ProductsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Label = styled.h2`
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 16px;
  color: var(--primaryTextColor);
  margin-bottom: 16px;
`;

export const PaymentMethodLabel = styled.h2`
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 16px;
  color: var(--primaryTextColor);
`;

export const ProductRow = styled.div`
  display: flex;
  margin-bottom: 8px;
`;

export const PrimaryText = styled.span`
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: var(--secondaryTextColor);
  margin-left: 8px;
  width: 100%;
`;

export const HighlightedLabel = styled.span`
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 16px;
  margin-bottom: 16px;
  color: #02BF37;
`;

export const ImpactText = styled.span`
  font-family: 'Roboto';
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  color: var(--secondaryTextColor);
`;
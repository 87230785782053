import { useEffect, useState } from 'react';
import { 
  HiCheckCircle, 
  HiExclamationCircle, 
  HiExclamation, 
  HiInformationCircle
} from 'react-icons/hi';
import { ToastProps } from 'types/ToastProps';
import { Column, Container, Empty, Message, StyledHiX, Title, Wrapper } from './styles';

export const Toast = ({ type, text, secondaryText, closeable }: ToastProps) => {
  const [showToast, setShowToast] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowToast(false)
    }, 6000);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  const getIcon = (type: string) => {
    switch (type) {
      case 'success':
        return <HiCheckCircle color="#00E13F" size={secondaryText ? 28 : 21} />
      
      case 'info':
        return <HiInformationCircle color="#56A4FF" size={secondaryText ? 28 : 21} />
      
      case 'alert': 
        return <HiExclamation color="#FA9E15" size={secondaryText ? 28 : 21} />
      
      default:
        return <HiExclamationCircle color="#E61D02" size={secondaryText ? 28 : 21} />
    }
  }

  return showToast ? (
    <Container type={type}>
      <Wrapper>
        {getIcon(type)}

        <Column>
          <Title type={type}>{text}</Title>

          {secondaryText && <Message type={type}>{secondaryText}</Message>}
        </Column>
      </Wrapper>
      
      {closeable ? <StyledHiX color="#6D6E71" size={26} onClick={() => setShowToast(false)}/> : <Empty />}
    </Container>
  ) : null
}
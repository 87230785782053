import styled from 'styled-components'
import { HiX } from 'react-icons/hi';
import { Button } from 'components/Button';

export const Dimmer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(17, 24, 39, 0.8);
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Container = styled.div`
  background: var(--background);
  border-radius: 8px;
  padding: 20px 24px;
  display: flex;
  flex-direction: column;
  width: 385px;
  max-height: 85vh;
  overflow-y: auto;
  overflow-x: hidden;
`;

export const Header = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Empty = styled.div``;

export const HeaderTitle = styled.span`
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  color: var(--primaryTextColor);
`;

export const StyledHiX = styled(HiX)`
  cursor: pointer;
  background: rgba(100, 96, 170, 0.04);
  border-radius: 4px;
  padding: 10px;
`;

export const ContentContainer = styled.div`
  padding-top: 44px;
`;

export const Row = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 40px;
`;

export const RadioWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const RadioButton = styled.input.attrs({ type: 'radio' })`
  appearance: none;
  cursor: pointer;
  width: 16px;
  height: 16px;
  border: 2px solid var(--primaryTextColor);
  border-radius: 50%;
  margin-right: 8px;
  outline: none;
  
  &:checked {
    border: 6px solid var(--highlightColor);
    border-radius: 50%;
  }
`;

export const Label = styled.label`
  font-family: 'Roboto', sans-serif;
  cursor: pointer;
  font-weight: 400;
  font-size: 14px;
  color: var(--primaryTextColor);
  margin-right: 48px;
`

export const DateWrapper = styled.div`
  display: flex;  
  width: 100%;
  margin-bottom: 12px;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 70px;
`;

export const DateTitle = styled.span`
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: var(--primaryTextColor);
`;

export const PickedDate = styled.span`
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 16px;
  color: var(--secondaryTextColor);
`

export const Divider = styled.div`
  width: 100%;
  border: 1px solid var(--dividerColor);
  margin-bottom: 24px;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  margin-top: 16px;
`;  

export const CancelButton = styled.span`
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 14px;
  text-decoration-line: underline;
  color: var(--highlightColor);
  margin-right: 30px;
  cursor: pointer;
`;

export const ExportButton = styled(Button)`
  width: 163px;
  height: 46px;
`;
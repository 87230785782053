export const TooltipMessageUtil = [
  {
    title: 'Consultor',
    text: 'Realiza buscas em toda a base e tem acesso aos detalhes dos seus clientes'
  },
  {
    title: 'Padrão',
    text: 'Realiza buscas e tem acesso aos detalhes de todos os clientes'
  },
  {
    title: 'Gerente',
    text: 'Acesso padrão mais relação de usuários da hierarquia e extração de relatórios'
  },
  {
    title: 'Administrador',
    text: 'Realiza buscas, tem acesso aos detalhes de todos os clientes e pode incluir/excluir usuários'
  },
  {
    title: 'Franquia',
    text: 'Realiza buscas em toda a base e tem acesso aos detalhes dos clientes de franquias'
  },
]


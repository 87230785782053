import { useEffect, useState } from 'react';
import { Cancel, Container, Dimmer, Message, Row, StyledButton } from './styles';
import { ClipLoader } from 'react-spinners';
import { HiCheckCircle } from 'react-icons/hi';

interface Props {
  message: string;
  confirmationText: string;
  onConfirmation: () => void;
  onClose: () => void;
}

export const GenericOptionModal = ({ message, confirmationText, onConfirmation, onClose }: Props) => {
  const [success, setSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  
  useEffect(() => {
    setSuccess(false);
  }, []);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const close = async (e: any) => {
      if(e.keyCode === 27){
        onClose();
      }
    }

    window.addEventListener('keydown', close)

    return () => window.removeEventListener('keydown', close)
  },[]);

  const handleOnConfirmation = () => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
      setSuccess(true);
      onConfirmation();
    }, 1000);
  }

  return (
    <Dimmer onClick={onClose}>
       <Container onClick={(e:React.MouseEvent) => e.stopPropagation()}>
        <Message>{message}</Message>

        <Row>
          <Cancel onClick={onClose}>Cancelar</Cancel>

          <StyledButton onClick={handleOnConfirmation}>
            {isLoading ? (
              <ClipLoader
                color='#fff'
                size={16}
                aria-label="Loading Spinner"
                data-testid="loader"
              />
            ) : success ? (
              <>
                <span style={{ marginRight: '10px' }}>Feito</span> <HiCheckCircle color='#00E13F' size={16} />
              </>
            ) : confirmationText}
          </StyledButton>
        </Row>
      </Container>
    </Dimmer>
  )
}

/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react';
import { useMsal, useIsAuthenticated } from '@azure/msal-react';
import { InteractionStatus } from '@azure/msal-browser';
import { Outlet } from 'react-router-dom';
import { msalInstance } from 'config/msalInstance';
import { loginRequest } from 'config/authConfig';
import useAuth from 'hooks/useAuth';
import { Login } from 'pages/Login';
import { Loader } from 'components/Loader';
import useUsersTypeAccess from 'hooks/useUsersTypeAccess';
import { ErrorScreen } from 'components/ErrorScreen';
import { LoginError } from 'components/LoginError';

export const PersistLogin = () => {
  const [loginError, setLoginError] = useState(false);
  const { instance, inProgress, } = useMsal();
  const [graphData, setGraphData] = useState(null);
  const isAuthenticated = useIsAuthenticated();
  const { setAuth } = useAuth();

  const { usersTypeAccess, getUsersTypeAccess, loading, error } = useUsersTypeAccess();

  const account = msalInstance.getActiveAccount();
  
  useEffect(() => {
    getUsersTypeAccess();
  }, []);

  useEffect(() => {
    const verifyRefreshToken = async () => {

      if (!account) {
        throw Error('No active account! Verify a user has been signed in and setActiveAccount has been called.');
      }

      // if (!usersTypeAccess.some(user => user.email === account.username)) instance.logoutRedirect();

      const response = await msalInstance.acquireTokenSilent({
        ...loginRequest,
        account: account
      });

      setGraphData({ ...response.account } as any)
      setAuth({ ...response.account })

      return response;
    };

    if (!graphData && (inProgress === InteractionStatus.None)) {
      verifyRefreshToken();
    }

    if (graphData && !usersTypeAccess.some(user => user?.email === account?.username)) {
      setLoginError(true);
    } else {
      setLoginError(false);
    }
  }, [inProgress, graphData, instance, usersTypeAccess]);

  const handleLoginPage = () => {
    if (inProgress) {
      if (inProgress === InteractionStatus.HandleRedirect || inProgress === InteractionStatus.Startup) return <Loader message1='Carregando...' message2='' />
      return <Login />;
    }
    return <Outlet />
  }

  if (loading) {
    return <Loader message1='carregando...' message2='Buscando usuários' />
  }

  if (error) {
    return <ErrorScreen
      title="Algo deu errado..."
      message="Mas logo será resolvido."
      status={500}
    />
  }

  if (loginError) {
    return <LoginError />
  }
  
  return usersTypeAccess.some(user => user.email === account?.username) && isAuthenticated
    ? <Outlet />
    : handleLoginPage()
}




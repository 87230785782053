export const getPricingLink = (clientCategory: string) => {
  switch (clientCategory) {
    case 'Cliente Normal HOF': 
      return 'https://search-professional-front-hml-pricing.s3.amazonaws.com/PROPOSTA+DE+TABELA_14_08+-+Comercial+V2.pdf';

    case 'Cliente Normal Médico':
      return 'https://search-professional-front-hml-pricing.s3.amazonaws.com/PROPOSTA+DE+TABELA_14_08+-+Comercial+V2.pdf';

    case 'Distribuidor':
      return 'https://search-professional-front-hml-pricing.s3.amazonaws.com/PROPOSTA+DE+TABELA_14_08+-+Comercial+V2.pdf';
       
    case 'Franquia':
      return 'https://search-professional-front-hml-pricing.s3.amazonaws.com/04 ABRIL 23_TABELA DE FRANQUIAS.pdf';

    default:
      return 'https://search-professional-front-hml-pricing.s3.amazonaws.com/PROPOSTA+DE+TABELA_14_08+-+Comercial+V2.pdf';
  }
}
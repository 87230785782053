import styled, { keyframes } from 'styled-components'
import { HiX } from 'react-icons/hi';

export const Dimmer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(17, 24, 39, 0.8);
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Container = styled.div`
  background: var(--background);
  border-radius: 8px;
  padding: 20px 16px;
  display: flex;
  flex-direction: column;
  width: 240px;
  max-height: 85vh;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 70%;

  /* Tablet */
  @media (min-width:768px) {
    max-width: 400px;
  }
`;


export const ClosingContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

export const StyledHiX = styled(HiX)`
  cursor: pointer;
  background: rgba(100, 96, 170, 0.04);
  border-radius: 4px;
  padding: 10px;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 150px;
  margin-bottom: 10px;
`;

export const Header = styled.span`
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  color: var(--primaryTextColor);
`;

export const MicContainer = styled.div<{ hasErrorOnListening: boolean }>`
  padding: 8px;
  background: ${({ hasErrorOnListening }) => {
    console.log({ hasErrorOnListening })
    return hasErrorOnListening ? '#F5F5F5' : '#4A4785'
  } };
  border: ${({ hasErrorOnListening }) => hasErrorOnListening ? '4px solid #E61D02' : 'none'};
  border-radius: 50%;
  width: 54px;
  height: 54px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const DotWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 10px;
`;

const dotFlashingAnimation = keyframes`
  0% {
    background-color: var(--primaryTextColor);
  }
  50%, 100% {
    background-color: rgba(0, 0, 0, 0.1);
  }
`

export const Dot = styled.div`
  height: 6px;
  width: 6px;
  border-radius: 50%;
  background-color: var(--primaryTextColor);
  position: relative;
  animation: ${dotFlashingAnimation} 1s infinite linear alternate;
  animation-delay: 0.5s;
  margin-right: 4px; 

  &::before {
    content: "";
    display: inline-block;
    position: absolute;
    top: 0;
    left: -15px;
    animation: ${dotFlashingAnimation} 1s infinite alternate;
    animation-delay: 0s;
  }

  &::after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 0;
    left: 15px;
    animation: ${dotFlashingAnimation} 1s infinite alternate;
    animation-delay: 1s;
  }
`;

export const Message = styled.span`
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  text-align: center;
  color: var(--primaryTextColor);
`;

import { api } from 'config/api';
import { UsersTypeAccessContext } from './UsersTypeAccessContext';
import { useState } from 'react';
import { Hierarchy, UserTypeAccess } from 'types/UsersTypeAccessType';
import useAuth from 'hooks/useAuth';

type Props = {
  children?: React.ReactNode;
}

const sortList = (list: UserTypeAccess[]) => {
  const sorted = list.sort((a, b) => {
    const nomeA = a.email.toUpperCase();
    const nomeB = b.email.toUpperCase();
    if (nomeA < nomeB) {
      return -1;
    }
    if (nomeA > nomeB) {
      return 1;
    }
    return 0;
  });
  return sorted;
};

export const UsersTypeAccessProvider = ({ children }: Props) => {
  const [usersTypeAccess, setUsersTypeAccess] = useState<UserTypeAccess[]>([]);
  const [hierarchy, setHierarchy] = useState<Hierarchy[]>([]);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [usersToShow, setUsersToShow] = useState<UserTypeAccess[]>([]);

  const { auth } = useAuth();

  const getUsersTypeAccess = async () => {
    try {
      setLoading(true);

      const { data: typeAccess } = await api.get('user/typeAccess');

      setUsersTypeAccess(sortList(typeAccess));
    } catch (err) {
      setError(true);
    } finally {
      setLoading(false)
    }
  }

  const getHierarchy = async () => {
    try {
      const { data: hierarchy } = await api.get(`hierarchy/${auth ? auth.username : 'null'}`);

      setHierarchy(hierarchy);
    } catch (err) {
      setError(true);
    }
  }

  const isAdmin = () => {
    return usersTypeAccess.some((user: UserTypeAccess) => user.email === auth?.username && user.typeAccess.toLowerCase() === 'administrador');
  }

  const isManager = () => {
    return usersTypeAccess.some((user: UserTypeAccess) => user.email === auth?.username && user.typeAccess.toLowerCase() === 'gerente');
  }

  const isMyManagement = (clientConsultantEmail: any) => {
    return hierarchy.some((consultant: any) => consultant.email === clientConsultantEmail);
  }

  const isStandard = () => {
    return usersTypeAccess.some((user: UserTypeAccess) => user.email === auth?.username && user.typeAccess.toLowerCase() === 'padrão');
  }

  const isConsultant = () => {
    return usersTypeAccess.some((user: UserTypeAccess) => user.email === auth?.username && user.typeAccess.toLowerCase() === 'consultor');
  }

  const isFranchise = (clientCategory: any) => {
    clientCategory = clientCategory ? clientCategory : '';
    const lowerClientCategory = clientCategory.toLowerCase();

    const result: any = usersTypeAccess.some((user: UserTypeAccess) =>
      user.email === auth?.username &&
      user.typeAccess && user.typeAccess.toLowerCase().trim() === lowerClientCategory
    );
    return result;
  }

  const getMyHierarchySuperior = () => {
    const superior = usersTypeAccess.find((user: UserTypeAccess) => user.email === auth?.username);
    return superior?.hierarchy;
  }

  return (
    <UsersTypeAccessContext.Provider value={{
      usersTypeAccess, hierarchy, getUsersTypeAccess, setUsersTypeAccess,
      error, setError, loading, setLoading, usersToShow, setUsersToShow,
      isAdmin, isStandard, isConsultant, isManager, isMyManagement, isFranchise, getHierarchy, getMyHierarchySuperior
    }}>
      {children}
    </UsersTypeAccessContext.Provider>
  );
};

export default UsersTypeAccessProvider;

import styled from 'styled-components';

export const Dimmer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(17, 24, 39, 0.8);
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ContainerModalSuccess = styled.div`
  background: var(--background);
  border-radius: 8px;
  padding: 28px 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 327px;
  max-height: 85vh;
  overflow: hidden;
  height: auto;
`;

export const TextSuccessAdd = styled.span`
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 16px;
  margin-top: 24px;
  width: 150px;
  display: flex;
  align-items: center;
  text-align: center;
  color: var(--primaryTextColor);
`;
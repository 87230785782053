import { SyntheticEvent } from 'react';
import {
  HighlightedText,
  StyledHiOutlineInformationCircle,
  TooltipContainer,
  TooltipText,
  TooltipWrapper
} from './styles';

interface TooltipProps {
  text: string;
  highlightedText?: string;
  withoutMargin?: boolean;
  withoutAlignment?: boolean;
  hasNoHighlightedText?: boolean;
  isCheckInTooltip?: boolean;
  onClick?: (e: SyntheticEvent) => boolean;
}

export const Tooltip = ({ 
  text, 
  highlightedText, 
  withoutMargin, 
  withoutAlignment, 
  hasNoHighlightedText, 
  isCheckInTooltip,
  onClick 
}: TooltipProps) => {
  return (
    <TooltipWrapper withoutAlignment={withoutAlignment} zIndexLow={hasNoHighlightedText} isCheckInTooltip={isCheckInTooltip}>
      <StyledHiOutlineInformationCircle 
        withoutMargin={withoutMargin} 
        size={24} 
        color="#6460AA" 
        onClick={(e) => onClick && onClick(e)} 
      />

      <TooltipContainer>
        {hasNoHighlightedText ? (
          <TooltipText>
            {text}
          </TooltipText>
        ) : (
          <TooltipText>
            {text}
            <HighlightedText>{highlightedText ? highlightedText : 'Não disponível'}</HighlightedText>
          </TooltipText>
        )}
      </TooltipContainer>
    </TooltipWrapper>
  )
}
import styled from 'styled-components';

export const Container = styled.div`
  background: ${props => props.color};
  border-radius: 8px;
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Text = styled.span`
  color: ${props => props.color};
  font-size: 14px;
  font-weight: 600;
  line-height: 120%;
`;
import { useState } from 'react';
import { Container, Label, RadioInput } from './styles';
import useCustomers from 'hooks/useCustomers';

interface LoadMoreProps {
  lastPage: number;
}

export function LoadMore({ lastPage }: LoadMoreProps) {
  const [checked, setChecked] = useState(false);

  const { page, setPage } = useCustomers();

  const handleCheckInput = () => {
    if (page < lastPage) {
      setChecked(true);
      
      setPage(page + 1);
    }

    setTimeout(() => {
      setChecked(false);
    }, 1000);
  }

  return (
    <Container onClick={handleCheckInput}>
      <RadioInput checked={checked}/>

      <Label>Carregar mais</Label>
    </Container>
  )
}

import { CopiedToClipboard } from 'components/CopiedToClipboard';
import { HighlightedElement } from 'components/HighlightedElement';
import { HiOutlineChevronRight } from 'react-icons/hi';
import { useState } from 'react';
import { getNormalizedDate } from 'utils';
import copy from 'copy-to-clipboard';
import { 
  Container,
  Date,
  ItemCard,
  ItemCardHeader,
  CodeRow,
  Row,
  ItemCode,
  TrackingCode,
  DescriptionContainer,
  TitleWrapper,
  ItemText,
  MoreItems,
  StyledHiOutlineDocumentDuplicate,
  TotalSaleText,
  TotalSaleValue,
  SaleRow,
  DescriptionRow,
} from './styles';
import { CustomerHistoryProducts } from 'types/CustomerHistoryProducts';


// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const CustomerHistoryCard = ({ date, status, saleType, code, trackingCode, discount, deliveryFee, items, onClick }: CustomerHistoryProducts) => {
  const [hasCopied, setHasCopied] = useState(false);
  
  const handleCopyToClipboard = (trackingCode: string) => {
    copy(trackingCode);
    setHasCopied(true);

    setTimeout(() => {
      setHasCopied(false);
    }, 2000);
  }

  const getStatusColor = (status: string) => {
    switch (status) {
      case 'Em trânsito':
        return {
          primaryColor: '#0C2A75',
          r: '12',
          g: '42',
          b: '117',
          a: '0.05',
        }
      case 'Fat. Parcial':
      case 'Cancelado':
        return {
          primaryColor: '#E61D02',
          r: '230',
          g: '29',
          b: '2',
          a: '0.05',
        }
      case 'Par. em trânsito':
      case 'Parc. entregue':
        return {
          primaryColor: '#F5A623',
          r: '250',
          g: '158',
          b: '21',
          a: '0.05',
        }
      case 'Entregue':
        return {
          primaryColor: '#2B641E',
          r: '43',
          g: '100',
          b: '30',
          a: '0.05',
        }
      default:
        return {
          primaryColor: '#6D6E71',
          r: '100',
          g: '96',
          b: '170',
          a: '0.05',
        }
    }
  }

  const getSubtotal = () => {
    return items && items.reduce((acc, product) => {
      return acc + product.price * (product.quantity || 1);
    }, 0);
  }

  const getDiscount = () => {
    return items && items.reduce((acc, product) => {
      return acc + (product.discount || 0) * (product.quantity || 1);
    }, 0);
  }

  const getTotal = () => {
    return getSubtotal() + deliveryFee - getDiscount();
  }

  return (
    <Container>
      <Date>{getNormalizedDate(date)}</Date>
      
      <ItemCard>
        <ItemCardHeader>
          <CodeRow  onClick={onClick}>
            <HighlightedElement  
              primaryColor={getStatusColor(status).primaryColor}
              r={getStatusColor(status).r}
              g={getStatusColor(status).g}
              b={getStatusColor(status).b}
              a={getStatusColor(status).a}
              text={status}
              customStyle={{ padding: '4px 12px'}}
            />

            <ItemCode>N° {code}</ItemCode>
          </CodeRow>

          <SaleRow onClick={onClick}>
            <TotalSaleText>{saleType}</TotalSaleText>

            <TotalSaleValue>Total {getTotal().toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</TotalSaleValue>
          </SaleRow>

          {trackingCode && (
            <Row>
              <TrackingCode>{trackingCode}</TrackingCode>

              {!hasCopied ? (
                <StyledHiOutlineDocumentDuplicate 
                  size={24} 
                  color='#6D6E71' 
                  onClick={() => handleCopyToClipboard(trackingCode)}
                />
              ) : (
                <CopiedToClipboard />
              )}
            </Row>
          )}
        </ItemCardHeader>

        <DescriptionRow onClick={onClick}>
          <DescriptionContainer>
            <TitleWrapper>
              <HighlightedElement  
                primaryColor='#6D6E71'
                r='100'
                g='96'
                b='170'
                a='0.05'
                text={items[0].quantity ? items[0].quantity.toString(): '0'}
                customStyle={{ width: '25px', height: '25px' }}
              />

              <ItemText>{items[0].name}</ItemText>
            </TitleWrapper>

            {items.length > 1 && <MoreItems>Mais {items.length -1} {items.length > 2 ? 'itens' : 'item'}</MoreItems>}
          </DescriptionContainer>

          <HiOutlineChevronRight size={20} color='#6D6E71' />
        </DescriptionRow>
      </ItemCard>
    </Container>
  )
}
import styled from 'styled-components';

export const Container = styled.div`
  margin-left: 24px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const Message = styled.span`
  margin-left: 6px;
  color: var(--success);
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
`;
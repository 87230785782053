import { useEffect, useState } from 'react';
import { UserTypeAccess } from 'types/UsersTypeAccessType';
import { ArrowBack, ArrowForward, Container, NumberPages } from './styles';
import useCustomers from 'hooks/useCustomers';

interface Props {
  usersTypeAccess?: UserTypeAccess[];
  setUsersToShow?: (arr: UserTypeAccess[]) => void;
  pageSize: number;
  lastPage: number;
  onPageChange?: (page: number) => void;
  isCheckIn: boolean;
  isHome?: boolean;
}

export const Pagination = ({ pageSize, usersTypeAccess, setUsersToShow, lastPage, onPageChange, isCheckIn, isHome }: Props) => {
  const { setPage, currentPage, setCurrentPage } = useCustomers();

  useEffect(() => {
    if (isHome) {
      setPage(currentPage);
    }
  }, [currentPage]);

  function handlePageChange(pageNumber: number) {
    setCurrentPage(pageNumber);

    if (onPageChange) {
      onPageChange(pageNumber);
    }
  }

  function handlePageBack() {
    setCurrentPage(currentPage - 1);

    if (onPageChange) {
      onPageChange(currentPage - 1);
    }
  }

  function handlePageForward() {
    setCurrentPage(currentPage + 1);

    if (onPageChange) {
      onPageChange(currentPage + 1);
    }
  }

  const pages: number[] = [];
  for (let i = 1; i <= lastPage; i++) {
    pages.push(i);
  }

  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  
  useEffect(() => {
    if (usersTypeAccess && setUsersToShow) {
      setUsersToShow(usersTypeAccess.slice(startIndex, endIndex));
    }
  }, [currentPage])

  return (
    <Container isCheckIn={isCheckIn}>
      <ArrowBack cursorPointer={currentPage !== 1} onClick={() => currentPage !== 1 ? handlePageBack() : undefined}/>
      {pages.map((page) => {
        if (
          page === 1 || 
          page === lastPage ||
          (page >= currentPage - 2 && page <= currentPage + 2)
        ) {
          return (
            <NumberPages isActive={page === currentPage} key={page} onClick={() => handlePageChange(page)}>
              {page}
            </NumberPages>
          );
        }
        else if (pages.indexOf(page) === 2 || pages.indexOf(page) === lastPage - 2) {
          return (
            <NumberPages isDots key={page}>
              ...
            </NumberPages>
          );
        }
        return null;
      })}
      <ArrowForward cursorPointer={currentPage !== pages[pages.length - 1]} onClick={() => currentPage !== pages[pages.length - 1] ? handlePageForward() : undefined}/>
    </Container>
  )
}
import { Button } from 'components/Button';
import { HiOutlineTrash } from 'react-icons/hi';
import styled from 'styled-components';

export const  TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  
  /* Tablet */
  @media (min-width:768px) {
    width: 95%;
  }

  /* Desktop */
  @media (min-width:1025px) {
    width: 780px;
  }
`;

export const  Table = styled.table`
  border-collapse: collapse;
  border: none;
`;

export const TableRow = styled.tr`
  &:nth-child(even) {
    background-color: #f2f2f2;
  }
`;

export const  TableCell = styled.td<{ alignRight?: boolean, paddingRight?: boolean, relative?: boolean, column?: boolean, allWidth?: boolean }>`
  padding: ${({ paddingRight }) => (paddingRight ? '8px 30px 8px 8px' : '14px 0px')};
  text-align: ${({ alignRight }) => (alignRight ? 'right' : 'left')};
  position: ${({ relative }) => (relative ? 'relative' : 'undefined')};
  font-family: 'Roboto';
  font-size: 14px;
  color: #6D6E71;
  display: ${({ column }) => (column ? 'flex' : 'undefined')};
  flex-direction: ${({ column }) => (column ? 'column' : 'undefined')};
  display: ${({ allWidth }) => (allWidth ? '100%' : 'undefined')};
`;

export const  TableCellTitle = styled.td<{ alignRight?: boolean, paddingRight?: boolean, flex?: boolean }>`
  padding: ${({ paddingRight }) => (paddingRight ? '8px 30px' : '8px 12px')}; 
  text-align: ${({ alignRight }) => (alignRight ? 'right' : 'left')};
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 125%;
  color: #2F3132;
  display: ${({ flex }) => (flex ? 'flex' : 'undefined')};

  /* Desktop */
  @media (min-width:1025px) {
    font-size: 18px;
  }
`;

export const ContainerModalOption = styled.div`
  background: var(--background);
  border-radius: 8px;
  padding: 30px 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 327px;
  overflow: hidden;
  height: auto;
`;

export const TextTitleModal = styled.span`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  width: 239px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #6D6E71;
  margin-bottom: 30px;
`;

export const TextTitleModalOption = styled.span`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  width: 239px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #6D6E71;
  margin-top: 30px;
`;

export const ContainerButton = styled.div`
  display: flex;
  justify-content: space-between;
  width: 280px;
  align-items: center;
`;

export const OptionButton = styled(Button)`
  width: 163px;
  height: 46px;
`;

export const OptionCancelButton = styled.span`
  width: 55px;
  height: 21px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  display: flex;
  align-items: center;
  text-decoration-line: underline;
  color: #6460AA;
  cursor: pointer;
`;

export const StyledHiTrash = styled(HiOutlineTrash)`
  cursor: pointer;
  padding-right: 12px;
`;
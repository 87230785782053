import { IconBaseProps } from 'react-icons/lib';
import { HighlightedElement } from 'components/HighlightedElement';

import { 
  Container,
  TitleWrapper,
  Title,
  BoldText,
  Row
} from './styles';
import { Tooltip } from 'components/Tooltip';
import useCustomers from 'hooks/useCustomers';

interface ComercialItemProps {
  icon: React.ComponentType<IconBaseProps>
  title: string;
  text: string;
  hasSubtitle?: boolean;
}

export const ComercialItemCard = ({ icon: Icon, title, text, hasSubtitle }: ComercialItemProps) => {
  const { dateOffset } = useCustomers();
  
  return (
    <Container>
      <TitleWrapper>
        <HighlightedElement  
          primaryColor='#6D6E71'
          r='100'
          g='96'
          b='170'
          a='0.05'
          icon={Icon}
        />

        <Row>
          <Title>{title}</Title>

          {hasSubtitle && (
            <Tooltip
              text='Corresponde ao período dos '
              highlightedText={`últimos ${Math.floor(dateOffset / 30)} meses`}
            />
          )}
        </Row>
      </TitleWrapper>

      <BoldText>{text}</BoldText>
    </Container>
  )
}
export const TABS = [
  {
    type: 'details',
    name: 'Detalhes'
  },
  {
    type: 'notes',
    name: 'Anotações'
  },
  {
    type: 'requests',
    name: 'Solicitações'
  },
  {
    type: 'finnancial',
    name: 'Financeiro'
  }
];

export const FINNANCIAL_TABS = [
  {
    type: 'invoice',
    name: 'Boletos e NFe'
  }
]

export const REQUEST_TABS = [
  {
    type: 'analytics',
    name: 'Análise de crédito'
  },
  {
    type: 'material',
    name: 'Solicitar material'
  }
]

export const NOTES_TABS = [
  {
    type: 'profile',
    name: 'Perfil de cliente'
  },
  {
    type: 'annotation',
    name: 'Anotações'
  }
]
import { useEffect, useState } from 'react';
import { DayPicker } from 'react-day-picker';
import { pt } from 'date-fns/locale';
import 'react-day-picker/dist/style.css';
import { addDays, addYears } from 'date-fns';

const css = `
  .rdp {
    align-self: center;
    margin: 0;
  }
  .rdp-caption_label {
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-size: 16px;
    color: #2F3132;
    text-transform: capitalize;
  }
  .rdp-head_cell {
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-size: 14px;
    color: #6460AA;
    text-transform: capitalize;
  }
  .rdp-table {
    width: 100%;
    max-width: 100%;
  }
  .rdp-button_reset .rdp-button .rdp-day{
    color: #6D6E71;
  }
  .my-selected:not([disabled]) { 
    background-color: #ececec;
    color: #6D6E71;
    font-weight: 700;
  }
  .my-selected:hover:not([disabled]) { 
    border-color: #ececec;
  }
  .my-today { 
    font-weight: bold;
    color: #6D6E71;
  }
`;

interface Props {
  selectedNotifyDay: Date | undefined,
  setSelectedNotifyDay: (date: Date | undefined) => void,
}

export default function DateSingleCalendar({ selectedNotifyDay, setSelectedNotifyDay }: Props) {
  const today = new Date(); 

  const getWeekends = (start: Date, end: Date) => {
    const list = []
    
    let startCopy = start;
    const SATURDAY = 6;
    const SUNDAY = 0;

    while (startCopy <= end) {
      if (startCopy.getDay() === SUNDAY || startCopy.getDay() === SATURDAY) {
        list.push(startCopy)
      }

      startCopy = addDays(startCopy, 1);
    }

    return list;
  }

  const disabledDays = [
    {
      before: today,
    },
    ...getWeekends(new Date(), addYears(new Date(), 10))
  ];

  return (
    <>
      <style>{css}</style>
        <DayPicker
        mode="single"
        selected={selectedNotifyDay}
        onSelect={setSelectedNotifyDay}
        showOutsideDays
        locale={pt}
        weekStartsOn={0}
        modifiersClassNames={{
          selected: 'my-selected',
          today: 'my-today'
        }}
        disabled={disabledDays}
      /> 
    </>
  );
}
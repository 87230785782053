import styled from 'styled-components';

export const Container = styled.span<{isMobile?: boolean}>`
  display: flex;
  flex-direction: ${({ isMobile }) => (isMobile ? 'row' : 'undefined')};
`;

export const DropdownContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;
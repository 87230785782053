import { HiOutlinePlus, HiPlus } from 'react-icons/hi';
import { NotesProps } from 'types/NotesProps';
import {
  EmptyProfileContainer,
  Message,
  NavContainer,
  NotesContainer,
  NotesHeader,
  ProfileContainer,
  ProfileContent,
  ProfileHeader,
  ProfileInterests,
  ProfileMessage,
  ProfileTitle,
  StyledButton,
  StyledFiEdit,
  Tab
} from './styles';
import { useState } from 'react';
import { NOTES_TABS } from 'pages/Customer/utils/tabs';
import { Note } from 'components/Note';
import { Chip } from 'components/Chip';
import { ProfileModal } from 'components/ProfileModal';

export const Notes = ({ note, noteDeprecated, email, onClickSetShowNotesModal, profile }: NotesProps) => {
  const [activeTab, setActiveTab] = useState('profile');
  const [isProfileModalOpen, setIsProfileModalOpen] = useState(false);

  const reverseHistoryArray = () => {
    const reversedArray = [];

    for (let i = noteDeprecated?.history?.length - 1; i >= 0; i--) {
      reversedArray.push(noteDeprecated.history[i]);
    }

    return reversedArray
  }

  const renderActiveTab = () => {
    switch (activeTab) {
      case 'profile':
        return profile && profile?.customer === email ? (
          <ProfileContainer>
            <ProfileHeader>
              <ProfileTitle>Perfil do cliente</ProfileTitle>

              <StyledFiEdit
                size={20}
                color="#6460AA"
                onClick={() => setIsProfileModalOpen(true)}
              />
            </ProfileHeader>

            <ProfileContent>
              {profile.profile}
            </ProfileContent>

            <ProfileTitle>Resumo de interesses</ProfileTitle>

            <ProfileInterests>
              {profile.interests.map((interest: any) => {
                return (
                  <Chip
                    key={interest}
                    text={interest}
                    removeable={true}
                  />
                )
              })}
            </ProfileInterests>
          </ProfileContainer>
        ) : (
          <EmptyProfileContainer>
            <ProfileMessage>Ainda não há informações <br /> de perfil para este cliente</ProfileMessage>

            <StyledButton onClick={() => setIsProfileModalOpen(true)} style={{ marginBottom: 0 }}><HiPlus size={20} color='#fff' />Criar perfil</StyledButton>
          </EmptyProfileContainer>
        )

      case 'annotation':
        return note.length > 0 || (Object.keys(noteDeprecated).length !== 0) ? (
          <NotesContainer>
            <NotesHeader>Anotações recentes</NotesHeader>

            <StyledButton onClick={onClickSetShowNotesModal}>
              <HiOutlinePlus size={24} /> Adicionar
            </StyledButton>
            {note.map((n, index) => {
              return (
                <Note key={index} id={n.id} annotation={n.annotation} name={n.consultantName} customerName={n.customerName} customerDocument={n.document} createAt={n.createdAt} isNotification={n.isNotification} />
              )
            })}
            
            {noteDeprecated && noteDeprecated.annotation && <Note annotation={noteDeprecated.annotation} name={noteDeprecated.madeBy} createAt={noteDeprecated.createAt} />}

            {reverseHistoryArray().map((n, index) => {
              return (
                <Note key={index} annotation={n.annotation} name={n.madeBy} createAt={n.createAt} />
              )
            })}

          </NotesContainer>
        ) : (
          <NotesContainer>
            <Message>Ainda não há anotações</Message>

            <StyledButton onClick={onClickSetShowNotesModal}>
              <HiOutlinePlus size={24} /> Adicionar
            </StyledButton>
          </NotesContainer>
        )

      default:
        return null;
    }
  }

  if (isProfileModalOpen) {
    return (
      <ProfileModal
        email={email}
        onCloseModal={() => setIsProfileModalOpen(false)}
        currentProfile={profile}
      />
    )
  }

  return (
    <>
      <NavContainer>
        {NOTES_TABS.map(({ type, name }) => {
          return (
            <Tab onClick={() => setActiveTab(type)} key={type} style={activeTab === type ? { fontWeight: 600, color: '#fff', background: '#6460AA', whiteSpace: 'nowrap' } : { whiteSpace: 'nowrap' }}>{name}</Tab>
          )
        })}
      </NavContainer>

      {renderActiveTab()}
    </>
  )
}
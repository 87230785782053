import Error from 'assets/login-error.svg';
import { 
  Container,
  Wrapper,
  Title,
  Message,
  Image,
  StyledButton,
} from './styles';

export const LoginError = () => {
  const redirectTo = () => {
    window.location.href = 'https://skinstore.atlassian.net/servicedesk/customer/portal/5/group/40';
  }

  return (
    <Container>
      <Wrapper>
        <Title>Parece que você ainda não tem acesso...</Title>

        <Message>Solicite seu acesso clicando no link abaixo ou acione um membro do time de Digital no Teams</Message>

        <Image src={Error} />
        
        <StyledButton onClick={() => redirectTo()}>Solicitar acesso</StyledButton>
      </Wrapper>
    </Container>
  )
}
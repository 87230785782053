import { api } from 'config/api';
import useAuth from 'hooks/useAuth';
import { useEffect, useState } from 'react';
import { CustomerCheckIn } from 'types/CheckInType';
import { CheckInContext } from './CheckInContext';
import { FilterType } from './util';

type Props = {
  children?: React.ReactNode;
}

export const CheckInProvider = ({ children }: Props) => {
  const [customersCheckIn, setCustomersCheckIn] = useState<CustomerCheckIn[]>([]);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [activeItemIndex, setActiveItemIndex] = useState<number | null>(null);
  const [filter, setFilter] = useState<boolean>(true); // true === ASC | false === DESC
  const [isIconRotate, setIsIconRotate] = useState(false);
  const [searchCustomers, setSearchCustomers] = useState('');
  const [lastSearch, setLastSearch] = useState('');
  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);

  const { auth } = useAuth();

  useEffect(() => {
    getCustomersCheckIn()
  }, [page]);
  
  const getCustomersCheckIn = async () => {
    if (!auth?.username) {
      return ;
    }
    
    let URI = `/checkin/${auth ? auth.username : ''}?page=${page}`;

    if (searchCustomers !== '') {
      URI = URI + `&search=${searchCustomers}`;
    }

    try {
      setLoading(true);
      const { data: customers } = await api.get(URI);

      setLastPage(customers.lastPage);
      
      setCustomersCheckIn(sortCustomersCheckIn(customers.data));
      
      setLastSearch(searchCustomers);
    } catch (err) {
      setError(true);
    } finally {
      setLoading(false)
    }
  }

  const sortCustomersCheckIn = (list: CustomerCheckIn[]) => {
    switch (activeItemIndex) {
      case FilterType.CUSTOMERS_NAME:
        list = sortByName(list);
        break;
      case FilterType.VISIT_ON:
        list = sortByVisitOn(list);
        break;
      case FilterType.CALL_WHATSAPP:
        list = sortByCallWhatsapp(list);
        break;
      case FilterType.LAST_CONTACT:
        list = sortByLastContact(list);
        break;
    }
    return list;
  }

  const sortByName = (list: CustomerCheckIn[]) => {
    return list.sort((a, b) => filter ? a.name.localeCompare(b.name) : b.name.localeCompare(a.name));
  };


  const sortByVisitOn = (list: CustomerCheckIn[]) => {
    return list.sort((a, b) => {
      if (a.lastVisit === null && b.lastVisit === null) return 0; 
      if (a.lastVisit === null) return filter ? 1 : -1; 
      if (b.lastVisit === null) return filter ? -1 : 1;
      if (filter) {
        return Date.parse(a.lastVisit) - Date.parse(b.lastVisit); 
      } else {
        return Date.parse(b.lastVisit) - Date.parse(a.lastVisit);
      }
    });
  };
  

  const sortByCallWhatsapp = (list: CustomerCheckIn[]) => {
    return list.sort((a, b) => {
      if (a.lastCall === null && b.lastCall === null) return 0; 
      if (a.lastCall === null) return filter ? 1 : -1;
      if (b.lastCall === null) return filter ? -1 : 1;
      if (filter) {
        return Date.parse(a.lastCall) - Date.parse(b.lastCall);
      } else {
        return Date.parse(b.lastCall) - Date.parse(a.lastCall);
      }
    });
  }

  const sortByLastContact = (list: CustomerCheckIn[]) => {
    return list.sort((a, b) => {
      const lastContactA = a.lastContact ?? Number.MAX_SAFE_INTEGER;
      const lastContactB = b.lastContact ?? Number.MAX_SAFE_INTEGER;
      const orderA = filter ? lastContactA - lastContactB : lastContactB - lastContactA;
      const orderB = lastContactA === lastContactB ? a.name.localeCompare(b.name) : 0;
      const orderC = (lastContactA === Number.MAX_SAFE_INTEGER && filter) || (lastContactB === Number.MAX_SAFE_INTEGER && !filter) ? 1 : -1;
      return orderA || orderB || orderC;
    });
  };



  return (
    <CheckInContext.Provider value={{
      customersCheckIn, getCustomersCheckIn, setCustomersCheckIn, error, setError, loading, setLoading,
      activeItemIndex, setActiveItemIndex, searchCustomers, setSearchCustomers, lastSearch, setLastSearch,
      filter, setFilter, setIsIconRotate, isIconRotate, sortCustomersCheckIn, page, setPage, lastPage
    }}>
      {children}
    </CheckInContext.Provider>
  );
};

export default CheckInProvider;
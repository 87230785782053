import { capitalize, formatCNPJ, formatCPF, getNormalizedDate } from 'utils';

const fullMessageCreditAnalysis = (name: string, cpf?: string, cnpj?: string, authName?: string) => { 

return `Olá!

%0D%0A

Solicito análise de crédito para o cliente abaixo:

%0D%0A
%0D%0A

Cliente: ${capitalize(name.toLowerCase())}  

%0D%0A

Documento: ${cpf ? 'CPF' : 'CNPJ'} ${cpf ? formatCPF(cpf) : cnpj ? formatCNPJ(cnpj) : null}  

%0D%0A

Valor total de R$ (Informe o valor) 

%0D%0A

Condições de pagamento: (Informe a condição de pagamento)

%0D%0A
%0D%0A

Atenciosamente,

%0D%0A
%0D%0A

${authName}

%0D%0A
%0D%0A

*Solicitação enviada a partir do MedSearch.
`
} 

const copyFullMessageCreditAnalysis = (name: string, cpf?: string, cnpj?: string, authName?: string) => { 

return `Olá!

Solicito análise de crédito para o cliente abaixo:

Cliente: ${capitalize(name.toLowerCase())}  

Documento: ${cpf ? 'CPF' : 'CNPJ'} ${cpf ? formatCPF(cpf) : cnpj ? formatCNPJ(cnpj) : null}  

Valor total de R$ (Informe o valor) 

Condições de pagamento: (Informe a condição de pagamento)

Atenciosamente,

${authName}

*Solicitação enviada a partir do MedSearch.
`
} 


const fullMessageMaterial = (name: string, email: string, address: string, CEP: string,  cpf?: string, cnpj?: string, authName?: string) => { 

  return `Olá!
  
%0D%0A

Solicito envio de material para o cliente abaixo:

%0D%0A
%0D%0A

Cliente: ${capitalize(name.toLowerCase())}  

%0D%0A

Documento: ${cpf ? 'CPF' : 'CNPJ'} ${cpf ? formatCPF(cpf) : cnpj ? formatCNPJ(cnpj) : null}  

%0D%0A

Email: ${email}

%0D%0A
%0D%0A

Material: [variável a ser inclusa pelo consultor]

%0D%0A

Endereço: ${address}

%0D%0A

CEP: ${CEP}

%0D%0A
%0D%0A

Atenciosamente,

%0D%0A
%0D%0A

${authName}

%0D%0A
%0D%0A

*Solicitação enviada a partir do MedSearch.
  `
  } 
  
  const copyFullMessageMaterial = (name: string, email: string, address: string, CEP: string,  cpf?: string, cnpj?: string, authName?: string) => { 
  
  return `Olá!

Solicito envio de material para o cliente abaixo:


Cliente: ${capitalize(name.toLowerCase())}  

Documento: ${cpf ? 'CPF' : 'CNPJ'} ${cpf ? formatCPF(cpf) : cnpj ? formatCNPJ(cnpj) : null}  

Email: ${email}


Material: [variável a ser inclusa pelo consultor]

Endereço: ${address}

CEP: ${CEP}


Atenciosamente,

${authName}

*Solicitação enviada a partir do MedSearch.
  `
  } 

export { fullMessageCreditAnalysis, copyFullMessageCreditAnalysis, fullMessageMaterial, copyFullMessageMaterial }



import { useEffect, useState } from 'react';
import { DateRange, DayPicker } from 'react-day-picker';
import { subYears } from 'date-fns';
import { pt } from 'date-fns/locale';
import 'react-day-picker/dist/style.css';

const css = `
  .rdp {
    align-self: center;
    margin: 0;
  }
  .rdp-caption_label {
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-size: 16px;
    color: #2F3132;
    text-transform: capitalize;
  }
  .rdp-head_cell {
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-size: 14px;
    color: #6460AA;
    text-transform: capitalize;
  }
  .rdp-table {
    width: 100%;
    max-width: 100%;
  }
  .rdp-button_reset .rdp-button .rdp-day{
    color: #6D6E71;
  }
  .my-selected:not([disabled]) { 
    background-color: #ececec;
    color: #6D6E71;
    font-weight: 700;
    margin: 3px 0;
  }
  .my-selected:hover:not([disabled]) { 
    border-color: #ececec;
  }
  .my-today { 
    font-weight: bold;
    color: #6D6E71;
  }
`;

interface Props {
  from: string,
  to: string,
  setFrom: (date: string) => void,
  setTo: (date: string) => void,
}

export default function DateRangeCalendar({ from, to, setFrom, setTo }: Props) {
  const defaultSelected: DateRange = {
    from: new Date(from),
    to: new Date(to)
  };

  const [range, setRange] = useState<DateRange | undefined>(defaultSelected);

  useEffect(() => {
    if (range?.from) {
      setFrom(range?.from?.toISOString());
    }
    if (range?.to) {
      setTo(range?.to?.toISOString());
    }
  }, [range]);

  return (
    <>
      <style>{css}</style>
      
      <DayPicker
        id="test"
        mode="range"
        defaultMonth={new Date()}
        selected={range}
        onSelect={setRange}
        showOutsideDays
        fixedWeeks
        locale={pt}
        weekStartsOn={0}
        fromDate={subYears(new Date(), 1)}
        toDate={new Date()}
        modifiersClassNames={{
          selected: 'my-selected',
          today: 'my-today'
        }}
      />
    </>
  );
}
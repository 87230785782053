/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/prop-types */
import { ComercialItemCard } from 'components/ComercialItemCard';
import { CopiedToClipboard } from 'components/CopiedToClipboard';
import { CustomerHistoryCard } from 'components/CustomerHistoryCard';
import { Filter } from 'components/Filter';
import { HighlightedElement } from 'components/HighlightedElement';
import { MostShoppedItemsCard } from 'components/MostShoppedItemsCard';
import copy from 'copy-to-clipboard';
import useCustomers from 'hooks/useCustomers';
import { useState } from 'react';
import { HiArrowCircleLeft, HiArrowRight, HiClipboardList, HiCurrencyDollar, HiLocationMarker, HiMail, HiPhone } from 'react-icons/hi';
import { IoLogoWhatsapp } from 'react-icons/io';
import { OrderDetail } from 'types/OrderDetail';
import { phoneMask, truncate } from 'utils';
import { 
  AddressAndButtonWrapper, 
  AddressText, 
  ComercialData, 
  ContactItem, 
  ContactsContainer, 
  ContactsWrapper, 
  ContactText, 
  CustomerHistoryContainer, 
  EmailWrapper, 
  HistoryHeader, 
  HistoryTitle, 
  HistoryWrapper, 
  MostShoppedEmptyList, 
  MostShoppedItemsContainer, 
  MostShoppedItemsFooter, 
  MostShoppedItemsFooterText, 
  MostShoppedItemsHeader, 
  MostShoppedItemsMessage, 
  MostShoppedItemsTitle, 
  MostShoppedItemsWrapper, 
  StyledButton, 
  StyledHiChevronRight, 
  StyledHiOutlineDocumentDuplicate
} from './styles';

export const Details = ({ isMobile, phone, whatsapp, email, address, mostShoppedItems, lastShopping, averageTicket, orders, onClickSetShowMostShoppedItemsModal, onClickHandleOrderClick }: any) => {
  const [hasCopied, setHasCopied] = useState(false);

  const { dateOffset } = useCustomers();

  const handleCopyToClipboard = () => {
    copy(email as string);
    setHasCopied(true);

    setTimeout(() => {
      setHasCopied(false);
    }, 2000);
  }

  return (
    <>
      <ContactsContainer>
        <ContactsWrapper>
          <ContactItem>
            <HighlightedElement  
              primaryColor='#6D6E71'
              r='100'
              g='96'
              b='170'
              a='0.05'
              icon={HiPhone}
            />

            <a href={`tel:${phone ? phone : whatsapp ? whatsapp : ''}`} target='_blank' rel='noreferrer'>
              <ContactText>{phone ? phoneMask(phone) : whatsapp ? phoneMask(whatsapp) : 'N/A'}</ContactText>
            </a>
          </ContactItem>
        
          <ContactItem>
            <HighlightedElement  
              primaryColor='#6D6E71'
              r='100'
              g='96'
              b='170'
              a='0.05'
              icon={IoLogoWhatsapp}
            />

            <a href={`https://wa.me/+55${whatsapp}`} target='_blank' rel='noreferrer'>
              <ContactText>{whatsapp ? phoneMask(whatsapp) : 'N/A'}</ContactText>
            </a>
          </ContactItem>
        </ContactsWrapper>

        <EmailWrapper>
          <ContactItem>
            <HighlightedElement  
              primaryColor='#6D6E71'
              r='100'
              g='96'
              b='170'
              a='0.05'
              icon={HiMail}
            />

            <a href={`mailto:${email}`} target='_blank' rel="noreferrer">
              <ContactText>{email ? isMobile ? truncate(email) : truncate(email, 25) : ''}</ContactText>
            </a>
          </ContactItem>

          {!hasCopied ? (
            <StyledHiOutlineDocumentDuplicate 
              size={24} 
              color='#6D6E71' 
              onClick={handleCopyToClipboard}
            />
          ) : (
            <CopiedToClipboard />
          )}
        </EmailWrapper>

        <AddressAndButtonWrapper>
          <ContactItem>
            <HighlightedElement  
              primaryColor='#6D6E71'
              r='100'
              g='96'
              b='170'
              a='0.05'
              icon={HiLocationMarker}
            />

            <AddressText>{address}</AddressText>
          </ContactItem>
        
        <a href={`https://waze.com/ul?q=${address}`} target='_blank' rel='noreferrer'>
          <StyledButton>
            Ver Rota <HiArrowRight size={24} color='#fff' style={{ marginLeft: '10px'}}/>
          </StyledButton>
        </a>
        </AddressAndButtonWrapper>
      </ContactsContainer>

      {mostShoppedItems && (
        <MostShoppedItemsContainer>
          <MostShoppedItemsHeader>
            <MostShoppedItemsTitle>Itens mais comprados</MostShoppedItemsTitle>

            <MostShoppedItemsMessage>Últimos {Math.floor(dateOffset / 30)} meses</MostShoppedItemsMessage>
          </MostShoppedItemsHeader>

          {mostShoppedItems.length > 0 ? (
            <MostShoppedItemsWrapper>
              {mostShoppedItems.map(({ description, quantity }: { description: string, quantity: number }, index: number) => {
                return index < 3 && (
                  <MostShoppedItemsCard 
                    key={index}
                    position={index + 1}
                    description={description}
                    quantity={quantity}
                  />
                )
              })}
            </MostShoppedItemsWrapper>
          ) : <MostShoppedEmptyList>Não houve compra nos últimos {Math.floor(dateOffset / 30)} meses</MostShoppedEmptyList>}

          {mostShoppedItems && mostShoppedItems.length > 3 && (
            <MostShoppedItemsFooter onClick={() => onClickSetShowMostShoppedItemsModal(true)}>
              <MostShoppedItemsFooterText>
                {mostShoppedItems.length === 4 ? 'Mais 1 item' : `Mais ${mostShoppedItems.length - 3} itens`}
              </MostShoppedItemsFooterText>

              <StyledHiChevronRight size={24} color='##6D6E71' />
            </MostShoppedItemsFooter>
          )}
        </MostShoppedItemsContainer>
      )}

      <ComercialData>
        <ComercialItemCard 
          icon={HiArrowCircleLeft}
          title='Última compra'
          text={ lastShopping === null ? 'N/A' : lastShopping === 0 ? 'Hoje' : `Há ${lastShopping} ${lastShopping === 1 ? 'dia' : 'dias'}`}
        />

        <ComercialItemCard
          icon={HiCurrencyDollar}
          title='Ticket médio'
          text={averageTicket ? averageTicket.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL'}) : 'N/A'}
          hasSubtitle
        />

        <ComercialItemCard
          icon={HiClipboardList}
          title='Qtd. Pedidos'
          text={orders && orders.length.toString()}
          hasSubtitle
        />
      </ComercialData>

      <CustomerHistoryContainer>
        <HistoryHeader>
          <HistoryTitle>Histórico de pedidos</HistoryTitle>

          <Filter isMobile={isMobile} />
        </HistoryHeader>

        <HistoryWrapper>
          {orders && orders.map((order: OrderDetail) => {
            return (
              <CustomerHistoryCard
                key={order.id}
                date={order.date ? order.date : 'N/A'}
                status={order.status ? order.status : 'N/A'}
                code={order.code ? order.code : 'N/A'}
                saleType={order.saleType ? order.saleType : ''}
                trackingCode={order.trackingCode}
                items={order.items ? order.items : []}
                discount={order.discount ? order.discount : 0}
                deliveryFee={order.deliveryFee ? order.deliveryFee : 0}
                onClick={() => onClickHandleOrderClick(order)}
              />
            )
          })}
        </HistoryWrapper>
      </CustomerHistoryContainer>
    </>
  )
}
import { Button } from 'components/Button';
import styled from 'styled-components';

export const Dimmer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(17, 24, 39, 0.8);
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ContainerModalSuccess = styled.div`
  background: var(--background);
  border-radius: 8px;
  padding: 20px 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 327px;
  max-height: 85vh;
  overflow: hidden;
  height: auto;
`;

export const TextSuccessAdd = styled.span`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  margin-top: 20px;
  width: 239px;
  display: flex;
  align-items: center;
  text-align: center;

  color: #6D6E71;
`;

export const ContainerModalForm = styled.div`
  background: var(--background);
  border-radius: 8px;
  padding: 20px 16px;
  display: flex;
  flex-direction: column;
  width: 85%;
  max-height: 85vh;
  overflow: hidden;
  height: auto;

  /* Tablet */
  @media (min-width:768px) {
    max-width: 400px;
  }
`;

export const ModalHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 12px;  
`;

export const Title = styled.h1`
  color: var(--primaryTextColor);
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 120%;
  text-align: center;
  flex: 1;
`;


export const Clickable = styled.div`
  cursor: pointer;
  margin-left: auto;
`;

export const ContainerSearchUser = styled.div`
  width: 100%;
  margin: 30px 0;
`;

export const ContainerTypeAccess = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`;

export const TypeAccessTitleSpan = styled.span`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color:  var(--primaryTextColor);
`;


export const DropdownContainer = styled.div`
  position: relative;
  z-index: 1000;
  display: inline-block;
`;

export const DropdownButton = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
  width: 150px;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 14px;
  color:  var(--primaryTextColor);
`;

export const DropdownList = styled.ul`
  position: absolute;
  height: 120px;
  width: auto;
  background: #FFFFFF;
  box-shadow: 0px 4px 12px rgba(146, 140, 151, 0.25);
  border-radius: 4px;
  border: none;
  padding: 0;
  margin: 0;
  list-style: none;
  z-index: 10;
  overflow-y: scroll;

  /* Mobile */
  @media (max-width:767px) {
    margin-top: 20px;
  }

  /* Tablet */
  @media (min-width:768px) {
    top: 100%;
    right: 0;
  }

  /* Desktop */
  @media (min-width:1025px) {
    top: 100%;
    right: 0;
  }
`;

export const DropdownItem = styled.li<{ isSelected?: boolean }>`
  padding: 8px;
  cursor: pointer;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 14px;
  color:  var(--primaryTextColor);
  background-color: ${({ isSelected }) => (isSelected ? '#ECECEC' : 'undefined')};

  &:hover {
    background-color: #ECECEC;
  }
`;

export const ContainerAddButton = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 30px; 
  margin-bottom: 15px; 
`;

export const AddButton = styled(Button)`
  width: 163px;
  height: 46px;
`;
/* eslint-disable @typescript-eslint/no-explicit-any */
import PDFMerger from 'pdf-merger-js';
import { HiOutlineDownload } from 'react-icons/hi';
import { Container, HighlightedText, StyledGoAlert, Text, TooltipContainer, TooltipText, Wrapper } from './styles';

interface TagProps {
  hasAlert: boolean;
  text: string;
  link: string | string[];
  order?: string;
}

export function Tag({ hasAlert, text, link, order }: TagProps) {
  const handleDownload = async (e: any) => {
    e.preventDefault()

    if (link.length === 0) {
      return null
    }

    const merger = new PDFMerger();

    if (Array.isArray(link)) {

      for (const file of link) {
        await merger.add(file);
      }

      await merger.save(`${text}-pedido-${order}`);

      return null
    }

    await merger.add(link);
    await merger.save(`${text}-pedido-${order}`);
    return null
  }

  return (
    <Container
      onClick={(e: any) => handleDownload(e)}
      style={link.length > 0 ? { cursor: 'pointer' } : { cursor: 'not-allowed' }}
    >
      {hasAlert && (
        <Wrapper>
          <StyledGoAlert size={18} color='#FA9E15' />

          <TooltipContainer>
            <TooltipText>Boletos vencidos há mais de <HighlightedText>5 dias</HighlightedText>, consulte o financeiro.</TooltipText>
          </TooltipContainer>
        </Wrapper>
      )}

      <Text>{text}</Text>

      <HiOutlineDownload size={18} color='#6460AA' />
    </Container>
  )
}
import useCustomers from 'hooks/useCustomers';
import { useState } from 'react';
import { Container, DropdownContainer, FilterItem, FilterList, Label, StyledFilterIcon } from './styles';

interface Props {
  isMobile?: boolean;
}

export function Filter({ isMobile }: Props) {
  const [isOpen, setIsOpen] = useState(false);

  const { dateOffset, setDateOffset } = useCustomers() 

  return (
    <Container onClick={() => setIsOpen(!isOpen)}>
      {!isMobile && <Label>Período:</Label>}

      <Label>Últimos {Math.floor(dateOffset / 30)} meses</Label>

      <StyledFilterIcon size={20} color='#6460AA'/>

      {isOpen && (
        <DropdownContainer>
          <FilterList>
            <FilterItem onClick={() => setDateOffset(90)}>Últimos 3 meses</FilterItem>
            <FilterItem onClick={() => setDateOffset(180)}>Últimos 6 meses</FilterItem>
            <FilterItem onClick={() => setDateOffset(270)}>Últimos 9 meses</FilterItem>
            <FilterItem onClick={() => setDateOffset(365)}>Últimos 12 meses</FilterItem>
          </FilterList>
        </DropdownContainer>
      )}
    </Container>
  )
}

import { ChangeEvent, useEffect, useState } from 'react';
import Toggle from 'react-toggle'
import { useWindowDimensions } from 'hooks/useWindowDimensions';
import MedSearchLogo from 'assets/logo-by-med-search.png';
import { Input } from 'components/Input';
import { HiMicrophone, HiOutlineMicrophone, HiOutlineSwitchVertical, HiOutlineX, HiSearch } from 'react-icons/hi';
import { Dropdown } from 'components/Dropdown';
import {
  Container,
  ContainerLogo,
  ContentContainer,
  FilterWrapper,
  HomeContainer,
  InputWrapper,
  Logo,
  MicContainer,
  Row,
  SearchContainer,
  SearchInputContainer,
  SearchTitle,
  ToggleLabel,
  TogglerContainer,
  Version,
  VersionContainer
} from './styles';
import { Header } from 'components/Header';
import { CustomersList } from 'components/CustomersList';
import { Pagination } from 'components/Pagination';
import useCustomers from 'hooks/useCustomers';
import { Loader } from 'components/Loader';
import { ErrorScreen } from 'components/ErrorScreen';
import './toggle.css';
import { CustomerReturn } from 'types/CustomersContextType';
import { SpeakerModal } from 'components/SpeakerModal';
import { LoadMore } from 'components/LoadMore';
import useUsersTypeAccess from 'hooks/useUsersTypeAccess';
import packageInfo from '../../../package.json';
import useAuth from 'hooks/useAuth';

export function Home() {
  const [showSpeakerModal, setShowSpeakerModal] = useState(false);
  const [chosenOption, setChosenOption] = useState('Último check-in');
  const [order, setOrder] = useState('check-in');
  const [valueInput, setValueInput] = useState('');
  const [asc, setAsc] = useState(true);

  const { isAdmin } = useUsersTypeAccess();
  const { auth } = useAuth();

  const {
    page,
    setPage,
    getCustomers,
    customers,
    loading,
    search,
    lastSearch,
    setSearch,
    error,
    isMyClientsOnly,
    setIsMyClientsOnly
  } = useCustomers();
  const { width } = useWindowDimensions();

  useEffect(() => {
    setPage(1);

    getCustomers('');
  }, [isMyClientsOnly])

  useEffect(() => {
    if (auth?.username) {
      getCustomers('');
    }
  }, [page, auth?.username]);

  useEffect(() => {
    if (search !== lastSearch) {
      setPage(1);
      getCustomers(search);
    }
  }, [search]);

  const isDesktop = width > 1025;
  const isMobile = width < 768;

  useEffect(() => {
    setAsc(true);
  }, [order]);

  useEffect(() => {
    switch (chosenOption) {
      case 'Última compra':
        setOrder('compra');

        break;

      case 'Último check-in':
        setOrder('check-in');

        break;

      case 'Nome do cliente':
        setOrder('cliente');

        break;
    }
  }, [chosenOption]);

  useEffect(() => {
    switch (order) {
      case 'compra':
        setChosenOption('Última compra');

        break;

      case 'check-in':
        setChosenOption('Último check-in');

        break;

      case 'cliente':
        setChosenOption('Nome do cliente');

        break;
    }
  }, [order]);

  const orderList = (list: CustomerReturn[]) => {
    const orderedList = [];

    switch (order) {
      case 'cliente':
        orderedList.push(...list.sort((a, b) => {
          if (a.name < b.name) {
            return -1;
          }

          if (a.name > b.name) {
            return 1;
          }

          return 0;
        }));

        break;

      case 'compra':
        orderedList.push(...list.sort((a, b) => {
          if (a.lastShopping === null) {
            return 1;
          }

          if (b.lastShopping === null) {
            return -1;
          }

          if (a.lastShopping < b.lastShopping) {
            return -1;
          }

          if (a.lastShopping > b.lastShopping) {
            return 1;
          }

          return 0;
        }));

        break;

      default:
        orderedList.push(...list.sort((a, b) => {
          if (a.lastContact === null) {
            return 1;
          }

          if (b.lastContact === null) {
            return -1
          }

          if (a.lastContact < b.lastContact) {
            return -1;
          }

          if (a.lastContact > b.lastContact) {
            return 1;
          }

          return 0;
        }));

        break;
    }

    return asc ? orderedList : orderedList.reverse();
  }

  const handleOrder = (column: string) => {
    setOrder(column);

    setAsc(!asc);
  }

  if (loading) {
    return (
      <>
        <Header />

        <Loader
          message1='Estamos buscando os'
          message2='resultados...'
        />
      </>
    )
  }

  if (error) {
    return (
      <>
        <Header />

        <ErrorScreen
          title='Algo deu errado...'
          message='Mas logo será resolvido.'
          status={400}
        />
      </>
    )
  }

  return (
    <>
      <Header />

      {showSpeakerModal && (
        <SpeakerModal
          onCloseModal={() => setShowSpeakerModal(false)}
          setSearch={setSearch}
          setInputValue={setValueInput}
        />
      )}

      {!isDesktop && (
        <MicContainer
          onClick={() => setShowSpeakerModal(true)}
        >
          <HiMicrophone size={32} color='#FFF' />
        </MicContainer>
      )}

      <Container>
        {isDesktop && (
          <ContainerLogo>
            <Logo src={MedSearchLogo} />
          </ContainerLogo>
        )}

        <HomeContainer>
          <ContentContainer>
            <SearchContainer>
              <SearchTitle>Busque por um cliente</SearchTitle>

              <SearchInputContainer>
                <InputWrapper>
                  <Input
                    placeholder='Busque por nome e cpf/cnpj'
                    onChange={(e: ChangeEvent<HTMLInputElement>) => setValueInput(e.target.value)}
                    onClick={() => setSearch(valueInput)}
                    value={valueInput}
                    icon={HiSearch}
                    right
                  />
                </InputWrapper>

                <TogglerContainer>
                  <Toggle
                    icons={false}
                    id='toggle'
                    checked={isAdmin() ? false : isMyClientsOnly}
                    onChange={() => setIsMyClientsOnly(isAdmin() ? false : !isMyClientsOnly)}
                  />

                  <ToggleLabel htmlFor='toggle'>{isMyClientsOnly ? 'Somente na minha carteira' : 'Em toda a base MedBeauty'}</ToggleLabel>
                </TogglerContainer>
              </SearchInputContainer>

              <Row>
                <FilterWrapper>
                  <Dropdown
                    chosenOption={chosenOption}
                    setChosenOption={setChosenOption}
                  />
                </FilterWrapper>

                {isMobile && <HiOutlineSwitchVertical size={24} color='#6460AA' onClick={() => setAsc(!asc)} />}
              </Row>
            </SearchContainer>

            <CustomersList
              customers={orderList(customers.data || [])}
              handleOrder={handleOrder}
              order={order}
            />

            {!isMobile ? (
              <Pagination
                lastPage={customers.lastPage}
                pageSize={customers.perPage}
                isHome
                isCheckIn
              />
            ) : (
              <LoadMore
                lastPage={customers.lastPage}
              />
            )}
          </ContentContainer>

          <VersionContainer>
            <Version>Versão {packageInfo.version}</Version>
          </VersionContainer>
        </HomeContainer>
      </Container>
    </>
  )
}

import { useState } from 'react';
import { 
  Container, 
  DropdownContainer, 
  FilterItem, 
  FilterList, 
  Label, 
  StyledFilterIcon 
} from './styles';

interface Props {
  chosenOption: string;
  setChosenOption: (str: string) => void;
}

export function Dropdown({ chosenOption, setChosenOption }: Props) {
  const [isOpen, setIsOpen] = useState(false);
  
  return (
    <Container onClick={() => setIsOpen(!isOpen)}>
      <Label>Ordenar por: {chosenOption}</Label>

      <StyledFilterIcon size={20} color='#6460AA'/>

      {isOpen && (
        <DropdownContainer>
          <FilterList>
            <FilterItem onClick={() => setChosenOption('Última compra')}>Última compra</FilterItem>
            <FilterItem onClick={() => setChosenOption('Último check-in')}>Último check-in</FilterItem>
            <FilterItem onClick={() => setChosenOption('Nome do cliente')}>Nome do cliente</FilterItem>
          </FilterList>
        </DropdownContainer>
      )}
    </Container>
  )
}

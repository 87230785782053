import styled from 'styled-components';
import Avatar from 'react-avatar';
import { HiOutlineDocumentDuplicate } from 'react-icons/hi';
import { Button } from 'components/Button';

export const Splitter = styled.div`
  display: flex;
`; 

export const LeftSide = styled.div`
  width: 980px;
  margin-right: 24px;
`;

export const CustomerHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 45px;
  border: 1px solid #F5F5F5;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 24px 32px;
  background-color: var(--background);
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledAvatar = styled(Avatar)`
  align-self: center;
  font-size: 16px;
  font-weight: 600;
`;

export const CustomerHeaderInfo = styled.div`
  margin-left: 24px;
  max-width: 556px;
`;

export const CustomerName = styled.h1`
  color: var(--secondaryTextColor);
  font-size: 20px;
  font-weight: 600;
  line-height: 150%;
  margin-bottom: 12px;
`;

export const CustomerHeaderType = styled.span`
  font-family: 'Roboto', sans-serif;
  color: var(--primaryTextColor);
  font-size: 14px;
  display: block;
  margin-bottom: 8px;
  line-height: 150%;
`;

export const Divider = styled.div`
  height: 1px;
  background: var(--dividerColor);
  margin: 8px 0;
`;

export const CashBeautyContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-self: flex-start;
`;

export const CustomerHistoryContainer = styled.section`

`;

export const HistoryHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 32px;
`;

export const HistoryTitle = styled.span`
  color: var(--secondaryTextColor);
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
`;

export const RightSide = styled.div`

`;

export const ContactsContainer = styled.div`
  padding: 0 4px;
  border: 1px solid #F5F5F5;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 24px 32px;
  background-color: var(--background);
`;

export const ContactsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
`;

export const ContactItem = styled.div`
  display: flex;
  align-items: center;
  max-width: 400px;
`;

export const EmailWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 40px;
`;

export const StyledHiOutlineDocumentDuplicate = styled(HiOutlineDocumentDuplicate)`
  margin-left: 36px;
  cursor: pointer;
`;

export const AddressAndButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledButton = styled(Button)`
  width: 163px;
  margin-top: 35px;
`;

export const ContactText = styled.span`
  margin-left: 8px;
  font-size: 16px;
  line-height: 125%;
  color: var(--secondaryTextColor);
  text-decoration: underline;
`;

export const MostShoppedItemsContainer = styled.div`
  padding: 24px 32px;
  padding-bottom: 35px;
  background-color: var(--background);
  border: 1px solid #F5F5F5;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  margin-top: 40px;
  width: calc(100% - 64px);
`;

export const MostShoppedItemsHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
`;

export const MostShoppedItemsTitle = styled.span`
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  color: var(--secondaryTextColor);
`;

export const MostShoppedItemsMessage = styled.span`
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: var(--primaryTextColor);
`;

export const MostShoppedItemsWrapper = styled.ul`

`;

export const MostShoppedItemsFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 11px;
  cursor: pointer;
`;

export const MostShoppedItemsFooterText = styled.span`
  text-decoration-line: underline;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
`;

export const ComercialData = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 40px;
  width: 428px;
`;

export const AddressText = styled.span`
  margin-left: 8px;
  font-size: 16px;
  line-height: 125%;
  color: var(--secondaryTextColor);
`;

export const CustomerHeaderText = styled.span`
  color: var(--primaryTextColor);
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  font-family: 'Poppins', sans-serif;
  margin-bottom: 8px;
`;

export const MostShoppedEmptyList = styled.span`
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: var(--primaryTextColor);
  display: block;
  text-align: center;
  margin-bottom: -11px;
`;

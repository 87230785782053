/* eslint-disable @typescript-eslint/no-explicit-any */
import { Container, MAX_LAYERS, Pulse, Wrapper } from './styles';

export const Pulsating = ({ children, ...rest }: any) => {
  return (
    <Wrapper>
      <Container>{children}</Container>

      {Array.from(Array(MAX_LAYERS).keys()).map((key) => (
          <Pulse key={key} layer={key} {...rest} />
        ))}
    </Wrapper>
  )  
}
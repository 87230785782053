/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  NavContainer,
  Tab,
  FilterContainer,
  InvoiceContainer,
  Divider,
  Table,
  TableRow,
  TableHeader,
  TableData,
} from './styles';
import { useState } from 'react';
import { FINNANCIAL_TABS } from 'pages/Customer/utils/tabs';
import { Filter } from 'components/Filter';
import { Tag } from 'components/Tag';
import { Modal } from 'components/Modal';
import { OrderDetail } from 'types/OrderDetail';
import { handleFiles } from 'utils/handleFiles';
import { getNormalizedDate } from 'utils';

export const Finnancial = ({ orders }: { name: string, cpf?: string, cnpj?: string, orders: any, clientCategory: string }) => {
  const [activeTab, setActiveTab] = useState('invoice');
  const [openModal, setOpenModal] = useState(false);
  const [modalOrder, setModalOrder] = useState({} as OrderDetail);

  const handleModal = (id: string) => {
    const order = orders.find((order: OrderDetail) => order.id === id)

    setModalOrder(order)
    setOpenModal(true)
  }


  const renderActiveTab = () => {
    switch (activeTab) {
      case 'invoice':
        return (
          <>
            {openModal && (
              <Modal
                code={modalOrder.code}
                items={modalOrder.items}
                saleType={modalOrder.saleType}
                shippingAddress={modalOrder.shippingAddress}
                discount={modalOrder.discount}
                deliveryFee={modalOrder.deliveryFee}
                paymentMethod={modalOrder.paymentMethod}
                onClick={() => setOpenModal(false)}
                onCloseModal={() => setOpenModal(false)}
              />
            )}

            <InvoiceContainer>
              <FilterContainer>
                <Filter />
              </FilterContainer>

              <Divider />

              <Table>
                <tr>
                  <TableHeader width={190}>Nº Pedido</TableHeader>
                  <TableHeader width={180}>Data do pedido</TableHeader>
                  <TableHeader width={225}>Forma de Pagamento</TableHeader>
                  <TableHeader width={400}>Documentos</TableHeader>
                </tr>

                <tbody>
                  {orders?.map((order: any) => {
                    return order.billingInfo && (
                      <TableRow key={order.id}>
                        <TableData onClick={() => handleModal(order.id)}>
                          {order.code}
                        </TableData>
                        <TableData>{getNormalizedDate(order.date)}</TableData>
                        <TableData>{order.paymentMethod}</TableData>
                        <TableData>
                          <Tag
                            text='NF-e'
                            link={order.billingInfo?.danfe?.url}
                            hasAlert={false}
                            order={order.code}
                          />
                          <Tag
                            text={`${order.billingInfo?.boletos?.length} ${order.billingInfo.boletos.length === 1 ? 'Boleto' : 'Boletos'}`}
                            link={handleFiles(order.billingInfo?.boletos)}
                            hasAlert={false}
                            order={order.code}
                          />
                        </TableData>
                      </TableRow>
                    )
                  })}
                </tbody>
              </Table>
            </InvoiceContainer>
          </>
        )
    }
  }

  return (
    <>
      <NavContainer>
        {FINNANCIAL_TABS.map(({ type, name }) => {
          return (
            <Tab onClick={() => setActiveTab(type)} key={type} style={activeTab === type ? { fontWeight: 600, color: '#6460AA', background: '#fff' } : {}}>{name}</Tab>
          )
        })}
      </NavContainer>

      {renderActiveTab()}
    </>
  )
}
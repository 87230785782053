/* eslint-disable @typescript-eslint/no-explicit-any */
import { 
  Container, 
  EmailContent, 
  EmailContentBox,
  StyledLink, 
  Title,
  StyledHiArrowRight,
  NavContainer,
  Tab,
  FilterContainer,
  ButtonWrapper,
  StyledPricingLink,
} from './styles';
import { capitalize, formatCNPJ, formatCPF,  } from 'utils';
import useAuth from 'hooks/useAuth';
import { useState } from 'react';
import { OrderDetail } from 'types/OrderDetail';
import { FINNANCIAL_TABS } from 'pages/Customer/utils/tabs';
import { Modal } from 'components/Modal';
import { Filter } from 'components/Filter';
import { BankSlip } from 'components/BankSlip';
import { getPricingLink } from 'pages/Customer/utils/getPricingLink';
import PDFMerger from 'pdf-merger-js';
import { SpacedRow } from 'components/CustomersList/styles';
import { CopiedToClipboard } from 'components/CopiedToClipboard';
import { StyledHiOutlineDocumentDuplicate } from 'components/CustomerHistoryCard/styles';
import copy from 'copy-to-clipboard';

export const Finnancial = ({ name, cpf, cnpj, isMobile, orders, clientCategory }: { name: string, cpf?: string, cnpj?: string, isMobile: boolean, orders: any, clientCategory: string }) => {
  const [activeTab, setActiveTab] = useState('invoice');
  const [openModal, setOpenModal] = useState(false);
  const [modalOrder, setModalOrder] = useState({} as OrderDetail)
  const [hasCopied, setHasCopied] = useState(false);

  const { auth } = useAuth();

  const fullMessage = `Olá!

%0D%0A

Solicito análise de crédito para o cliente abaixo:

%0D%0A
%0D%0A

Cliente: ${capitalize(name.toLowerCase())}  

%0D%0A

Documento: ${cpf ? 'CPF' : 'CNPJ'} ${cpf ? formatCPF(cpf) : cnpj ? formatCNPJ(cnpj) : null}  

%0D%0A

Valor total de R$ (Informe o valor) 

%0D%0A

Condições de pagamento: (Informe a condição de pagamento)

%0D%0A
%0D%0A

Atenciosamente,

%0D%0A
%0D%0A

${auth?.name}

%0D%0A
%0D%0A

*Solicitação enviada a partir do MedSearch.
`

const copyFullMessage = `Olá!

Solicito análise de crédito para o cliente abaixo:

Cliente: ${capitalize(name.toLowerCase())}  

Documento: ${cpf ? 'CPF' : 'CNPJ'} ${cpf ? formatCPF(cpf) : cnpj ? formatCNPJ(cnpj) : null}  

Valor total de R$ (Informe o valor) 

Condições de pagamento: (Informe a condição de pagamento)

Atenciosamente,

${auth?.name}

*Solicitação enviada a partir do MedSearch.
`

  const handleModal = (id: string) => {
    const order = orders.find((order: OrderDetail) => order.id === id)

    setModalOrder(order)
    setOpenModal(true)
  }

  const checkLink = async (e: any) => {
    e.preventDefault()

    const link = getPricingLink(clientCategory);

    if (link.length === 0) {
      return null
    }

    const merger = new PDFMerger();

    if (Array.isArray(link)) {

      for (const file of link) {
        await merger.add(file);
      }

      await merger.save('tabelas-de-preço');

      return null
    }

    await merger.add(link);
    await merger.save('tabelas-de-preço');
    return null
  }

  const handleCopyToClipboard = () => {
    copy(copyFullMessage as string);
    setHasCopied(true);

    setTimeout(() => {
      setHasCopied(false);
    }, 2000);
  }

  const renderActiveTab = () => {
    switch(activeTab) {
      case 'analytics': 
        return (
          <Container>
            <Title>Análise de crédito</Title>

            <EmailContentBox>
              <SpacedRow>
                <EmailContent>Olá!</EmailContent>

                {!hasCopied ? (
                  <StyledHiOutlineDocumentDuplicate 
                    size={24} 
                    color='#6D6E71' 
                    onClick={handleCopyToClipboard}
                  />
                ) : (
                  <CopiedToClipboard />
                )}
              </SpacedRow>

              <EmailContent> Solicito análise de crédito para o cliente abaixo:</EmailContent>

              <EmailContent style={{ marginTop: '18px'}}>Cliente: {capitalize(name.toLowerCase())}</EmailContent>

              <EmailContent>Documento: {cpf ? 'CPF' : 'CNPJ'} {cpf ? formatCPF(cpf) : cnpj ? formatCNPJ(cnpj) : null}</EmailContent>

              <EmailContent>Valor total de R$ (Informe o valor)</EmailContent>

              <EmailContent>Condições de pagamento: (Informe a condição de pagamento)</EmailContent>

              <EmailContent style={{ marginTop: '18px'}}>*Solicitação enviada a partir do MedSearch.</EmailContent>
            </EmailContentBox>

            <ButtonWrapper style={{ width: isMobile ? '100%' : '344px', alignSelf: isMobile ? '' : 'flex-end' }}>
              {clientCategory ? (
                <StyledPricingLink onClick={(e:any) => checkLink(e)}>Tabela de preços</StyledPricingLink>
                ) : (
                  <div />
                )
              }

              <StyledLink href={`mailto:credito@medbeauty.com.br?subject=Análise de crédito&body=${fullMessage}`} target='_blank' rel="noreferrer">Abrir e-mail <StyledHiArrowRight size={16} /></StyledLink>
            </ButtonWrapper>
          </Container>
        )

      case 'invoice':
        return (
          <>
            {openModal && (
              <Modal 
                code={modalOrder.code}
                items={modalOrder.items}
                saleType={modalOrder.saleType}
                shippingAddress={modalOrder.shippingAddress}
                discount={modalOrder.discount}
                deliveryFee={modalOrder.deliveryFee}
                paymentMethod={modalOrder.paymentMethod}
                onClick={() => setOpenModal(false)}
                onCloseModal={() => setOpenModal(false)}
              />
            )}

            <FilterContainer>
              <Filter />
            </FilterContainer>

            {orders.map((order: any) => {
              return order.billingInfo &&(
                <BankSlip 
                  key={order.id}
                  order={order}
                  onClick={(e) => handleModal(e)}
                />
              )
            })}
          </>
        )

      default:
        return null;
    }
  }

  return (
    <>
      <NavContainer>
        {FINNANCIAL_TABS.map(({ type, name }) => {
          return (
            <Tab onClick={() => setActiveTab(type)} key={type}  style={activeTab === type ? { fontWeight: 600, color: '#fff', background: '#6460AA', whiteSpace: 'nowrap' } : { whiteSpace: 'nowrap' }}>{name}</Tab>
          )
        })}
      </NavContainer>
      
      {renderActiveTab()}
    </>
  )
}
import React, { InputHTMLAttributes, useState, KeyboardEvent } from 'react';

import { IconBaseProps } from 'react-icons/lib';

import {Container, TextInput} from './styles'

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  icon?: React.ComponentType<IconBaseProps>
  left?: boolean
  right?: boolean
  onClick?: () => void
}

export const Input = ({ icon: Icon, left, right, onClick, ...rest}: InputProps) => {
  const [isFocused, setIsFocused] = useState(false);

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      if (onClick) {
        onClick();
      }
    }
  };

  return (
    <Container 
      style={
        isFocused ? { boxShadow: '0px 0px 6px 1px rgba(0,0,0,0.3)' } : {}
      }
    >
      {left && Icon && (
        <Icon 
          size={20} 
          color="#6D6E71" 
          onClick={onClick} 
          style={{ cursor: 'pointer' }}
        />
      )}

      <TextInput 
        {...rest} 
        onFocus={() => setIsFocused(true)} 
        onBlur={() => setIsFocused(false)}
        onKeyDown={handleKeyDown} // Adiciona o evento onKeyDown
      />

      {right && Icon && (
        <Icon 
          size={20} 
          color="#6460AA" 
          onClick={onClick} 
          style={{ cursor: 'pointer' }}
        />
      )}
    </Container>
  )
}

import styled from 'styled-components';
import Avatar from 'react-avatar';
import { Link } from 'react-router-dom';
import { HiLockClosed } from 'react-icons/hi';

export const GoBackContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between; 
  background: var(--highlightColor);
  color: var(--highlightText);
  padding: 16px 40px;
  padding-top: 32px;

  /* Tablet */
  @media (min-width:768px) {
    background: var(--background);
    color: var(--secondaryTextColor);
    padding: 35px 0;
    margin-top: 220px;
  }
`;

export const LinkWrapper = styled(Link)`
  /* Tablet */
  @media (min-width:768px) {
    display: flex;
    margin-left: 18px;
    margin-bottom: 28px;
  }
`;

export const GoBackLink = styled.span`
  font-family: 'Roboto', sans-serif;
  font-weight: 600;
  text-decoration: underline;

  /* Tablet */
  @media (min-width:768px) {
    background: var(--background);
    color: var(--highlightColor);
    margin-left: 16px;
  }
`;

export const GoBackText = styled.span`
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
`;

export const Empty = styled.div`
  width: 20px;
`;

export const Container = styled.div`
  padding: 12px 16px;
  padding-bottom: 0;
  background: var(--background);
  display: flex;
  flex-direction: column;

  /* Tablet */
  @media (min-width:768px) {
    padding: 32px 0;
    background: var(--detailsBackground);
    margin-top: 334px;
  }
`;

export const CustomerHeader = styled.div`
  display: flex;
  padding: 0 4px;
  
  /* Tablet */
  @media (min-width:768px) {
    margin-bottom: 32px;
    border: 1px solid #F5F5F5;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    padding: 16px 32px;
    justify-content: center;
    align-items: center;
    background-color: var(--background);
  }
`;

export const CustomerHeaderInfo = styled.div`
  width: 100%;
  margin-right: 10px;

  /* Tablet */
  @media (min-width:768px) {
    margin-left: 24px;
    display: flex;
  }
`;

export const CustomerName = styled.h1`
  color: var(--secondaryTextColor);
  font-size: 16px;
  font-weight: 600;
  line-height: 150%;
  margin-bottom: 12px;

  /* Tablet */
  @media (min-width:768px) {
    font-size: 20px;
    margin-bottom: 12px;
  }
`;

export const CustomerData = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 12px 0;
`;

export const CustomerHeaderType = styled.span`
  color: var(--primaryTextColor);
  font-size: 14px;
  display: block;
  margin: 8px 0;
  font-family: 'Roboto', sans-serif;
  height: 21px;
  line-height: 150%;

  /* Tablet */
  @media (min-width:768px) {
    margin-bottom: 8px;
  }
`;

export const CustomerHeaderText = styled.span`
  color: var(--primaryTextColor);
  font-size: 16px;
  font-family: 'Poppins', sans-serif;
  margin-bottom: 8px;
  font-weight: 600;
`;

export const CashBeautyContainer = styled.div`
  width: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-self: flex-start;

  /* Tablet */
  @media (min-width:768px) {
    margin-top: 8px;
  }
`;  

export const Title = styled.h1`
  font-weight: 600;
  font-size: 16px;
  color: var(--primaryTextColor);
  line-height: 150%;
  margin-bottom: 8px;

  /* Tablet */
  @media (min-width:768px) {
    font-size: 20px;
  }
`;

export const SearchContainer = styled.div`
  position: fixed;
  top: 92px;
  background: var(--searchBackground);
  width: calc(100% - 60px);
  padding: 28px 30px;

  /* Tablet */
  @media (min-width:768px) {
    padding-top: 42px;
    padding-bottom: 0;
    background: var(--background);
  }
`;

export const SearchContent = styled.div`
  /* Tablet */
  @media (min-width:768px) {
   margin-bottom: 40px;
  }
`;

export const StyledAvatar = styled(Avatar)`
  font-size: 16px; 
  font-weight: 600;
`;

export const DropdownContainer = styled.div`
  width: 760px;
  max-height: 200px;
  background-color: var(--background);
  box-shadow: 0px 4px 12px rgba(146, 140, 151, 0.25);
  border-radius: 4px;
  position: absolute;
  top: 150px;
`;

export const CustomersList = styled.ul`
  display: flex;
  flex-direction: column;
`;

export const CustomerItem = styled.li`
  padding: 13px 16px;
  color: var(--primaryTextColor);
  cursor: pointer;

  &:hover {
    background-color: var(--searchBackground);
  }
`;

export const BlockedCustomerItem = styled.li`
  padding: 13px 16px;
  color: var(--tertiaryTextColor);
  cursor: not-allowed;
  background-color: var(--dividerColor);
`;

export const CustomerInfo = styled.span`
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
`;

export const BlockedCustomerInfo = styled.span`
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-right: 77px;
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 24px;
`;

export const CustomDivider = styled.div`
  margin-left: -20px;
  margin-right: -16px;
  height: 1px;
  background: var(--dividerColor);
`;

export const Divider = styled.div`
  margin-left: -20px;
  margin-right: -30px;
  height: 1px;
  background: var(--dividerColor);

  /* Tablet */
  @media (min-width:768px) {
    margin-left: 0;
    margin-right: 0;
  }
`;

export const TabsContainer = styled.div`
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;

  /* Tablet */
  @media (min-width:768px) {
   height: 60px;
   justify-content: flex-start;
   margin-left: -30px;
   margin-right: -30px;
   border-bottom: 1px solid var(--dividerColor);
  }
`;

export const Tab = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-right: 24px;
  cursor: pointer;

  &:last-child {
    margin-right: 0;
  }

  /* Tablet */
  @media (min-width:768px) {
    width: 100px;
    margin-right: 32px;
  }
`;

export const TabTitle = styled.span`
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: var(--secondaryTextColor);
`;

export const TabSelected = styled.div`
  height: 2px;
  background: var(--highlightColor);
  width: 100%;
`;

export const ContainerBackground = styled.div`
  background-color: var(--detailsBackground);
  margin: 0 -16px;

  /* Tablet */
  @media (min-width:768px) {
    margin: 0;
  }
`;

export const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const InfoText = styled.div`
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  line-height: 150%;
  color: var(--primaryTextColor);
`;

export const DisabledInfoText = styled(InfoText)`
  color: var(--tertiaryTextColor);
`;

export const TooltipContainer = styled.div`
  visibility: hidden;
  position: absolute;
  color: var(--primaryTextColor); 
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  line-height: 150%;

  &:before {
    content: "";
    width: 0;
    height: 0;
    right: 10px;
    top: 62px;
    position: absolute;
    border: 10px solid transparent;
    transform: rotate(315deg);
    transition: border 0.4s ease-in-out;
  }

  /* Tablet */
  @media (min-width:768px) {
    &:before {
      content: "";
      width: 0;
      height: 0;
      right: 23px;
      top: 62px;
      position: absolute;
      border: 10px solid transparent;
      transform: rotate(315deg);
      transition: border 0.4s ease-in-out;
    }  
  }
`;

export const StyledHiLockClosed = styled(HiLockClosed)`
  cursor: pointer;
`;

export const TooltipWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: relative;

  & ${StyledHiLockClosed}:hover + ${TooltipContainer} {
    visibility: visible;
    background: var(--searchBackground);
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.1);
    padding: 16px;
    width: 150px;
    border-radius: 8px;
    margin-top: -89px;
    margin-right: -10px;

    /* Tablet */
    @media (min-width:768px) {
      margin-right: -20px;
    }
    
    &:before {
      border-color: transparent transparent var(--searchBackground) var(--searchBackground);
    }
  }
`;

export const TooltipText = styled.span``;

export const HighlightedText = styled.span`
  font-weight: bold;
`;
import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  border: 1px solid rgba(17, 17, 19, 0.2);
  border-radius: 4px;
  background: var(--background);
`;

export const TextInput = styled.input`
  width: 100%;
  background: var(--background);
  height: 30px;
  border: none;
  padding: 0 10px;
  outline: none;

  &::placeholder {
    color: #B9B9BB;
    font-size: 16px;
  }
`;

import { Button } from 'components/Button';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 73px;
  height: calc(100vh - 73px);
  background: #F5F5F5;

  /* Tablet & Mobile */
  @media (max-width:1024px) {
    align-items: center;
  }

  /* Desktop */
  @media (min-width:1025px) {
    margin-top: 110px;
    height: calc(100vh - 110px);
    width: calc(100% - 200px);
    margin-left: 200px;
    flex-direction: row;
    min-height: 0; /* Adicione esta linha */
  }
`;

export const WrapperTitleToggle = styled.div`
  width: 100%;
  padding: 20px 0;

  /* Tablet & Mobile */
  @media (max-width:1024px) {
    background: white;
    position: fixed;
    z-index: 10;
  }

`;

export const BackgroundContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  max-height: calc(100% - 60px);
  background: white;
  overflow-y: auto; 

  /* Desktop */
  @media (min-width:1025px) {
    background-color: var(--searchBackground);
  }
`;

export const Divider = styled.div`
  width: 95%;
  border: 1px solid var(--dividerColor);
  margin-bottom: 30px;
`;

export const VersionContainer = styled.div<{ isCheckInPage?: boolean }>`
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 15px;
  
  /* Desktop */
  @media (min-width:1025px) {
    width: calc(100% - 200px);
  }
`;

export const Version = styled.span`
  color: var(--primaryTextColor);
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  text-align: center;
`

export const Content = styled.div`
  width: 95%;

  /* Desktop */
  @media (min-width:1025px) {
    background-color: var(--searchBackground);
    width: 90%;
  }
`;

export const TitleNotification = styled.span`
  color: #6D6E71;
  font-size: 16px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;

  /* Desktop */
  @media (min-width:1025px) {
    color: #2F3132;
  }
`;

export const TogglerContainer = styled.div`
  display: flex;
  margin-top: 20px;
  
  /* Desktop */
  @media (min-width:1025px) {
    align-items: center;
    justify-content: center;
    margin-left: 40px;
  }
`;

export const ToggleLabel = styled.label`
  font-weight: 400;
  font-size: 14px;
  color: var(--primaryTextColor);
  margin-left: 14px;
  cursor: pointer;
`;

export const EmptySearch = styled.div`
  height: calc(100vh - 300px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  /* Desktop */
  @media (min-width:1024px) {
   height: calc(100vh - 372px);
  }
`;

export const EmptySearchText = styled.div`
  border-collapse: collapse;
  margin: 100px 40px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 150%;
  color: #6D6E71;
  text-align: center;
`;

export const StyledButton = styled(Button)`
  width: auto;
  padding: 5px 16px;
`;

export const EmptyListFiltered = styled.div`
  height: calc(100vh - 300px);
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 150%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #6D6E71;
`;

export const NotificationWrapper = styled.div`
  /* Desktop */
  @media (min-width:1024px) {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
`;

export const NotificationListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: fit-content;

  /* Tablet & Mobile */
  @media (max-width:1024px) {
    margin-top: 120px;
  }

  /* Desktop */
  @media (min-width: 1024px) {
    padding: 10px;
    background-color: var(--background);
    max-height: calc(100vh - 300px);
    overflow-y: auto;
    overflow-x: hidden;

    &:first-child {
      width: 55%;
    }

    &:nth-child(2) {
      width: 38%;
    }
  }
`;

export const DateNotificationTitle = styled.span`
  color: #6D6E71;
  font-size: 16px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  margin-bottom: 16px;
`;

export const TypeListTitle = styled.span`
  color: #6D6E71;
  font-size: 16px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  margin-bottom: 30px;
`;

export const ContainerLogo = styled.section`
  padding: 16px 0px;
  background: var(--background);
  width: calc(100% - 200px);
  margin-left: 200px;
  position: fixed;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const LogoMedSearch = styled.img`
  width: 194px;
  height: 72px;
`;
import { useEffect, useState } from 'react';
import { formatCNPJ, formatCPF, truncate } from 'utils';
import {
  BlockedCustomer,
  CardContainer,
  Clickable,
  CustomerDocument,
  CustomerInfo,
  CustomerName,
  HeaderWrapper,
  Label,
  SpacedRow,
  StyledHiOutlineSwitchVertical,
  Table,
  TableBody,
  TableData,
  TableHead,
  TableHeader,
  TableRow,
  ValueText
} from './styles';
import { CheckInModal } from 'components/CheckInModal';
import { NotesModal } from 'components/NotesModal';
import { CustomerReturn } from 'types/CustomersContextType';
import { Tooltip } from 'components/Tooltip';
import { useWindowDimensions } from 'hooks/useWindowDimensions';
import useUsersTypeAccess from 'hooks/useUsersTypeAccess';
import { Empty } from 'components/Modal/styles';

interface CustomersListProps {
  customers: CustomerReturn[];
  handleOrder: (str: string) => void;
  order: string;
}

export function CustomersList({ customers, handleOrder, order }: CustomersListProps) {
  const [openCheckInModal, setOpenCheckInModal] = useState(false);
  const [openNoteModal, setOpenNoteModal] = useState(false);
  const [customerName, setCustomerName] = useState('');
  const [customerDocument, setCustomerDocument] = useState('');

  const { width } = useWindowDimensions();
  const { isAdmin, isStandard, isMyManagement, getHierarchy } = useUsersTypeAccess();

  useEffect(() => {
    getHierarchy();
  }, []);

  const shouldRenderClickableItem = (customer: CustomerReturn) => {
    return customer.isMyClient || isAdmin() || isMyManagement(customer.clientConsultantEmail) || isStandard();
  };

  const isDesktop = width > 1025;

  const renderTable = () => {
    return (
      <Table>
        <TableHead>
          <TableRow>
            <TableData isLarge isBoundaryColumn isHeader>
              <HeaderWrapper>
                <TableHeader active={order === 'cliente'}>Cliente</TableHeader>

                <StyledHiOutlineSwitchVertical size={18} color='#6460AA' onClick={() => handleOrder('cliente')} />
              </HeaderWrapper>
            </TableData>

            <TableData isHeader />

            <TableData isHeader>
              <HeaderWrapper>
                <TableHeader active={order === 'compra'}>Última compra</TableHeader>

                <StyledHiOutlineSwitchVertical size={18} color='#6460AA' onClick={() => handleOrder('compra')} />
              </HeaderWrapper>
            </TableData>

            <TableData isHeader>
              <HeaderWrapper>
                <TableHeader>Check-in</TableHeader>
              </HeaderWrapper>
            </TableData>

            <TableData isHeader>
              <HeaderWrapper>
                <TableHeader active={order === 'check-in'}>Último check-in</TableHeader>

                <StyledHiOutlineSwitchVertical size={18} color='#6460AA' onClick={() => handleOrder('check-in')} />
              </HeaderWrapper>
            </TableData>

            <TableData isHeader isBoundaryColumn isSmall>
              <HeaderWrapper>
                <TableHeader>Anotação</TableHeader>
              </HeaderWrapper>
            </TableData>
          </TableRow>
        </TableHead>

        <TableBody>
          {customers.map(customer => {
            return (
              <TableRow key={Math.random()}>
                <TableData
                  isLarge
                  isBoundaryColumn
                  isMyClient={((!!customer?.cpf || !!customer?.cnpj)) || shouldRenderClickableItem(customer)}
                >
                  <CustomerInfo>
                    {(shouldRenderClickableItem(customer)) ? (
                      <Clickable
                        to={`/customer/${customer?.cpf ? customer?.cpf : customer?.cnpj ? customer?.cnpj : ''}`}
                      >
                        {customer.name}
                      </Clickable>
                    ) : (
                      <BlockedCustomer>{customer.name}</BlockedCustomer>
                    )}

                  </CustomerInfo>
                </TableData>

                <TableData isMyClient={shouldRenderClickableItem(customer)}>
                  <CustomerDocument isMyClient={shouldRenderClickableItem(customer)}>
                    {customer?.cpf ? formatCPF(customer?.cpf) : customer?.cnpj ? formatCNPJ(customer?.cnpj) : ''}
                  </CustomerDocument>
                </TableData>

                <TableData isMyClient={shouldRenderClickableItem(customer)}>
                  {customer.lastShopping !== null
                    ? customer.lastShopping === 0
                      ? 'Hoje'
                      : `Há ${customer.lastShopping} ${customer.lastShopping === 1 ? 'dia' : 'dias'}`
                    : 'Não consta'}
                </TableData>

                <TableData
                  isMyClient={shouldRenderClickableItem(customer)}
                  isClickable onClick={() => {
                    setCustomerName(customer.name)
                    setCustomerDocument(customer?.cpf ? customer?.cpf : customer?.cnpj ? customer?.cnpj : '')
                    setOpenCheckInModal(true)
                  }
                  }>Fazer check-in</TableData>

                <TableData isMyClient={shouldRenderClickableItem(customer)}>
                  {customer.lastContact !== null
                    ? customer.lastContact === 0
                      ? 'Hoje'
                      : `Há ${customer.lastContact} ${customer.lastContact === 1 ? 'dia' : 'dias'}`
                    : 'Não consta'}
                </TableData>

                <TableData
                  isClickable
                  isMyClient={shouldRenderClickableItem(customer)}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    height: '48px'
                  }}
                  isSmall
                  isBoundaryColumn
                >
                  <span
                    onClick={() => {
                      setCustomerName(customer.name)
                      setCustomerDocument(customer?.cpf ? customer?.cpf : customer?.cnpj ? customer?.cnpj : '')
                      setOpenNoteModal(true)
                    }}
                    style={{
                      marginRight: '10px'
                    }}
                  >Adicionar</span>
                  <Tooltip
                    text='Carteira do consultor(a) '
                    highlightedText={customer.clientConsultant}
                    withoutMargin
                  />
                </TableData>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    )
  }

  const renderMobile = () => {
    return customers.map(customer => {
      return (
        <CardContainer key={customer?.cpf ? customer?.cpf : customer?.cnpj ? customer?.cnpj : Math.random()}>
          <SpacedRow>
            {shouldRenderClickableItem(customer) ? (
              <CustomerName isMyClient to={`/customer/${customer?.cpf ? customer?.cpf : customer?.cnpj ? customer?.cnpj : ''}`}>{customer.name}</ CustomerName>
            ) : (
              <BlockedCustomer>{customer.name}</BlockedCustomer>
            )}

          <Tooltip
            text='Carteira do consultor(a) '
            highlightedText={customer.clientConsultant}
            isCheckInTooltip={true}
          />

          </SpacedRow>

          <SpacedRow>
            <ValueText isMyClient={shouldRenderClickableItem(customer)}>
              {customer?.cpf ? 'CPF ' : customer?.cnpj ? 'CNPJ ' : ''}
              {customer?.cpf ? formatCPF(customer?.cpf) : customer?.cnpj ? formatCNPJ(customer?.cnpj) : ''}
            </ValueText>
          </SpacedRow>

          <SpacedRow>
            <Label isMyClient={shouldRenderClickableItem(customer)}>Última compra:</Label>

            <ValueText isMyClient={shouldRenderClickableItem(customer)}>
              {customer.lastShopping !== null
                ? customer.lastShopping === 0
                  ? 'Hoje'
                  : `Há ${customer.lastShopping} ${customer.lastShopping === 1 ? 'dia' : 'dias'}`
                : 'Não consta'}
            </ValueText>
          </SpacedRow>

          <SpacedRow>
            <Label isMyClient={shouldRenderClickableItem(customer)}>Check-in:</Label>

            <ValueText
              isMyClient={shouldRenderClickableItem(customer)}
              isClickable={shouldRenderClickableItem(customer)}
              onClick={() => {
                setCustomerName(customer.name)
                setCustomerDocument(customer?.cpf ? customer?.cpf : customer?.cnpj ? customer?.cnpj : '')
                setOpenCheckInModal(true)
              }
              }
            >Fazer check-in</ValueText>
          </SpacedRow>

          <SpacedRow>
            <Label isMyClient={shouldRenderClickableItem(customer)}>Último contato:</Label>

            <ValueText isMyClient={shouldRenderClickableItem(customer)}>
              {customer.lastContact !== null
                ? customer.lastContact === 0
                  ? 'Hoje'
                  : `Há ${customer.lastContact} ${customer.lastContact === 1 ? 'dia' : 'dias'}`
                : 'Não consta'}
            </ValueText>
          </SpacedRow>

          <SpacedRow>
            <Label isMyClient={shouldRenderClickableItem(customer)}>Anotação:</Label>

            <ValueText
              isClickable={shouldRenderClickableItem(customer)}
              isMyClient={shouldRenderClickableItem(customer)}
              onClick={() => {
                setCustomerName(customer.name)
                setCustomerDocument(customer?.cpf ? customer?.cpf : customer?.cnpj ? customer?.cnpj : '')
                setOpenNoteModal(true)
              }}
            >Adicionar</ValueText>
          </SpacedRow>
        </CardContainer>
      )
    })
  }

  return (
    <>
      {openCheckInModal && (
        <CheckInModal
          onClose={() => setOpenCheckInModal(false)}
          customerDocument={customerDocument}
        />
      )}
      {openNoteModal && <NotesModal customerName={customerName} customerDocument={customerDocument} onCloseModal={() => setOpenNoteModal(false)} />}

      {isDesktop ? renderTable() : renderMobile()}
    </>
  )
}

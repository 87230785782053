import {
  Container,
  ItemDescription,
  PositionContainer,
  Position,
  ItemName,
  Quantity,
} from './styles';

interface MostShopppedItemsCardProps {
  position: number;
  description: string;
  quantity: number;
}

export const MostShoppedItemsCard = ({ position, description, quantity }: MostShopppedItemsCardProps) => {
  const getBackgroundColor = (position: number) => {
    switch (position) {
      case 1:
        return '#4A4785';
      case 2: 
        return '#6460AA';
      case 3: 
        return '#A29FCC';
      default:
        return '#B4B4B4';
    }
  }
  
  return (
    <Container>
      <ItemDescription>
        <PositionContainer style={{ background: getBackgroundColor(position)}}>
          <Position>
            {position}º
          </Position>
        </PositionContainer>

        <ItemName>{description}</ItemName>
      </ItemDescription>

      <Quantity>{quantity} Un.</Quantity>
    </Container>
  )
}
/* eslint-disable no-prototype-builtins */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { HighlightedElement } from 'components/HighlightedElement';
import { useEffect, useState } from 'react';
import { HiCheckCircle, HiX } from 'react-icons/hi';
import { ClipLoader } from 'react-spinners';
import { 
  Clickable, 
  Container, 
  Dimmer, 
  Empty, 
  ModalHeader, 
  Column,
  Subtitle, 
  Title,
  Divider,
  ProfileBody,
  Handler,
  StyledButton,
  ChipsContainer,
} from './styles';
import { Chip } from 'components/Chip';
import { api } from 'config/api';
import useNotes from 'hooks/useNotes';
import { Profile } from 'types/CustomerDetails';

interface ProfileModalProps {
  email: string;
  onCloseModal: () => void;
  currentProfile?: Profile;
}

export const ProfileModal = ({ email, onCloseModal, currentProfile }: ProfileModalProps) => {
  const [newProfile, setNewProfile] = useState<Profile>({} as Profile)
  const [interests, setInterests] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const { setProfile, username } = useNotes();

  useEffect(() => {
    if (currentProfile && currentProfile.customer === email) {
      setNewProfile(currentProfile);
      setInterests(currentProfile.interests);
    }
  }, [currentProfile]);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const close = (e: any) => {
      if(e.keyCode === 27){
        onCloseModal && onCloseModal()
      }
    }

    window.addEventListener('keydown', close)

    return () => window.removeEventListener('keydown', close)
  },[newProfile]);

  const handleProfile = (message: string) => {
    setNewProfile({
      email,
      customer: email,
      profile: message,
      interests,
      createAt: new Date(),
      madeBy: username,
    });
    
    if(message.length > 280){
      setNewProfile({
        email,
        customer: email,
        profile: message.slice(0, 280),
        interests,
        createAt: new Date(),
        madeBy: username,
      });
    }
  }

  const handleSaveProfile = async () => {
    try {
      setLoading(true);

      const { data } = await api.post('/customer/profile', newProfile);

      setProfile(data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);

      setSuccess(true);

      setTimeout(() => {
        onCloseModal && onCloseModal();
      }, 1000);
    }
  }

  useEffect(() => {
    if(success){
      setTimeout(() => {
        setSuccess(false);
      }, 2000)
    }
  }, [success]);

  const INTERESTS = [
    'Cinema',
    'HandsON',
    'Treinamento',
    'Pet',
    'Moda',
    'Música',
    'K-Club',
    'Gastronomia',
    'Saúde e Bem-estar',
    'Viagem',
    'Tecnologia',
    'Eventos',
    'Esportes',
    'Cultura',
    'Filhos'
  ]

  const handleSelectInterest = (interest: string) => {
    if (interests.includes(interest)) {
      setInterests(interests.filter(i => i !== interest));

      setNewProfile({
        email,
        customer: email,
        profile: newProfile.profile ? newProfile.profile : '',
        interests: interests.filter(i => i !== interest),
        createAt: new Date(),
        madeBy: username,
      });
    } else {
      setInterests([...interests, interest]);

      setNewProfile({
        email,
        customer: email,
        profile: newProfile.profile ? newProfile.profile : '',
        interests: [...interests, interest],
        createAt: new Date(),
        madeBy: username,
      });
    }
  }

  const checkEmpty = () => {
    if (newProfile) {
      if (newProfile.profile && newProfile.profile.length > 0) {
        return false;
      }

      if (newProfile.interests && newProfile.interests.length > 0) {
        return false;
      }
    }

    return true;
  }

  return (
    <Dimmer onClick={() => onCloseModal && onCloseModal()}>
      <Container onClick={(e:React.MouseEvent) => e.stopPropagation()}>
        <ModalHeader>
          <Empty />

          <Column>
            <Title>Perfil do cliente</Title>   

            <Subtitle>{newProfile && newProfile?.profile ? newProfile.profile.length : 0}/280 caracteres</Subtitle>   
          </Column>

          <Clickable onClick={() => onCloseModal && onCloseModal()}>
            <HighlightedElement 
              primaryColor='#6460AA'
              icon={HiX}
              r='100'
              g='96'
              b='170'
              a='0.2'
            />
          </Clickable>
        </ModalHeader>

        <Divider />

        <ProfileBody 
          onChange={(e) => !loading ? handleProfile(e.target.value) : null}
          value={newProfile && newProfile?.profile ? newProfile.profile : ''}
          placeholder='Ex.: Profissional jovem que começou a trabalhar com fios há pouco tempo.'
        />

        <Column style={{ marginBottom: '16px', marginTop: '28px' }}>
          <Title>Interesses</Title>   
        </Column>

        <Divider />
        
        <ChipsContainer>
          {INTERESTS.map((interest, index) => {
            return (
              <Chip 
                key={index} 
                text={interest} 
                onClick={() => handleSelectInterest(interest)}
                removeable={interests.includes(interest)}
              />
            )
          })}
        </ChipsContainer>

        <Handler>
          <StyledButton 
            onClick={() => handleSaveProfile()} 
            disabled={checkEmpty()}
          >
            {loading ? (
              <ClipLoader
                color='#fff'
                size={16}
                aria-label="Loading Spinner"
                data-testid="loader"
              />
            ) : success ? (
              <>
                <span style={{ marginRight: '10px' }}>Feito</span> <HiCheckCircle color='#00E13F' size={16}/>
              </>
            ) : 'Salvar' }
          </StyledButton>
        </Handler>
      </Container>
    </Dimmer>
  )
}
/* eslint-disable @typescript-eslint/no-unused-vars */
import { HighlightedElement } from 'components/HighlightedElement';
import { api } from 'config/api';
import { useEffect, useState } from 'react';
import { HiCheckCircle, HiChevronLeft, HiX } from 'react-icons/hi';
import { ClipLoader } from 'react-spinners';
import { NotesModalProps } from 'types/NotesModalProps';
import {
  Clickable,
  Container,
  Dimmer,
  Empty,
  ModalHeader,
  Column,
  Subtitle,
  Title,
  Divider,
  NoteBody,
  Handler,
  StyledButton,
  GoBackContainer,
  GoBackText,
  NotificationButton,
  NotificationContainer,
  NotificationDateWrapper,
  NotificationDateTitle,
  NotificationDateValue,
} from './styles';
import useNotes from 'hooks/useNotes';
import { useWindowDimensions } from 'hooks/useWindowDimensions';
import { HiBell } from 'react-icons/hi2';
import { addDays, format } from 'date-fns';
import DateSingleCalendar from 'components/DateSingleCalendar';
import { pt } from 'date-fns/locale';
import useAuth from 'hooks/useAuth';
import useCustomers from 'hooks/useCustomers';
import { Annotation } from 'types/CustomerDetails';

export const NotesModal = ({ id, onCloseModal, customerDocument, customerName, annotation, dateNotification, onClick }: NotesModalProps) => {
  const [newNote, setNewNote] = useState('');
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [selectedNotifyDay, setSelectedNotifyDay] = useState<Date | undefined>(dateNotification ? dateNotification : addDays(new Date(), 1));
  const [isCalendarOpen, setIsCalendarOpen] = useState(annotation ? true : false);

  const { notification, setNotification } = useNotes();
  const { getCustomerByDocument } = useCustomers();
  const { auth } = useAuth();

  const { width } = useWindowDimensions();
  const isMobile = width < 768;

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const close = async (e: any) => {
      if (e.keyCode === 27) {
        onCloseModal && onCloseModal();
      }
    }

    window.addEventListener('keydown', close)

    return () => window.removeEventListener('keydown', close)
  }, [newNote]);

  const handleNewNote = (message: string) => {
    setNewNote(message);

    if (message.length > 280) {
      setNewNote(message.slice(0, 280));
    }
  }

  const handleSaveNote = async () => {
    try {
      setLoading(true);

      if (id) {
        const body = {
          isNotification: true,
          active: true,
          dateNotification: selectedNotifyDay
        }

        await api.put(`/customer/annotation/${id}`, body);

        if (onClick) {
          const notificationObj = {
            id: id,
            isNotification: true,
            active: true,
            dateNotification: selectedNotifyDay?.toISOString() || '',
            consultantName: auth?.name || '',
            consultantEmail: auth?.username || '',
            annotation: annotation || '',
            document: customerDocument || '',
            createAt: '',
            updatedAt: new Date().toISOString()
          }

          setNotification([...notification, notificationObj]);

          onClick && onClick()
        } else {
          const updatedData = notification.map(item => {
            if (item.id === id) {
              return {
                ...item,
                dateNotification: selectedNotifyDay ? selectedNotifyDay.toISOString() : ''
              };
            }
            return item;
          });

          setNotification(updatedData)
        }

      } else {
        const body = {
          annotation: newNote,
          customerDocument: customerDocument,
          customerName: customerName,
          consultantEmail: auth ? auth.username : '',
          consultantName: auth ? auth.name : '',
          ...(isCalendarOpen && {
            isNotification: true,
            dateNotification: selectedNotifyDay
          })
        };

        await api.post('/customer/annotation', body);

        getCustomerByDocument(customerDocument ? customerDocument : '')

      }

    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);

      setSuccess(true);

      setTimeout(() => {
        onCloseModal && onCloseModal();
      }, 1000);
    }
  }

  useEffect(() => {
    if (success) {
      setTimeout(() => {
        setSuccess(false);
      }, 2000)
    }
  }, [success]);

  return (
    <Dimmer onClick={() => onCloseModal && onCloseModal()}>

      <Container isCalendarOpen={isCalendarOpen} onClick={(e: React.MouseEvent) => e.stopPropagation()}>

        {isMobile && (
          <GoBackContainer >
            <HiChevronLeft size={24} color='#fff' cursor={'pointer'} onClick={() => onCloseModal && onCloseModal()} />
            <GoBackText>Cliente</GoBackText>
            <Empty />
          </GoBackContainer>
        )}

        <ModalHeader>
          {!isMobile && (
            <Empty />
          )}

          <Column>
            <Title>Anotações</Title>

            <Subtitle>{annotation ? annotation.length : newNote.length}/280 caracteres</Subtitle>
          </Column>

          {!isMobile && (
            <Clickable onClick={() => onCloseModal && onCloseModal()}>
              <HighlightedElement
                primaryColor='#6460AA'
                icon={HiX}
                r='100'
                g='96'
                b='170'
                a='0.2'
              />
            </Clickable>
          )}
        </ModalHeader>

        <Divider />

        <NoteBody
          onChange={(e) => !loading ? handleNewNote(e.target.value) : null}
          value={annotation ? annotation : newNote}
          isCreateAnnotation={annotation ? true : false}
        />

        <>
          <NotificationContainer>
            {isCalendarOpen ? (
              <>
                <NotificationDateWrapper>
                  <NotificationDateTitle>
                    Data da notificação
                  </NotificationDateTitle>
                  <NotificationDateValue>
                    {selectedNotifyDay && format(selectedNotifyDay, 'eeee, dd MMM', { locale: pt })}.
                  </NotificationDateValue>
                </NotificationDateWrapper>

                <DateSingleCalendar
                  setSelectedNotifyDay={(e) => setSelectedNotifyDay(e)}
                  selectedNotifyDay={selectedNotifyDay}
                />
              </>
            ) :
              (
                <>
                  <NotificationButton onClick={() => setIsCalendarOpen(true)}>
                    <HiBell color='#6460AA' size={20} />
                    Criar notificação
                  </NotificationButton>
                </>
              )
            }
          </NotificationContainer>
        </>

        <Handler isNotification={true}>
          <StyledButton onClick={() => handleSaveNote()} disabled={(newNote.length === 0 && annotation === undefined) || (isCalendarOpen && !selectedNotifyDay)}>
            {loading ? (
              <ClipLoader
                color='#fff'
                size={16}
                aria-label="Loading Spinner"
                data-testid="loader"
              />
            ) : success ? (
              <>
                <span style={{ marginRight: '10px' }}>Feito</span> <HiCheckCircle color='#00E13F' size={16} />
              </>
            ) : 'Salvar'}
          </StyledButton>
        </Handler>
      </Container>
    </Dimmer >
  )
}

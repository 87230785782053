import { useEffect, useRef } from 'react';
import { ContainerItem, ContentItem, Dimmer, Divider, ItemAnnotation, ItemTitleName } from './styles';
interface Props {
  annotation: string;
  name: string;
  isDateBeforeToday: boolean;
  onClose: () => void;
}

export const NotificationItemModal = ({ annotation, name, isDateBeforeToday, onClose }: Props) => {
  const modalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const closeOnOutsideClick = (e: MouseEvent) => {
      if (modalRef.current && !modalRef.current.contains(e.target as Node)) {
        onClose();
      }
    };

    document.addEventListener('mousedown', closeOnOutsideClick);

    return () => {
      document.removeEventListener('mousedown', closeOnOutsideClick);
    };
  }, [onClose]);

  return (
    <Dimmer>
      <ContainerItem isOutOfDate={isDateBeforeToday} ref={modalRef}>
        <ContentItem>
          <ItemTitleName isOutOfDate={isDateBeforeToday}>{name}</ItemTitleName>

          <Divider />

          <ItemAnnotation isOutOfDate={isDateBeforeToday}>{annotation}</ItemAnnotation>
        </ContentItem>
      </ContainerItem>
    </Dimmer>
  );
};

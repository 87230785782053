import { Button } from 'components/Button';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Dimmer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(17, 24, 39, 0.8);
  z-index: 10000;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Container = styled.div<{ isCalendarOpen?: boolean }>`
  display: flex;
  flex-direction: column;
  background: var(--background);
  width: 100%;
  height: 100vh;

  /* Tablet */
  @media (min-width:768px) {
    border-radius: 8px;
    height: ${({ isCalendarOpen }) => (isCalendarOpen ? '650px' : '400px')};
    width: 500px;
  }

  /* Desktop */
  @media (min-width:1024px) {
    border-radius: 8px;
    height: ${({ isCalendarOpen }) => (isCalendarOpen ? '700px' : '400px')};
    width: 500px;
  }
`;

export const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
  margin-bottom: 12px; 
  margin-top: 20px; 
`;

export const Empty = styled.div`
  width: 20px;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  /* Mobile */
  @media (max-width:767px) {
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }
`;


export const Title = styled.h1`
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  color: var(--primaryTextColor);
  font-size: 16px;
`;

export const Subtitle = styled.span`
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  margin-top: 4px;
  color: var(--primaryTextColor);
`;

export const Clickable = styled.div`
  cursor: pointer;
`;

export const Divider = styled.div`
  border: 1px solid var(--dividerColor);
  margin-bottom: 12px;
  margin-left: 16px;
  margin-right: 16px;
`;

export const NoteBody = styled.textarea<{ isCreateAnnotation?: boolean }>`
  height: 73px;
  margin: 0 16px;
  max-width: 100%;
  color: ${({ isCreateAnnotation }) => (isCreateAnnotation ? '#B9B9BB' : '#2F3132')};
  font-size: 16px;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  line-height: 150%;
  resize: none;
  border: 1px solid var(--dividerColor);
  ${({ isCreateAnnotation }) => isCreateAnnotation && 'pointer-events: none;'}

  &:focus {
    outline: none;
  }

`;

export const Handler = styled.div<{ isNotification?: boolean }>`
  background-color: ${({ isNotification }) => !isNotification ? 'var(--dividerColor)' : 'undefined'} ;
  margin: 0 -16px;
  margin-bottom: -20px;
  height: 76px;
  display: flex;
  justify-content: center;
  align-items: center;
  
  /* Mobile */
  @media (max-width:767px) {
    bottom: 0;
    margin: 0;
    height: auto;
  }

`;

export const StyledButton = styled(Button)`
  width: 163px;
`;

export const GoBackContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between; 
  background: var(--highlightColor);
  color: var(--highlightText);
  padding: 16px 40px;
  padding-top: 32px;
`;

export const StyledLink = styled(Link)`
display: flex;
align-items: center;
justify-content: center;
`;

export const GoBackText = styled.span`
font-family: 'Roboto', sans-serif;
font-weight: 400;
font-size: 16px;
line-height: 150%;
`;

export const NotificationContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 16px 40px 16px;
`;

export const NotificationButton = styled(Button)`
  width: 170px;
  padding: 4px;
  gap: 4px;
  background: #FFFFFF;
  color: #6460AA;
  border: 3px solid #6460AA;
  font-size: 16px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
`;

export const NotificationDateWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 10px 0 16px 0;
`;

export const NotificationDateTitle = styled.span`
  color: #6D6E71;
  font-size: 14px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
`;

export const NotificationDateValue = styled.span`
  color: #6D6E71;
  text-align: right;
  font-size: 16px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
`;
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import MedBeautyL from 'assets/by-medbeauty-large.svg';
import MedBeautyS from 'assets/by-medbeauty-small.svg';
import LoginBannerDesktop from 'assets/login_banner_desktop.png';
import LoginBannerMobile from 'assets/login_banner_mobile.png';
import LoginBannerTablet from 'assets/login_banner_tablet.png';
import MedSearchL from 'assets/medsearch-large.svg';
import MedSearchS from 'assets/medsearch-small.svg';
import Microsoft from 'assets/microsoft.svg';
import { loginRequest } from 'config/authConfig';
import { useWindowDimensions } from 'hooks/useWindowDimensions';
import { Navigate } from 'react-router-dom';
import packageInfo from '../../../package.json';
import {
  Container, 
  ContainerImgs, 
  CoverImg, 
  Info, 
  LoginButton, 
  LoginCard, 
  LoginContainer, 
  LogoContainer, 
  LogoMedBeauty, 
  LogoMedSearch, 
  MicrosoftLog, 
  Title, 
  Version, 
  VersionContainer
} from './styles';

export const Login = () => {
  const { instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const { width } = useWindowDimensions();

  const handleLogin = () => {
    instance.loginRedirect(loginRequest);
  }

  const isMobile = width < 768;
  const isTablet = width >= 768 && width < 1024;

  if (isAuthenticated) {
    return <Navigate to="/" state={{ from: location }} />
  }

  return (
    <Container>
      <ContainerImgs>
        <CoverImg 
          src={
            isMobile 
            ? LoginBannerMobile 
            : isTablet 
              ? LoginBannerTablet 
              : LoginBannerDesktop
          } 
          alt='Cover logo' 
        />

        <LogoContainer>
          <LogoMedSearch src={isMobile ? MedSearchS : MedSearchL} alt="Medbeauty" />
          <LogoMedBeauty src={isMobile ? MedBeautyS : MedBeautyL} alt="Medbeauty" />        
        </LogoContainer>
      </ContainerImgs>

      <LoginContainer>
        <LoginCard>
          <Title>Login</Title>

          <Info>Consultar os dados de seus clientes agora é rápido e fácil.</Info>

          <LoginButton onClick={() => handleLogin()}>Entrar com Microsoft</LoginButton>
          
          <MicrosoftLog src={Microsoft} alt="Microsoft "/>
        </LoginCard>
        
        <VersionContainer>
          <Version>Versão {packageInfo.version}</Version>
        </VersionContainer>
      </LoginContainer>
    </Container>
  )
}
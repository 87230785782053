/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { ChangeEvent, useEffect, useState } from 'react';
import {
  HiChevronLeft,
  HiOutlineX,
  HiOutlineMicrophone,
} from 'react-icons/hi';
import { HighlightedElement } from 'components/HighlightedElement';
import { Link } from 'react-router-dom';
import {
  GoBackContainer,
  LinkWrapper,
  GoBackLink,
  GoBackText,
  Empty,
  Container,
  CustomerHeader,
  CustomerHeaderInfo,
  CustomerName,
  CustomerHeaderType,
  CustomerHeaderText,
  CashBeautyContainer,
  StyledAvatar,
  SearchContainer,
  SearchContent,
  DropdownContainer,
  CustomersList,
  CustomerItem,
  CustomerInfo,
  Column,
  Row,
  Divider,
  CustomDivider,
  TabsContainer,
  Tab,
  TabTitle,
  TabSelected,
  ContainerBackground,
  StyledLink,
  BlockedCustomerItem,
  BlockedCustomerInfo,
  TooltipWrapper,
  StyledHiLockClosed,
  TooltipContainer,
  TooltipText,
  HighlightedText
} from './styles';
import { Header } from 'components/Header';
import { format, parseISO } from 'date-fns';
import { OrderDetail } from 'types/OrderDetail';
import { formatCNPJ, formatCPF, handleName, sanitizeString } from 'utils';
import { Modal } from 'components/Modal';
import { ModalProps } from 'types/ModalProps';
import { Input } from 'components/Input';
import useCustomers from 'hooks/useCustomers';
import { Customer } from 'types/CustomersContextType';
import { api } from 'config/api';
import useAuth from 'hooks/useAuth';
import { MostShoppedItemsModal } from 'components/MostShoppedItemsModal';
import { NotesModal } from 'components/NotesModal';
import { Details } from './Tabs/Details';
import { Notes } from './Tabs/Notes';
import { Finnancial } from './Tabs/Finnancial';
import { ModalType } from '../utils/modalTypes';
import { SpeakerModal } from 'components/SpeakerModal';
import { TABS } from '../utils/tabs';
import useUsersTypeAccess from 'hooks/useUsersTypeAccess';
import { Requests } from './Tabs/Requests';

export const SmallScreenView = ({ name, clientCategory, cpf, cnpj, email, phone, whatsapp, lastShopping, averageTicket, birthdate, address, registerDate, cashback, orders, onClick, isMyClient, mostShoppedItems, currentTab, setCurrentTab, note, noteDeprecated, profile }: any) => {
  const [width, setWidth] = useState<number>(window.innerWidth);
  const [order, setOrder] = useState<ModalProps>({} as ModalProps);
  const [showModal, setShowModal] = useState<ModalType>('none')
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [searchCustomers, setSearchCustomers] = useState<Customer[]>([]);
  const { isAdmin } = useUsersTypeAccess();

  const { auth } = useAuth();
  const { redirectSearch, setRedirectSearch, setLastSearch, setError, setCustomers, setSearch, setPage } = useCustomers();

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    const debouncer = setTimeout(async () => {
      if (redirectSearch) {
        try {
          setLoadingSearch(true);
          
          setPage(1);

          const response = await api.get(`customers?email=${auth ? auth?.username : ''}&page=1&search=${sanitizeString(redirectSearch)}&isMyClientsOnly=${isAdmin()}`);

          setSearchCustomers(response.data.data);

          setCustomers(response.data);
          setSearch(redirectSearch);
          setLastSearch(redirectSearch);
        } catch (error) {
          setError(true);
        } finally {
          setLoadingSearch(false);
        }
      }
    }, 1000);

    return () => clearTimeout(debouncer);
  }, [redirectSearch]);

  useEffect(() => {
    if (['details', 'mostShoppedItems'].includes(showModal)) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [showModal]);

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);

    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);

  const isMobile = width < 768;

  const handleOrderClick = (order: OrderDetail) => {
    setOrder(order);
    setShowModal('details');
  }

  const renderList = () => {
    return loadingSearch
      ? (
        <CustomerItem style={{ cursor: 'not-allowed' }}>
          <CustomerInfo>Procurando...</CustomerInfo>
        </CustomerItem>
      )
      : searchCustomers.length > 0 ? (
        searchCustomers.map((customer: Customer, index) => {
          return index < 3
            ? isMyClient || isAdmin() ? (
              <Link to={`../customer/${customer.cpf ? customer.cpf : customer.cnpj}`} key={index} onClick={() => {
                onClick(customer);
                setRedirectSearch('');
              }}>
                <CustomerItem key={index}>
                  <CustomerInfo>
                    {customer.name} ({customer.cpf ? formatCPF(customer.cpf) : customer.cnpj ? formatCNPJ(customer.cnpj) : ''})
                  </CustomerInfo>
                </CustomerItem>
              </Link>
            ) : (
              <BlockedCustomerItem key={index}>
                <BlockedCustomerInfo>
                  {customer.name} ({customer.cpf ? formatCPF(customer.cpf) : customer.cnpj ? formatCNPJ(customer.cnpj) : ''})

                  <TooltipWrapper>
                    <StyledHiLockClosed size={24} color='#B9B9BB' />

                    <TooltipContainer>
                      <TooltipText>Carteira do consultor(a)
                        <HighlightedText> {customer.clientConsultant}</HighlightedText>
                      </TooltipText>
                    </TooltipContainer>
                  </TooltipWrapper>
                </BlockedCustomerInfo>
              </BlockedCustomerItem>
            ) :
            index === searchCustomers.length - 1 && index >= 3
              ? (
                <Link to='/' onClick={() => setRedirectSearch('')}>
                  <CustomerItem key={index}>
                    <CustomerInfo>Ver todos</CustomerInfo>
                  </CustomerItem>
                </Link>
              ) : null;
        })) : (
        <CustomerItem style={{ cursor: 'not-allowed' }}>
          <CustomerInfo>Nenhum cliente encontrado</CustomerInfo>
        </CustomerItem>
      )
  }

  const renderTab = (tab: string) => {
    switch (tab) {
      case 'details':
        return (
          <Details
            isMobile={isMobile}
            phone={phone}
            whatsapp={whatsapp}
            email={email}
            address={address}
            mostShoppedItems={mostShoppedItems}
            lastShopping={lastShopping}
            averageTicket={averageTicket}
            orders={orders}
            onClickHandleOrderClick={(order: OrderDetail) => handleOrderClick(order)}
            onClickSetShowMostShoppedItemsModal={() => setShowModal('mostShoppedItems')}
          />
        )

      case 'notes':
        return (
          <Notes
            note={note}
            noteDeprecated={noteDeprecated}
            profile={profile}
            email={email}
            onClickSetShowNotesModal={() => setShowModal('notes')}
          />
        )

      case 'requests':
        return (
          <Requests
            name={name}
            cpf={cpf}
            cnpj={cnpj}
            isMobile={isMobile}
            email={email}
            clientCategory={clientCategory}
            address={address}
          />
        )

      case 'finnancial':
        return (
          <Finnancial
            name={name}
            cpf={cpf}
            cnpj={cnpj}
            isMobile={isMobile}
            orders={orders}
            clientCategory={clientCategory}
          />
        )

      default:
        return null
    }
  }

  const modalEnabler = () => {
    switch (showModal) {
      case 'details':
        return (
          <Modal
            code={order.code}
            items={order.items}
            saleType={order.saleType}
            shippingAddress={order.shippingAddress}
            discount={order.discount}
            deliveryFee={order.deliveryFee}
            paymentMethod={order.paymentMethod}
            onClick={() => setShowModal('none')}
            onCloseModal={() => setShowModal('none')}
          />
        )

      case 'mostShoppedItems':
        return (
          <MostShoppedItemsModal
            mostShoppedItems={mostShoppedItems}
            onClick={() => setShowModal('none')}
            onCloseModal={() => setShowModal('none')}
          />
        )

      case 'notes':
        return (
          <NotesModal
            onCloseModal={() => setShowModal('none')}
            customerName={name}
            customerDocument={cpf ? cpf : cnpj ? cnpj : ''}
          />
        )

      // case 'deleteNote':
      //   return (
      //     <DeleteNoteModal
      //       onCloseModal={() => setShowModal('none')}
      //       customer={email}
      //     />
      //   )

      case 'speaker':
        return (
          <SpeakerModal
            onCloseModal={() => setShowModal('none')}
            setSearch={setRedirectSearch}
          />
        )

      default:
        return null
    }
  }

  const Tabs = () => {
    return (
      <TabsContainer>
        {TABS.map(({ type, name }) => {
          return (
            <Tab onClick={() => setCurrentTab(type)} key={type}>
              <Empty />

              <TabTitle style={currentTab === type ? { fontWeight: 600, color: '#6460AA' } : {}}>{name}</TabTitle>

              {currentTab === type ? <TabSelected /> : <Empty />}
            </Tab>
          )
        })}
      </TabsContainer>
    )
  }

  const renderHeaderInfo = () => {
    return isMobile ? (
      <>
        <Row>
          <CustomerName>{name}</CustomerName>

          <CashBeautyContainer>
            <CustomerHeaderText>CashBeauty</CustomerHeaderText>

            <HighlightedElement
              primaryColor='#F3716D'
              r='243'
              g='113'
              b='109'
              a='0.05'
              text={cashback ? cashback.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : 'R$ 0,00'}
              customStyle={{ padding: '4px 12px' }}
            />
          </CashBeautyContainer>
        </Row>

        <Divider />

        <CustomerHeaderType>Tipo <span style={{ color: '#2F3132', marginLeft: '8px' }}>{clientCategory || 'Não informado'}</span></CustomerHeaderType>

        <Divider />

        <CustomerHeaderType>Documento <span style={{ color: '#2F3132', marginLeft: '8px' }}>{cpf ? `CPF ${formatCPF(cpf)}` : cnpj ? `CNPJ ${formatCNPJ(cnpj)}` : ''}</span></CustomerHeaderType>

        <Divider />

        <CustomerHeaderType>Cadastro <span style={{ color: '#2F3132' }}>{registerDate ? format(parseISO(registerDate), 'dd/MM/yyyy') : 'N/A'}</span> - Nascimento <span style={{ color: '#2F3132' }}>{birthdate ? format(parseISO(birthdate), 'dd/MM/yyyy') : 'N/A'}</span></CustomerHeaderType>

        <Divider />
      </>
    ) : (
      <>
        <Column>
          <CustomerName>{name}</CustomerName>

          <CustomerHeaderType>Tipo <span style={{ color: '#2F3132', marginLeft: '8px' }}>{clientCategory || 'Não informado'}</span></CustomerHeaderType>

          <Divider />

          <CustomerHeaderType>Documento <span style={{ color: '#2F3132', marginLeft: '8px' }}>{cpf ? `CPF ${formatCPF(cpf)}` : cnpj ? `CNPJ ${formatCNPJ(cnpj)}` : ''}</span></CustomerHeaderType>

          <Divider />

          <CustomerHeaderType>Cadastro <span style={{ color: '#2F3132' }}>{registerDate ? format(parseISO(registerDate), 'dd/MM/yyyy') : 'N/A'}</span> - Nascimento <span style={{ color: '#2F3132' }}>{birthdate ? format(parseISO(birthdate), 'dd/MM/yyyy') : 'N/A'}</span></CustomerHeaderType>
        </Column>

        <CashBeautyContainer>
          <CustomerHeaderText>CashBeauty</CustomerHeaderText>

          <HighlightedElement
            primaryColor='#F3716D'
            r='243'
            g='113'
            b='109'
            a='0.05'
            text={cashback ? cashback.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : 'R$ 0,00'}
            customStyle={{ padding: '4px 12px' }}
          />
        </CashBeautyContainer>
      </>
    )
  }

  return (
    <>
      {modalEnabler()}

      {!isMobile && (
        <SearchContainer>
          <LinkWrapper to='/'>
            <HiChevronLeft size={24} color='#6460AA' />

            <GoBackLink>Voltar</GoBackLink>
          </LinkWrapper>

          <SearchContent>
            <Input
              placeholder='Busque por nome e cpf/cnpj'
              onChange={(e: ChangeEvent<HTMLInputElement>) => setRedirectSearch(e.target.value)}
              onClick={() => redirectSearch ? setRedirectSearch('') : setShowModal('speaker')}
              value={redirectSearch}
              icon={redirectSearch ? HiOutlineX : HiOutlineMicrophone}
              right
            />
          </SearchContent>

          {redirectSearch && (
            <DropdownContainer>
              <CustomersList>
                {renderList()}
              </CustomersList>
            </DropdownContainer>
          )}

          <Tabs />
        </SearchContainer>
      )}

      {isMobile ? (
        <GoBackContainer>
          <StyledLink to='/'>
            <HiChevronLeft size={24} color='#fff' />
          </StyledLink>

          <GoBackText>Cliente</GoBackText>

          <Empty />
        </GoBackContainer>
      ) : (
        <Header />
      )}

      <Container>
        {isMobile ? (
          <CustomerHeader>
            <CustomerHeaderInfo>
              {renderHeaderInfo()}
            </CustomerHeaderInfo>
          </CustomerHeader>
        ) : (
          <CustomerHeader style={currentTab === 'details' ? {} : { display: 'none' }}>
            {currentTab === 'details' && <StyledAvatar name={handleName(name)} round size='40px' />}

            <CustomerHeaderInfo>
              {renderHeaderInfo()}
            </CustomerHeaderInfo>
          </CustomerHeader>
        )}

        {isMobile && (
          <>
            <Tabs />

            <CustomDivider />
          </>
        )}

        <ContainerBackground style={currentTab !== 'details' ? { minHeight: 'calc(100vh - 354px)' } : {}}>
          {renderTab(currentTab)}
        </ContainerBackground>
      </Container>
    </>
  );
}
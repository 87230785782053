import { Header } from 'components/Header';
import MedSearchLogo from 'assets/logo-by-med-search.png';
import { useWindowDimensions } from 'hooks/useWindowDimensions';
import { BackgroundContainer, Container, ContainerLogo, Content, DateNotificationTitle, Divider, EmptyListFiltered, EmptySearch, LogoMedSearch, NotificationListWrapper, WrapperTitleToggle, TitleNotification, ToggleLabel, TogglerContainer, Version, VersionContainer, NotificationWrapper, TypeListTitle, StyledButton, EmptySearchText } from './styles';
import useNotes from 'hooks/useNotes';
import Toggle from 'react-toggle'
import './toggle.css';
import { useEffect, useState } from 'react';
import { Loader } from 'components/Loader';
import { ErrorScreen } from 'components/ErrorScreen';
import { NotificationItem } from 'components/NotificationItem';
import { Annotation } from 'types/CustomerDetails';
import packageInfo from '../../../package.json';
import { formatDate } from './Util';
import { NavLink } from 'react-router-dom';

export const Notification = () => {

  const { width } = useWindowDimensions();
  const { loading, error, getNotificationLength, notification,
    getNotificationFilterByDay, getNotificationsBeforeAndAfterToday,
    notificationFilterByDay, notificationBeforeAndAfterToday, setNotificationFilterByDay, setNotificationBeforeAndAfterToday
  } = useNotes();
  const [isFilterByToday, setIsFilterByToday] = useState(true);
  const [notificationListToShow, setNotificationListToShow] = useState<Annotation[]>([] as Annotation[]);

  const isDesktop = width >= 1024;

  const DesktopListUtilRender = [
    notificationFilterByDay,
    notificationBeforeAndAfterToday
  ]

  useEffect(() => {
    if (isDesktop) {
      setNotificationFilterByDay(getNotificationFilterByDay());
      setNotificationBeforeAndAfterToday(getNotificationsBeforeAndAfterToday());
    } else {
      if (isFilterByToday) {
        setNotificationListToShow(getNotificationFilterByDay());
      } else {
        setNotificationListToShow(getNotificationsBeforeAndAfterToday());
      }
    }
  }, [notification])

  const handleToggle = (filterByDay: boolean) => {
    setIsFilterByToday(!isFilterByToday);
    if (filterByDay) {
      setNotificationListToShow(getNotificationFilterByDay());
    } else {
      setNotificationListToShow(getNotificationsBeforeAndAfterToday());
    }
  }

  const renderEmpty = () => {
    return (
      <EmptySearch>
        <EmptySearchText>
          Suas notificações aparecerão aqui
        </EmptySearchText>

        <NavLink to='/'>
          <StyledButton>
            Ir para página inicial
          </StyledButton>
        </NavLink>
      </EmptySearch>
    )
  }

  const renderEmptyListFiltered = (isTodayList?: boolean) => {
    return (
      <>
        <EmptyListFiltered>
          {isTodayList ? 'Não há notificações registradas para hoje' : 'Não há notificações registradas para outras datas'}
        </EmptyListFiltered>
      </>
    )
  }

  const renderEmptyListMobile = () => {
    return (
      <>
        <EmptyListFiltered>
          {isFilterByToday ? 'Não há notificações registradas para hoje' : 'Não há notificações registradas para outras datas'}
        </EmptyListFiltered>
      </>
    )
  }

  const renderVersion = (isCheckInPage = false) => {
    return (
      <VersionContainer isCheckInPage={isCheckInPage}>
        <Version>Versão {packageInfo.version}</Version>
      </VersionContainer>
    )
  }

  if (error) {
    return (
      <>
        <Header />

        <ErrorScreen
          title="Algo deu errado..."
          message="Mas logo será resolvido."
          status={500}
        />
      </>
    )
  }

  return (
    <>
      <Header />

      {isDesktop && (
        <ContainerLogo>
          <LogoMedSearch src={MedSearchLogo} />
        </ContainerLogo>
      )}

      {loading ? (
        <Loader
          message1='Estamos carregando as'
          message2='notificações...'
        />
      ) :
        (
          <Container>
            <BackgroundContainer>
              <Content>

                {getNotificationLength() === 0 ? (
                  <>
                    {renderEmpty()}
                  </>
                ) :
                  <>
                    <WrapperTitleToggle>
                      <TitleNotification>
                        Notificações
                      </TitleNotification>

                      {!isDesktop && (
                        <TogglerContainer>
                          <Toggle
                            icons={false}
                            id='toggle'
                            checked={isFilterByToday}
                            onChange={() => handleToggle(!isFilterByToday)}
                          />

                          <ToggleLabel htmlFor='toggle'>{isFilterByToday ? 'Somente notificações de hoje' : 'Ver datas anteriores e posteriores'}</ToggleLabel>
                        </TogglerContainer>
                      )}
                    </WrapperTitleToggle>

                    {isDesktop ? (
                      <NotificationWrapper>
                        <>
                          {DesktopListUtilRender.map((list, indexList) => {
                            return (
                              <NotificationListWrapper key={`list-${indexList}`}>

                                {indexList !== 0 ? (
                                  <TypeListTitle>
                                    Datas anteriores e posteriores
                                  </TypeListTitle>
                                ) :
                                  (
                                    <DateNotificationTitle >
                                      {formatDate(new Date().toISOString())}
                                    </DateNotificationTitle>
                                  )
                                }

                                {list.length === 0 ? (
                                  <>
                                    {renderEmptyListFiltered(indexList === 0)}
                                  </>
                                ) : (
                                  <>

                                    {list.map((item: Annotation, index: number) => {
                                      const previousItem = index > 0 ? list[index - 1] : null;
                                      const currentDateString = formatDate(item.dateNotification, previousItem ? previousItem.dateNotification : null);
                                      const previousDateString = previousItem ? formatDate(previousItem.dateNotification, null) : null;

                                      const elementsToRender = [];

                                      if (currentDateString !== previousDateString) {
                                        elementsToRender.push(
                                          <>
                                            {(currentDateString !== previousDateString) && (indexList !== 0) && (previousDateString !== null) && (
                                              <Divider />
                                            )}
                                            {indexList !== 0 && (
                                              <DateNotificationTitle key={`date-${index}`}>
                                                {currentDateString}
                                              </DateNotificationTitle>
                                            )}
                                          </>
                                        );
                                      }

                                      elementsToRender.push(
                                        <>
                                          <NotificationItem
                                            key={`item-${item.id}`}
                                            id={item.id}
                                            name={item.customerName ? item.customerName : ''}
                                            annotation={item.annotation}
                                            dateNotification={item.dateNotification}
                                            active={item.active}
                                            nameCreatedBy={item.consultantName}
                                            emailCreatedBy={item.consultantEmail}
                                          />
                                        </>

                                      );

                                      return elementsToRender;
                                    })}
                                  </>
                                )}
                              </NotificationListWrapper>
                            )
                          })}
                        </>

                      </NotificationWrapper>
                    ) : (
                      <NotificationListWrapper>

                        {notificationListToShow.length === 0 ? (
                          <>
                            {renderEmptyListMobile()}
                          </>
                        ) : (
                          <>
                            {notificationListToShow.map((item: Annotation, index: number) => {
                              const previousItem = index > 0 ? notificationListToShow[index - 1] : null;
                              const currentDateString = formatDate(item.dateNotification, previousItem ? previousItem.dateNotification : null);
                              const previousDateString = previousItem ? formatDate(previousItem.dateNotification, null) : null;

                              const elementsToRender = [];

                              if (currentDateString !== previousDateString) {
                                elementsToRender.push(
                                  <DateNotificationTitle key={`date-${index}`}>
                                    {currentDateString}
                                  </DateNotificationTitle>
                                );
                              }

                              elementsToRender.push(
                                <NotificationItem
                                  key={`item-${item.id}`}
                                  id={item.id}
                                  name={item.customerName ? item.customerName : ''}
                                  annotation={item.annotation}
                                  dateNotification={item.dateNotification}
                                  active={item.active}
                                  nameCreatedBy={item.consultantName}
                                  emailCreatedBy={item.consultantEmail}
                                />
                              );

                              return elementsToRender;
                            })}
                          </>
                        )}
                      </NotificationListWrapper>
                    )}

                  </>
                }

              </Content>

              {!isDesktop && (
                <Divider />
              )}
            </BackgroundContainer>

            {!isDesktop && (
              renderVersion()
            )}

          </Container>
        )
      }

    </>
  )
}
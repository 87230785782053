import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';

export const Container = styled.header<{ isMobile?: boolean }>`
  height: 72px; 
  background: var(--highlightColor);
  color: var(--highlightText);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-bottom: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;

  /* Desktop */
  @media (min-width:1025px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    height: 100vh;
    width: 136px;
    padding: 24px 32px;
    z-index: 1;
  }
`;

export const Divider = styled.div`
  display: none;
  display: block;
  width: 100%;
  height: 1px;
  background: #A29FCC;
  margin: 24px 0;
`;

export const GreetingContainer = styled.div`
  display: flex;

  /* Desktop */
  @media (min-width:1025px) {
    flex-direction: column;
  }
`;

export const Greeting = styled.span`
  font-weight: 600;
  line-height: 120%;

  /* Desktop */
  @media (min-width:1025px) {
    line-height: 150%;
  }
`;

export const UserName = styled.span`
  font-weight: 600;
  line-height: 120%;
  margin-left: 8px;

  /* Desktop */
  @media (min-width:1025px) {
    line-height: 150%;
    margin-left: 0;
  }
`;

export const ItemsMenuContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: auto;
  cursor: pointer;

  /* Tablet */
  @media (min-width:768px) {
    justify-content: flex-end;
  }

    /* Desktop */
  @media (min-width:1025px) {
    margin-bottom: 24px; 
  }
`;

export const ItemsMenuText = styled.span<{ isActive?: boolean }>`
  font-family: 'Roboto', sans-serif;
  width: 100%;
  margin-right: 5px;
  font-size: 16px;
  text-overflow: ellipsis;
  overflow: hidden; 
  white-space: nowrap;
  color: var(--primaryTextColor);
  text-decoration-line: ${({ isActive }) => (isActive ? 'underline' : 'none')};

  /* Tablet */
  @media (min-width:768px) {
    width: auto;
  }

  /* Desktop */
  @media (min-width:1025px) {
    width: auto;
    color: #fff;
  }
`;

export const ContainerItemsHeaderMobileOrTablet = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 16px;

  /* Tablet */
  @media (min-width:768px) {
    padding: 0 28px;
  }
`;

export const StyledLink = styled(NavLink)`
  color: white;
`;

export const ExternalLink = styled.a`
  width: 100%;
  overflow: hidden; 

   /* Tablet */
  @media (min-width:768px) {
    width: auto;
  }
`;


export const ToggleIcon = styled.div<{ isOpen: boolean }>`
display: flex;
justify-content: center;
align-items: center;
width: 20px;
height: 20px;
cursor: pointer;
transition: transform 0.3s ease-in-out;
transform: ${props => (props.isOpen ? 'rotate(90deg)' : 'none')};
`;

export const MenuContainer = styled.div<{ isOpen?: boolean }>`
position: fixed;
top: 72px;
height: 100vh;
background-color: white;
display: flex;
flex-direction: column;
width: 100%;
padding: 0 16px;

/* Tablet */
@media (min-width:768px) {
  padding: 0 28px;
}

/* Animação */
transform: translateX(${props => props.isOpen ? '0%' : '100%'});
transition: transform 0.3s ease;

/* Animação de slide-in */
@keyframes slide-in {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0%);
  }
}

/* Animação de slide-out */
@keyframes slide-out {
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(100%);
  }
}

/* Quando o menu é aberto */
${props =>
  props.isOpen &&
  css`
  animation: slide-in 0.3s ease forwards;
  `}
  
  /* Quando o menu é fechado */
  ${props =>
    !props.isOpen &&
    css`
    animation: slide-out 0.3s ease forwards;
    `}
    `;
    
  export const MenuItem = styled.div<{ borderBottom?: boolean, isActive?: boolean }>`
  display: flex;
  align-items: center;
  height: 56px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #6D6E71;
  border-bottom: ${({ borderBottom }) => (borderBottom ? '1px solid #B9B9BB' : 'none')};
  margin: 0 16px;
  gap: 10px;
  text-decoration-line: ${({ isActive }) => (isActive ? 'underline' : 'none')};
  text-underline-offset: 2px;
  cursor: pointer;
  
  /* Tablet */
  @media (min-width:768px) {
    margin: 0 28px;
  }
  
  &:hover {
    text-decoration-line: underline;
  }
  `;
  
  export const NotificationWrapper = styled.div`
    display: flex;
    gap: 20px;
  `;
  
  // Estilos para o novo componente
  export const BadgeWrapper = styled.div`
    position: relative;
  `;
  
  export const BadgeCircle = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 18px;
    height: 18px;
    background-color: #FA9E15;
    border-radius: 50%;

    /* !Desktop */
    @media (max-width:1025px) {
      position: absolute;
      top: -32px;
      right: -8px;
    }

    /* Desktop */
    @media (min-width:1025px) {
      margin-left: 10px;
    }
  `;
  
  export const BadgeCount = styled.span`
    font-size: 10px;
    color: black;
  `;
import MedSearchLogo from 'assets/logo-by-med-search.png';
import { AddTypeAccessModal } from 'components/AddUserTypeAccessModal';
import { Header } from 'components/Header';
import { Input } from 'components/Input';
import { Loader } from 'components/Loader';
import { TableUsers } from 'components/TableUsers';
import useUsersTypeAccess from 'hooks/useUsersTypeAccess';
import { useWindowDimensions } from 'hooks/useWindowDimensions';
import { ChangeEvent, useEffect, useState } from 'react';
import { HiOutlinePlus, HiOutlineSearch, HiOutlineX } from 'react-icons/hi';
import {
  ContainerLogo,
  ContainerSearchUser,
  ContainerUser,
  EmptyList,
  EmptyListWrapper,
  LogoMedSearch,
  SearchTitle,
  TextButton,
  UserButton,
  UserButtonContainer
} from './styles';

export const User = () => {
  const { usersTypeAccess, loading, setUsersToShow, isAdmin } = useUsersTypeAccess();

  const [usersFiltered, setUsersFiltered] = useState(usersTypeAccess);
  const [searchUsers, setSearchUsers] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const [isDownloadSuccess, setIsDownloadSuccess] = useState(false);

  const { width } = useWindowDimensions();

  const isDesktop = width > 1025;

  useEffect(() => {
    if (isDownloadSuccess) {
      setTimeout(() => {
        setIsDownloadSuccess(false);
      }, 2000);
    }
  }, [isDownloadSuccess]);

  const getFilteredList = (value: string) => {
    const filteredList = usersTypeAccess.filter((user) => {
      return user.email.toLowerCase().includes(value.toLowerCase());
    });

    setSearchUsers(value);
    setUsersFiltered(filteredList);
    setUsersToShow(filteredList);
  }

  const renderList = () => {
    if (usersFiltered.length > 0) {
      return (
        <TableUsers userList={usersFiltered} />
      )
    }

    return (
      <EmptyListWrapper>
        <EmptyList>{`Não encontramos resultado para "${searchUsers}", tente novamente.`}</EmptyList>
      </EmptyListWrapper>
    )
  }

  const cleanSearch = () => {
    setSearchUsers('');
    setUsersFiltered(usersTypeAccess);
  }

  const handleModalOpen = () => {
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  return (
    <>
      <Header />

      {isDesktop && (
        <ContainerLogo>
          <LogoMedSearch src={MedSearchLogo} />
        </ContainerLogo>
      )}

      <ContainerUser>
        <UserButtonContainer>
          {isAdmin() && <UserButton onClick={handleModalOpen}>
            <HiOutlinePlus color='#ffffff' size={20} />

            <TextButton>Incluir usuário</TextButton>
          </UserButton>}

        </UserButtonContainer>

        <ContainerSearchUser>
          <SearchTitle>Buscar usuário:</SearchTitle>

          <Input
            placeholder='Busque por email do usuário'
            icon={searchUsers ? HiOutlineX : HiOutlineSearch}
            right
            onChange={(e: ChangeEvent<HTMLInputElement>) => getFilteredList(e.target.value)}
            value={searchUsers}
            onClick={() => searchUsers ? cleanSearch() : undefined}
          />
        </ContainerSearchUser>

        {loading ? (
          <Loader
            message1='Estamos buscando os'
            message2='resultados...'
          />
        ) : (
          renderList()
        )}

        <AddTypeAccessModal isOpen={modalOpen} setIsOpen={setModalOpen} onClose={handleModalClose} />

      </ContainerUser>
    </>
  )
}

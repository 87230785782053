import { IconBaseProps } from 'react-icons/lib';
import {
  Container,
  Text
} from './styles'

interface HighlightedTextProps {
  text?: string;
  icon?: React.ComponentType<IconBaseProps>
  primaryColor: string;
  r: string;
  g: string;
  b: string;
  a: string;
  customStyle?: React.CSSProperties;  
}

export const HighlightedElement = ({ text, icon: Icon, primaryColor, r, g, b, a, customStyle }: HighlightedTextProps) => {
  return (
    <Container color={`rgba(${r},${g},${b},${a})`} style={Icon && customStyle ? { padding: '8px', ...customStyle } : Icon ? { padding: '8px' } : customStyle ? customStyle : {}}>
      {text && <Text color={primaryColor}>{text}</Text>}

      {Icon && <Icon size={20} color={primaryColor} />}
    </Container>
  )
}
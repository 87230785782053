import styled from 'styled-components';

export const Container = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 8px;
  font-family: 'Roboto', sans-serif;
  width: 100%;
  border-bottom: 1px solid var(--dividerColor);
  background-color: var(--cardBackground);
  width: calc(100% - 16px);

  &:last-child {
    border-bottom: none;
  }
`;

export const ItemDescription = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 85%;
`;

export const PositionContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 36px;
  min-height: 36px;
  max-width: 36px;
  max-height: 36px;
  border-radius: 8px;
  margin-right: 12px;
`;

export const Position = styled.span`
  font-size: 16px;
  font-weight: 600;
  color: var(--highlightText);
  font-family: 'Poppins', sans-serif;
  line-height: 150%;
`;

export const ItemName = styled.div`
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: var(--secondaryTextColor);
  /* max-width: 250px; */
`;

export const Quantity = styled.div`
  color: var(--primaryTextColor);
  text-align: center;
`;

/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { TABS } from 'pages/Customer/utils/tabs'
import { Header } from 'components/Header';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import {
  HiChevronLeft,
  HiOutlineX,
  HiOutlineMicrophone,
  HiSearch,
} from 'react-icons/hi';
import { OrderDetail } from 'types/OrderDetail';
import {
  MainContainer,
  LinkWrapper,
  GoBackLink,
  SearchContainer,
  SearchContent,
  DropdownContainer,
  CustomersList,
  CustomerItem,
  CustomerInfo,
  Empty,
  StyledInput,
  TabsContainer,
  Tab,
  TabTitle,
  TabSelected,
  TooltipWrapper,
  StyledHiLockClosed,
  TooltipContainer,
  TooltipText,
  HighlightedText,
  ContainerInput,
  ContainerCustomersList,
} from './styles-desktop';
import { formatCNPJ, formatCPF, sanitizeString } from 'utils';
import { Modal } from 'components/Modal';
import { ModalProps } from 'types/ModalProps';
import useCustomers from 'hooks/useCustomers';
import { Link } from 'react-router-dom';
import useAuth from 'hooks/useAuth';
import { api } from 'config/api';
import { Customer } from 'types/CustomersContextType';
import { MostShoppedItemsModal } from 'components/MostShoppedItemsModal';
import { NotesModal } from 'components/NotesModal';
import { Details, Notes, Finnancial } from './Tabs';
import { BlockedCustomerInfo, BlockedCustomerItem } from '../Mobile/styles';
import { SpeakerModal } from 'components/SpeakerModal';
import { ModalType } from '../utils/modalTypes';
import useUsersTypeAccess from 'hooks/useUsersTypeAccess';
import { Requests } from './Tabs/Requests';

export const DesktopView = ({ name, clientCategory, cpf, cnpj, email, phone, whatsapp, lastShopping, averageTicket, birthdate, address, registerDate, cashback, orders, onClick, isMyClient, mostShoppedItems, currentTab, setCurrentTab, note, noteDeprecated, profile }: any) => {
  const [order, setOrder] = useState<ModalProps>({} as ModalProps);
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [searchCustomers, setSearchCustomers] = useState<Customer[]>([]);
  const [showModal, setShowModal] = useState<ModalType>('none');
  const [isSearchListActive, setIsSearchListActive] = useState(false);
  const { isAdmin } = useUsersTypeAccess();
  const containerRef = useRef<HTMLDivElement | null>(null); // Definindo o tipo da ref

  const { auth } = useAuth();
  const { redirectSearch, setRedirectSearch, setCustomers, setSearch, setLastSearch, setError, setPage } = useCustomers();

  const handleInputSearch = async () => {
    setIsSearchListActive(true);
    if (redirectSearch) {
      try {
        setLoadingSearch(true);

        setPage(1);

        const response = await api.get(`customers?email=${auth ? auth?.username : ''}&page=1&search=${sanitizeString(redirectSearch)}&isMyClientsOnly=${isAdmin() ? false : true }`);

        setSearchCustomers(response.data.data);

        setCustomers(response.data);
        setSearch(redirectSearch);
        setLastSearch(redirectSearch);
      } catch (error) {
        setError(true);
      } finally {
        setLoadingSearch(false);
      }
    }
  }
  
  useEffect(() => {
    if (['details', 'mostShoppedItems'].includes(showModal)) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [showModal]);

  const handleOrderClick = (order: OrderDetail) => {
    setOrder(order);
    setShowModal('details');
  }


  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
        setIsSearchListActive(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const renderList = () => (
    <ContainerCustomersList ref={containerRef}>
      {loadingSearch ? (
        <CustomerItem style={{ cursor: 'not-allowed' }}>
          <CustomerInfo>Procurando...</CustomerInfo>
        </CustomerItem>
      ) : searchCustomers.length > 0 ? (
        searchCustomers.map((customer, index) => (
          index < 3 ? (
            isMyClient || isAdmin() ? (
              <Link
                to={`../customer/${customer.cpf ? customer.cpf : customer.cnpj}`}
                key={index}
                onClick={() => {
                  onClick(customer);
                  setRedirectSearch('');
                }}
              >
                <CustomerItem key={index}>
                  <CustomerInfo>
                    {customer.name} ({customer.cpf ? formatCPF(customer.cpf) : customer.cnpj ? formatCNPJ(customer.cnpj) : ''})
                  </CustomerInfo>
                </CustomerItem>
              </Link>
            ) : (
              <BlockedCustomerItem key={index}>
                <BlockedCustomerInfo>
                  {customer.name} ({customer.cpf ? formatCPF(customer.cpf) : customer.cnpj ? formatCNPJ(customer.cnpj) : ''})
  
                  <TooltipWrapper>
                    <StyledHiLockClosed size={24} color='#B9B9BB' />
  
                    <TooltipContainer>
                      <TooltipText>
                        Carteira do consultor(a)
                        <HighlightedText> {customer.clientConsultant}</HighlightedText>
                      </TooltipText>
                    </TooltipContainer>
                  </TooltipWrapper>
                </BlockedCustomerInfo>
              </BlockedCustomerItem>
            )
          ) : (
            index === searchCustomers.length - 1 && index >= 3 ? (
              <Link to='/' onClick={() => setRedirectSearch('')} key={index}>
                <CustomerItem>
                  <CustomerInfo>Ver todos</CustomerInfo>
                </CustomerItem>
              </Link>
            ) : null
          )
        ))
      ) : (
        <CustomerItem style={{ cursor: 'not-allowed' }}>
          <CustomerInfo>Nenhum cliente encontrado</CustomerInfo>
        </CustomerItem>
      )}
    </ContainerCustomersList>
  );
  

  const renderTab = (tab: string) => {
    switch (tab) {
      case 'details':
        return (
          <Details
            name={name}
            clientCategory={clientCategory}
            cpf={cpf}
            cnpj={cnpj}
            registerDate={registerDate}
            birthdate={birthdate}
            cashback={cashback}
            orders={orders}
            phone={phone}
            whatsapp={whatsapp}
            email={email}
            address={address}
            mostShoppedItems={mostShoppedItems}
            lastShopping={lastShopping}
            averageTicket={averageTicket}
            onClickHandleOrderClick={(order: OrderDetail) => handleOrderClick(order)}
            onClickMostShoppedItemsModal={() => setShowModal('mostShoppedItems')}
          />
        )

      case 'notes':
        return (
          <Notes
            note={note}
            noteDeprecated={noteDeprecated}
            profile={profile}
            email={email}
            onClickSetShowNotesModal={() => setShowModal('notes')}
            // onClickSetShowDeleteNoteModal={() => setShowModal('deleteNote')}
          />
        )

      case 'requests':
        return (
          <Requests
            name={name}
            cpf={cpf}
            cnpj={cnpj}
            email={email}
            clientCategory={clientCategory}
            address={address}
          />
        )

      case 'finnancial':
        return (
          <Finnancial
            name={name}
            cpf={cpf}
            cnpj={cnpj}
            orders={orders}
            clientCategory={clientCategory}
          />
        )

      default:
        return null
    }
  }

  const modalEnabler = () => {
    switch (showModal) {
      case 'details':
        return (
          <Modal
            code={order.code}
            items={order.items}
            saleType={order.saleType}
            shippingAddress={order.shippingAddress}
            discount={order.discount}
            deliveryFee={order.deliveryFee}
            paymentMethod={order.paymentMethod}
            onClick={() => setShowModal('none')}
            onCloseModal={() => setShowModal('none')}
          />
        )

      case 'mostShoppedItems':
        return (
          <MostShoppedItemsModal
            mostShoppedItems={mostShoppedItems}
            onClick={() => setShowModal('none')}
            onCloseModal={() => setShowModal('none')}
          />
        )

      case 'notes':
        return (
          <NotesModal
            onCloseModal={() => setShowModal('none')}
            customerName={name}
            customerDocument={cpf ? cpf : cnpj ? cnpj : ''}
          />
        )

      // case 'deleteNote':
      //   return (
      //     <DeleteNoteModal
      //       onCloseModal={() => setShowModal('none')}
      //       customer={email}
      //     />
      //   )

      case 'speaker':
        return (
          <SpeakerModal
            onCloseModal={() => setShowModal('none')}
            setSearch={setRedirectSearch}
          />
        )

      default:
        return null
    }
  }

  return (
    <>
      {modalEnabler()}

      <Header />

      <SearchContainer>

        <SearchContent>

          <LinkWrapper to='/'>
            <HiChevronLeft size={24} color='#6460AA' />

            <GoBackLink>Voltar</GoBackLink>

          </LinkWrapper>

          <ContainerInput>

            <StyledInput
              placeholder='Busque por nome e cpf/cnpj'
              onChange={(e: ChangeEvent<HTMLInputElement>) => setRedirectSearch(e.target.value)}
              onClick={() => handleInputSearch()}
              value={redirectSearch}
              icon={HiSearch}
              right
            />

            {isSearchListActive && (
              <DropdownContainer>
                <CustomersList>
                  {renderList()}
                </CustomersList>
              </DropdownContainer>
            )}
            
          </ContainerInput>

        </SearchContent>

        <TabsContainer>
          {TABS.map(({ type, name }) => {
            return (
              <Tab onClick={() => setCurrentTab(type)} key={type}>
                <Empty />

                <TabTitle style={currentTab === type ? { color: '#6460AA', fontWeight: 'bold', background: '#fff' } : {}}>{name}</TabTitle>

                {currentTab === type ? <TabSelected /> : <Empty />}
              </Tab>
            )
          })}
        </TabsContainer>

      </SearchContainer>

      <MainContainer>
        {renderTab(currentTab)}
      </MainContainer>
    </>
  )
}
import { HiCheckCircle } from 'react-icons/hi';
import { ContainerModalSuccess, Dimmer, TextSuccessAdd } from './styles';

interface SuccessModalProps {
  message: string;
}

export function SuccessModal({ message }: SuccessModalProps) {
  return (
    <Dimmer>
      <ContainerModalSuccess>
        <HiCheckCircle color='#00E13F' size={44} />
        <TextSuccessAdd>
          {message}
        </TextSuccessAdd>
      </ContainerModalSuccess>
    </Dimmer>
  )
}

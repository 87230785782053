import styled from 'styled-components';

export const Dimmer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(17, 24, 39, 0.8);
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Container = styled.div`
  background: var(--background);
  border-radius: 4px;
  padding: 24px 20px;
  display: flex;
  flex-direction: column;
  width: 85%;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 70%;

  /* Tablet */
  @media (min-width:768px) {
    max-width: 400px;
  }
`;

export const Title = styled.span`
  font-weight: 600;
  font-size: 16px;
  color: var(--primaryTextColor);
  text-align: center;
  margin-bottom: 32px;
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
`;

export const Unchecked = styled.div`
  width: 16px;
  height: 16px;
  border: 1px solid #ececec;
  border-radius: 4px;
  cursor: pointer;
`;

export const Checked = styled.div`
  width: 16px;
  height: 16px;
  background: var(--highlightColor);
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Label = styled.label`
  margin-left: 8px;
  cursor: pointer;
  font-weight: 400;
  font-size: 14px;
  color: var(--primaryTextColor);
`;

export const SubTitle = styled.span`
  margin-top: 12px;
  font-weight: 600;
  font-size: 14px;
  color: var(--primaryTextColor);
  margin-bottom: 12px;
`;

export const CompanionContainer = styled.div`
  border: 1px solid #b9b9bb;
  border-radius: 4px;
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  position: relative;
  margin-bottom: 24px;
`;

export const DropdownPlaceholder = styled.span`
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  font-family: 'Roboto', sans-serif;
  color: #B9B9BB;
`;

export const DropdownContainer = styled.div`
  background-color: var(--background);
  box-shadow: 0px 4px 12px rgba(146, 140, 151, 0.25);
  border-radius: 4px;
  position: absolute;
  top: 60px;
  right: 0;
  left: 0;
  z-index: 3;
`;

export const List = styled.ul`
  
`;

export const Item = styled.li`
  padding: 13px 16px;
  color: var(--primaryTextColor);
  cursor: pointer;

  &:hover {
    background-color: var(--searchBackground);
  }
`;

export const AnnotationContainer = styled.div`
  margin-bottom: 32px;
`;  

export const AnnotationHeader = styled.span`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Text = styled.span`
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: var(--primaryTextColor);
`;

export const Divider = styled.div`
  border: 1px solid #ECECEC;
  margin-bottom: 12px;
`;

export const NoteBody = styled.textarea`
  border: 1px solid #ECECEC;
  width: calc(100% - 20px);
  height: 160px;
  outline: none;
  padding: 10px;
`;

export const ControlContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const Cancel = styled.span`
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 14px;
  text-decoration: underline;
  color: var(--highlightColor);
  cursor: pointer;
`;

export const ButtonWrapper = styled.div`
  width: 163px;
  margin-left: 38px;
`;
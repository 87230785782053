import { Button } from 'components/Button';
import styled from 'styled-components';

export const Dimmer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(17, 24, 39, 0.8);
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Container = styled.div`
  background: var(--background);
  border-radius: 8px;
  padding: 20px 16px;
  display: flex;
  flex-direction: column;
  width: 85%;
  max-height: 80%;
  overflow-y: auto;
  overflow-x: hidden;

  /* Tablet */
  @media (min-width:768px) {
    max-width: 400px;
  }
`;

export const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 8px;
  margin-bottom: 12px;  
`;

export const Empty = styled.div`
  width: 36px;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;


export const Title = styled.h1`
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  color: var(--primaryTextColor);
  font-size: 16px;
`;

export const Subtitle = styled.span`
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  margin-top: 4px;
  color: var(--primaryTextColor);
`;

export const Clickable = styled.div`
  cursor: pointer;
`;

export const Divider = styled.div`
  border: 1px solid var(--dividerColor);
  margin-bottom: 12px;
`;

export const ProfileBody = styled.textarea`
  height: 193px;
  max-width: 100%;
  color: #4f4f4f;
  font-size: 16px;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  line-height: 150%;
  resize: none;
  border: 1px solid var(--dividerColor);
  padding: 4px 10px;

  &:focus {
    outline: none;
  }

  &::placeholder {
    font-size: 16px;
    font-style: italic;
  }
`;

export const Handler = styled.div`
  background-color: var(--dividerColor);
  margin: 0 -16px;
  margin-bottom: -20px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledButton = styled(Button)`
  width: 163px;
  margin: 16px 0;
`;

export const ChipsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`
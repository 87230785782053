/* eslint-disable @typescript-eslint/no-explicit-any */
export const handleFiles = (boletos: any) => {
  if (!boletos || boletos.length === 0) {
    return ''
  }

  const downloadables: any[] = []

  boletos.forEach((boleto: any) => {
    downloadables.push(boleto.url);
  })

  return downloadables
}

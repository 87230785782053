import styled from 'styled-components';
import { HiArrowRight } from 'react-icons/hi';

export const Container = styled.div`
  background-color: var(--background);
  padding: 30px 32px;
  display: flex;
  justify-content: space-between;
`;

export const NavContainer = styled.nav`
  display: flex;
  background-color: var(--dividerColor);
  height: 70px;
`;

export const Tab = styled.div`
  color: var(--secondaryTextColor);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 30px;
  margin-right: 20px;
  min-width: 150px;
  font-family: 'Source Sans Pro', serif;
  font-weight: 600;
  font-size: 20px;
  color: var(--secondaryTextColor);
`;

export const EmailWrapper = styled.div`
  margin-right: 45px;
  padding-top: 10px;
  width: 100%;
`;

export const Title = styled.h1`
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  color: var(--secondaryTextColor);
  margin-bottom: 20px;
  margin-left: 8px;
`;

export const EmailContentBox = styled.div`
  border: 1px solid var(--searchBackground);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.06), 0px 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 16px;
  margin-bottom: 24px;
`;

export const EmailContent = styled.p`
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #4f4f4f;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const StyledPricingLink = styled.span`
  padding: 0 32px;
  height: 42px;
  background: #FFF;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #6460AA;
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  font-size: 16px;
  cursor: pointer;
  transition: opacity 0.2s;
  border: 2px solid #6460AA;
  margin-right: 24px;

  &:hover {
    opacity: 0.9;
  }
`;

export const StyledLink = styled.a`
  width: 163px;
  height: 46px;
  background: #6460AA;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  font-size: 16px;
  line-height: 150%;
  cursor: pointer;
  transition: opacity 0.2s;

  &:hover {
    opacity: 0.9;
  }
`;

export const StyledImage = styled.img`
  height: 345px;
`;

export const StyledHiArrowRight = styled(HiArrowRight)`
  margin-left: 10px;
`;

export const InvoiceContainer = styled.div`
  background-color: var(--background);
  padding: 16px 24px;
  min-height: 300px;
`;

export const Divider = styled.div`
  border: 1px solid var(--dividerColor);
  margin: 16px -24px;
`;

export const FilterContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

export const Table = styled.table`
  margin: -18px -26px;
  border-collapse: collapse;
`;

export const TableRow = styled.tr`
  &:nth-child(odd) {
    background-color: var(--searchBackground);
  }
`;

export const TableHeader = styled.th<{ width?: number }>`
  font-family: 'Roboto', serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: var(--tableHeader);
  text-align: left;
  width: ${({ width }) => `${width}px` };
  height: 57px;
  padding: 0 24px;
`;

export const TableData = styled.td`
  padding: 0 24px;
  height: 57px;
  color: var(--primaryTextColor);
  font-family: 'Roboto', serif;

  &:first-child {
    cursor: pointer;
    font-weight: 600;
    text-decoration: underline;
    color: #4F4F4F;
  }

  &:last-child {
    display: flex;
    align-items: center;
  }
`;
import MedSearchLogo from 'assets/logo-by-med-search.png';
import { AddTypeAccessModal } from 'components/AddUserTypeAccessModal';
import { FilterReportModal } from 'components/FilterReportModal';
import { Header } from 'components/Header';
import { Input } from 'components/Input';
import { Loader } from 'components/Loader';
import { SuccessModal } from 'components/SuccessModal';
import { TableUsers } from 'components/TableUsers';
import useUsersTypeAccess from 'hooks/useUsersTypeAccess';
import { useWindowDimensions } from 'hooks/useWindowDimensions';
import { ChangeEvent, useEffect, useState } from 'react';
import { HiOutlineSearch, HiOutlineX } from 'react-icons/hi';
import {
  ContainerLogo,
  ContainerSearchUser,
  ContainerTitleDesc,
  ContainerUser,
  EmptyList,
  EmptyListWrapper,
  LogoMedSearch,
  ReportButton,
  SearchTitle,
  TextButton,
  TextDesc,
  TextTitle,
  UserButtonContainer
} from './styles';

export const Report = () => {
  const { usersTypeAccess, loading, setUsersToShow, isAdmin } = useUsersTypeAccess();

  const [usersFiltered, setUsersFiltered] = useState(usersTypeAccess);
  const [searchUsers, setSearchUsers] = useState('');
  const [filterReportType, setFilterReportType] = useState<'access' | 'checkIn'>('access');
  const [isFilterReportModalOpen, setIsFilterReportModalOpen] = useState(false);
  const [isDownloadSuccess, setIsDownloadSuccess] = useState(false);

  const { width } = useWindowDimensions();

  const isDesktop = width > 1025;

  useEffect(() => {
    if (isDownloadSuccess) {
      setTimeout(() => {
        setIsDownloadSuccess(false);
      }, 2000);
    }
  }, [isDownloadSuccess]);

  const getFilteredList = (value: string) => {
    const filteredList = usersTypeAccess.filter((user) => {
      return user.email.toLowerCase().includes(value.toLowerCase());
    });

    setSearchUsers(value);
    setUsersFiltered(filteredList);
    setUsersToShow(filteredList);
  }

  const renderList = () => {
    if (usersFiltered.length > 0) {
      return (
        <TableUsers isReportPage userList={usersFiltered} />
      )
    }

    return (
      <EmptyListWrapper>
        <EmptyList>{`Não encontramos resultado para "${searchUsers}", tente novamente.`}</EmptyList>
      </EmptyListWrapper>
    )
  }

  const cleanSearch = () => {
    setSearchUsers('');
    setUsersFiltered(usersTypeAccess);
  }

  return (
    <>
      <Header />

      {isDesktop && (
        <ContainerLogo>
          <LogoMedSearch src={MedSearchLogo} />
        </ContainerLogo>
      )}

      <ContainerUser>

        <ContainerTitleDesc>
          <TextTitle>
            Relatórios
          </TextTitle>
          <TextDesc>
            Extraia relatórios de acessos e check-in realizados pelo seu time
          </TextDesc>
        </ContainerTitleDesc>

        <UserButtonContainer>

          {/* {isDesktop && (
            <>
              <ReportButton onClick={
                () => {
                  setFilterReportType('access');
                  setIsFilterReportModalOpen(true);
                }}>
                <TextButton>Relatório de acessos</TextButton>
              </ReportButton>

              <ReportButton onClick={
                () => {
                  setFilterReportType('checkIn');
                  setIsFilterReportModalOpen(true);
                }}>
                <TextButton>Relatório de check-in</TextButton>
              </ReportButton>
            </>
          )} */}

          {isDesktop && (

              <ReportButton onClick={
                () => {
                  setFilterReportType('checkIn');
                  setIsFilterReportModalOpen(true);
                }}>
                <TextButton>Relatório de check-in</TextButton>
              </ReportButton>
          )}

        </UserButtonContainer>

        <ContainerSearchUser>
          <SearchTitle>Buscar usuário:</SearchTitle>

          <Input
            placeholder='Busque por email do usuário'
            icon={searchUsers ? HiOutlineX : HiOutlineSearch}
            right
            onChange={(e: ChangeEvent<HTMLInputElement>) => getFilteredList(e.target.value)}
            value={searchUsers}
            onClick={() => searchUsers ? cleanSearch() : undefined}
          />
        </ContainerSearchUser>

        {loading ? (
          <Loader
            message1='Estamos buscando os'
            message2='resultados...'
          />
        ) : (
          renderList()
        )}

        {isDownloadSuccess && <SuccessModal message='Relatório exportado com sucesso!' />}

        {isFilterReportModalOpen && (
          <FilterReportModal
            filterReportType={filterReportType}
            onClose={() => setIsFilterReportModalOpen(false)}
            onSuccess={() => setIsDownloadSuccess(true)}
          />
        )}
      </ContainerUser>
    </>
  )
}

import { HighlightedElement } from 'components/HighlightedElement';
import { Input } from 'components/Input';
import { api } from 'config/api';
import useUsersTypeAccess from 'hooks/useUsersTypeAccess';
import { ChangeEvent, useEffect, useState } from 'react';
import { HiChevronDown, HiX } from 'react-icons/hi';
import { ClipLoader } from 'react-spinners';
import {
  AddButton, 
  Clickable, 
  ContainerAddButton, 
  ContainerModalForm, 
  ContainerSearchUser,
  ContainerTypeAccess, 
  Dimmer, 
  DropdownButton,
  DropdownContainer,
  DropdownItem,
  DropdownList, 
  ModalHeader,
  Title, TypeAccessTitleSpan
} from './styles';
import { TypeAccessOptions } from './util';
import { SuccessModal } from 'components/SuccessModal';

export const AddTypeAccessModal = ({ isOpen, setIsOpen, onClose }: any) => {
  const [isTypeAccessDropdownOpen, setIsTypeAccessDropdownOpen] = useState(false);
  const [isHierarchyDropdownOpen, setIsHierarchyDropdownOpen] = useState(false);
  const [isModalSuccessOpen, setIsModalSuccessOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [typeAccess, setTypeAccess] = useState('Administrador');
  const [hierarchy, setHierarchy] = useState('');
  const [hierarchyList, setHierarchyList] = useState<string[]>([])
  const [email, setEmail] = useState('');

  const { getUsersTypeAccess, usersTypeAccess } = useUsersTypeAccess();

  useEffect(() => {
    const close = (e: any) => {
      if(e.keyCode === 27){
        onClose && onClose()
      }
    }
    window.addEventListener('keydown', close)
    return () => window.removeEventListener('keydown', close)
  },[]);

  useEffect(() => {
    const list = [];

    for (const user of usersTypeAccess) {
      if (user.typeAccess.toLowerCase() === 'administrador' || user.typeAccess.toLowerCase() === 'gerente') {
        list.push(user.email);
      }
    }

    setHierarchyList(list);
  }, [usersTypeAccess]);

  const toggleTypeAccessDropdown = () => {
    setIsTypeAccessDropdownOpen(!isTypeAccessDropdownOpen);
  }

  const toggleHierarchyDropdown = () => {
    setIsHierarchyDropdownOpen(!isHierarchyDropdownOpen);
  }

  const handleTypeAccessSelected = (option: string) => {
    setTypeAccess(option)
    toggleTypeAccessDropdown();
  }

  const handleHierarchySelected = (option: string) => {
    setHierarchy(option);
    toggleHierarchyDropdown();
  }

  const handleAddUser = async () => {
    try {
      setLoading(true);

      const body = {
        email,
        typeAccess,
        hierarchy,
      };

      await api.post('/user/typeAccess', body);
    } catch (error) {
      console.log(error)
    } finally {
      setTimeout(() => {
        setIsOpen(false);
        setLoading(false);
        setEmail('');
        toggleTypeAccessDropdown();
        toggleHierarchyDropdown();
        setIsModalSuccessOpen(true);
      }, 1000);
      setTimeout(() => {
        setIsModalSuccessOpen(false);
        getUsersTypeAccess();
      }, 2000);
    }
  }

  const validateEmail = () => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@(?:medbeauty\.com\.br|ext\.medbeauty\.com\.br)$/;

    return emailRegex.test(email);
  }

  return (
    <>
      {isOpen && (
        <Dimmer onClick={onClose}>
          <ContainerModalForm onClick={(e:React.MouseEvent) => e.stopPropagation()}>
            <ModalHeader>
              <Title>Incluir novo usuário</Title>

              <Clickable onClick={onClose}>
                <HighlightedElement
                  primaryColor='#6460AA'
                  icon={HiX}
                  r='255'
                  g='255'
                  b='255'
                  a='1'
                />
              </Clickable>
            </ModalHeader>

            <ContainerSearchUser>
              <Input
                onChange={(e: ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
                value={email}
                placeholder='Digite o e-mail para cadastro'
              />
            </ContainerSearchUser>

            <ContainerTypeAccess>
              <TypeAccessTitleSpan>Tipo de acesso</TypeAccessTitleSpan>

              <DropdownContainer>
                <DropdownButton onClick={toggleTypeAccessDropdown}>
                  {typeAccess} <HiChevronDown color='#6D6E71' size={24} />
                </DropdownButton>
                
                {isTypeAccessDropdownOpen && (
                  <DropdownList>
                    {TypeAccessOptions.map((option) => {
                      return (
                        <DropdownItem isSelected={option == typeAccess} key={option} onClick={() => handleTypeAccessSelected(option)}>{option}</DropdownItem>
                      )
                    })}
                  </DropdownList>
                )}
              </DropdownContainer>
            </ContainerTypeAccess>

              {typeAccess.toUpperCase() !== 'ADMINISTRADOR' && (
                <ContainerTypeAccess>
                  <TypeAccessTitleSpan>Hierarquia: </TypeAccessTitleSpan>

                  <DropdownContainer>
                    <DropdownButton onClick={toggleHierarchyDropdown}>
                      {hierarchy} <HiChevronDown color='#6D6E71' size={24} />
                    </DropdownButton>
                    
                    {isHierarchyDropdownOpen && (
                      <DropdownList>
                        {hierarchyList.map((option) => {
                          return (
                            <DropdownItem isSelected={option == hierarchy} key={option} onClick={() => handleHierarchySelected(option)}>{option}</DropdownItem>
                          )
                        })}
                      </DropdownList>
                    )}
                  </DropdownContainer>
                </ContainerTypeAccess>
              )}

            <ContainerAddButton>
              <AddButton onClick={handleAddUser} disabled={!validateEmail()}>
                {loading ? (
                  <ClipLoader
                    color='#fff'
                    size={16}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                  />
                ) : 'Incluir'}
              </AddButton>
            </ContainerAddButton>
          </ContainerModalForm>
        </Dimmer>
      )}

      {isModalSuccessOpen && <SuccessModal message='Usuário adicionado com sucesso' />}
    </>
  )
};
/* eslint-disable react/prop-types */
import { useEffect } from 'react';
import { HiX } from 'react-icons/hi';
import { HighlightedElement } from 'components/HighlightedElement';
import { MostShoppedItemsCard } from 'components/MostShoppedItemsCard';
import { MostShoppedItemsModalProps } from 'types/ModalProps';
import { 
  Container, 
  Dimmer,
  ModalHeader,
  Empty,
  Title,
  Subtitle,
  Clickable,
  Column,
  MostShoppedItemsWrapper,
} from './styles';

export const MostShoppedItemsModal = ({ mostShoppedItems, onClick, onCloseModal }: MostShoppedItemsModalProps) => {

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const close = (e: any) => {
      if(e.keyCode === 27){
        onCloseModal && onCloseModal()
      }
    }
    window.addEventListener('keydown', close)
    return () => window.removeEventListener('keydown', close)
  },[]);

  return (
    <Dimmer onClick={onCloseModal}>
      <Container onClick={(e:React.MouseEvent) => e.stopPropagation()}>
        <ModalHeader>
          <Empty />

          <Column>
            <Title>Itens mais comprados</Title>

            <Subtitle>Últimos 6 meses</Subtitle>          
          </Column>

          <Clickable onClick={onClick}>
            <HighlightedElement 
              primaryColor='#6460AA'
              icon={HiX}
              r='100'
              g='96'
              b='170'
              a='0.2'
            />
          </Clickable>
        </ModalHeader>

        <MostShoppedItemsWrapper>
          {mostShoppedItems.map(({ description, quantity }: { description: string, quantity: number }, index: number) => {
            return (
              <MostShoppedItemsCard 
                key={index}
                position={index + 1}
                description={description}
                quantity={quantity}
              />
            )
          })}
        </MostShoppedItemsWrapper>
      </Container>
    </Dimmer>
  )
}
import styled, { keyframes } from 'styled-components';

export const MAX_LAYERS = 1;

const pulse = keyframes`
  0%,10% {
    opacity: 0;
    transform: scale(1.1);
  }
  80% {
    opacity: 0.7;
    transform: scale(1.15,1.4);
  }
  81%, 100% {
    opacity: 0;
    transform: scale(1);
  }
`;

const Scale = keyframes`
  0% {
    transform: scale(1);
  }
  35%, 80% {
    transform: scale(1.1,1.35);
  }
  100% {
    transform: scale(1);
  }
`;

export const Wrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  position: relative;
`;

export const Container = styled.div`
  z-index: ${MAX_LAYERS + 1};
`;

export const Pulse = styled.div<{ layer: number }>`
  animation: ${({ layer }) => (layer ? pulse : Scale)} 1.5s infinite;
  border-radius: 50%;
  border: 3px solid #A29FCC;
  height: ${({ layer = 0 }) => 52 + layer}px;
  position: absolute;
  width: ${({ layer = 0 }) => 64 + layer}px;
  z-index: ${({ layer = 0 }) => MAX_LAYERS - layer};
`;
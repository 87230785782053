import { api } from 'config/api';
import useAuth from 'hooks/useAuth';
import useNotes from 'hooks/useNotes';
import { useState } from 'react';
import { Customer, CustomersReturn } from 'types/CustomersContextType';
import { sanitizeString } from 'utils';
import { CustomersContext } from './CustomersContext';
import { Annotation, AnnotationDeprecated, Profile } from 'types/CustomerDetails';
import useUsersTypeAccess from 'hooks/useUsersTypeAccess';
import { useWindowDimensions } from 'hooks/useWindowDimensions';

type Props = {
  children?: React.ReactNode;
}

export const CustomersProvider = ({ children }: Props) => {
  const [singleCustomer, setSingleCustomer] = useState<Customer>({} as Customer);
  const [customers, setCustomers] = useState<CustomersReturn>({} as CustomersReturn);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [search, setSearch] = useState('');
  const [lastSearch, setLastSearch] = useState('');
  const [redirectSearch, setRedirectSearch] = useState('');
  const [dateOffset, setDateOffset] = useState(90);
  const [page, setPage] = useState(1);
  const [isMyClientsOnly, setIsMyClientsOnly] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);

  const { auth } = useAuth();
  const { setNote, note, noteDeprecated, setNoteDeprecated, setProfile } = useNotes();
  const { width } = useWindowDimensions();
  const { isAdmin } = useUsersTypeAccess();

  const isMobile = width < 768;

  const getCustomersWithRedirect = async () => {
    setCustomers({} as CustomersReturn);
    setSearch(redirectSearch);
    setRedirectSearch('');
    
    await getCustomers();
  }

  const getCustomers = async () => {
    try {
      setLoading(true);

      const { data: AxiosData } = await api.get<CustomersReturn>(`customers?email=${auth ? auth?.username : ''}&page=${page === 0 ? 1 : page}&search=${sanitizeString(search)}&isMyClientsOnly=${isAdmin() ? false : isMyClientsOnly}`);

      if (isMobile) {
        const updatedList = {
          data: customers.data && page > 1 ? [...customers.data, ...AxiosData.data] : AxiosData.data,
          page: AxiosData.page,
          perPage: AxiosData.perPage,
          total: AxiosData.total,
          lastPage: AxiosData.lastPage
        }

        setCustomers(updatedList);
      } else {
        setCustomers(AxiosData);
      }
      
      setLastSearch(search);
    } catch (err) {
      setError(true);
    } finally {
      setLoading(false);
    }
  }

  const getCustomerByDocument = async (document: string) => {
    try {
      setLoading(true);
      
      const response = await api.get(`customer/details/${document}?email=${auth ? auth?.username : ''}&dateOffset=${dateOffset}`);
      
      if (response.data[0].annotation) {
        setNoteDeprecated(response.data[0].annotation);
      } else {
        setNoteDeprecated({} as AnnotationDeprecated);
      }

      if (response.data[0].newAnnotations.length > 0) {
        setNote(response.data[0].newAnnotations);
      } else {
        setNote([] as Annotation[]);
      }

      if (response.data[0].profile) {
        setProfile(response.data[0].profile);
      } else {
        setProfile({} as Profile);
      }

      setSingleCustomer(response.data[0]);
    } catch (err) {
      setError(true);
    } finally {
      setLoading(false);
    }
  }

  return (
    <CustomersContext.Provider value={{ singleCustomer, setSingleCustomer, customers, setCustomers, search, lastSearch, setLastSearch, loading, error, getCustomers, getCustomerByDocument, setSearch, setLoading, redirectSearch, setRedirectSearch, getCustomersWithRedirect, setError, dateOffset, setDateOffset, page, setPage, isMyClientsOnly, setIsMyClientsOnly, currentPage, setCurrentPage }}>
      {children}
    </CustomersContext.Provider>
  );
};

export default CustomersProvider;
 
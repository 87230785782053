


import { useEffect, useRef, useState } from 'react';
import { OptionsDropdown } from './Util';
import { ContainerItem, ContentItem, DropdownItem, DropdownMenu, HighlightedText, ItemAnnotation, ItemTitleName, StyledHiOutlineInformationCircle, TooltipContainer, TooltipText, TooltipWrapper, WrapperIcon } from './style';
import { HiDotsVertical } from 'react-icons/hi';
import { NotesModal } from 'components/NotesModal';
import { GenericOptionModal } from 'components/GenericOptionModal';
import { api } from 'config/api';
import useNotes from 'hooks/useNotes';
import { NotificationItemModal } from 'components/NotificationItemModal';
import useUsersTypeAccess from 'hooks/useUsersTypeAccess';
import useAuth from 'hooks/useAuth';

interface NotificationItemProps {
  id: string;
  name: string;
  annotation: string;
  active: boolean;
  dateNotification: string;
  nameCreatedBy?: string;
  emailCreatedBy?: string;
}

export const NotificationItem = ({ id, name, annotation, active, dateNotification, nameCreatedBy, emailCreatedBy }: NotificationItemProps) => {
  const [isConfirmFinishModalOpen, setIsConfirmFinishModalOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isModalNotificationOpen, setIsModalNotificationOpen] = useState(false);
  const [isNotificationItemModalOpen, setIsNotificationItemModalOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const { isManager } = useUsersTypeAccess();
  const { auth } = useAuth();

  const { notification, setNotification } = useNotes();

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleFinishNotification = async () => {
    try {
      const body = {
        id: id,
        active: false,
        isNotification: false,
      }

      await api.put(`/customer/annotation/${id}`, body);

    } catch (error) {
      console.log(error);
    } finally {

      const updatedList = notification.filter(item => item.id !== id);

      setNotification(updatedList);

      setTimeout(() => {
        setIsConfirmFinishModalOpen(false);
      }, 1000);
    }
  }

  const handleHiDotsVerticalClick = () => {
    setIsDropdownOpen(!isDropdownOpen);
  }

  const handleStopPropagation = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation();
  }

  const renderNotificationModal = () => {
    return (
      <NotesModal
        onCloseModal={() => setIsModalNotificationOpen(false)}
        id={id}
        customerName={name}
        annotation={annotation}
        dateNotification={new Date(dateNotification)} />
    )
  }

  const renderNotificationItemModal = () => {
    return (
      <NotificationItemModal
        onClose={() => setIsNotificationItemModalOpen(false)}
        name={name}
        annotation={annotation}
        isDateBeforeToday={isDateBeforeToday()} />
    )
  }

  const renderConfirmFinishNotificationModal = () => {
    return (
      <GenericOptionModal
        message='Tem certeza que deseja concluir notificação?'
        confirmationText='Confirmar'
        onClose={() => setIsConfirmFinishModalOpen(false)}
        onConfirmation={handleFinishNotification}
      />
    )
  }

  const handleDropdownClick = (value: string) => {
    if (value === 'Concluída') {
      setIsConfirmFinishModalOpen(true);
    } else if (value === 'Reagendar') {
      setIsModalNotificationOpen(true);
    }
  }

  // const isDateBeforeToday = (): boolean => {
  //   const today = new Date();
  //   const notificationDate = new Date(dateNotification);

  //   return notificationDate < today;
  // }

  const isDateBeforeToday = (): boolean => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const notificationDate = new Date(dateNotification);
    notificationDate.setHours(0, 0, 0, 0);

    return notificationDate < today;
  };


  return (
    <>
      <ContainerItem isOutOfDate={isDateBeforeToday()} onClick={() => setIsNotificationItemModalOpen(true)}>
        <ContentItem>
          <ItemTitleName isOutOfDate={isDateBeforeToday()}>
            {name}
          </ItemTitleName>
          <ItemAnnotation isOutOfDate={isDateBeforeToday()}>
            {annotation}
          </ItemAnnotation>
        </ContentItem>

        {(nameCreatedBy && isManager()) && (
          <TooltipWrapper onClick={(event) => { handleStopPropagation(event) }}>
            <StyledHiOutlineInformationCircle
              size={24}
              color="#6460AA"
            />
            <TooltipContainer>
              <TooltipText>Criado(a) por:</TooltipText>
              <HighlightedText> {nameCreatedBy}</HighlightedText>
            </TooltipContainer>
          </TooltipWrapper>
        )}

        {(!isManager() || (emailCreatedBy === auth?.username || '')) && (
          <WrapperIcon onClick={(event) => { handleStopPropagation(event); handleHiDotsVerticalClick(); }}>
            <HiDotsVertical cursor='pointer' color='#6D6E71' size={20} />
          </WrapperIcon>
        )}

        {isDropdownOpen && (
          <DropdownMenu ref={dropdownRef}>
            {OptionsDropdown.map((option) => (
              <DropdownItem key={option.text} onClick={(event) => { handleStopPropagation(event); handleDropdownClick(option.text); }}>{option.text}</DropdownItem>
            ))}
          </DropdownMenu>
        )}
      </ContainerItem>
      {isModalNotificationOpen && renderNotificationModal()}
      {isConfirmFinishModalOpen && renderConfirmFinishNotificationModal()}
      {isNotificationItemModalOpen && renderNotificationItemModal()}
    </>
  );
}

import { DropdownList, DropdownItem, Dimmer } from 'components/AddUserTypeAccessModal/styles';
import { TypeAccessOptions } from 'components/AddUserTypeAccessModal/util';
import { 
  ContainerModalOption, 
  TextTitleModal, 
  ContainerButton, 
  OptionCancelButton, 
  OptionButton, 
} from 'components/TableUsers/styles';
import { api } from 'config/api';
import useUsersTypeAccess from 'hooks/useUsersTypeAccess';
import { useWindowDimensions } from 'hooks/useWindowDimensions';
import { useEffect, useState } from 'react';
import { HiChevronDown, HiChevronUp } from 'react-icons/hi';
import { ClipLoader } from 'react-spinners';
import { UserTypeAccess } from 'types/UsersTypeAccessType';
import { Container, DropdownContainer } from './style';
import { SuccessModal } from 'components/SuccessModal';

export const TypeUserDropdownItem = ({ id, email, typeAccess, hierarchy }: UserTypeAccess) => {
  const [loading, setLoading] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isModalEditOpen, setIsModalEditOpen] = useState(false);
  const [isModalSuccessOpen, setIsModalSuccessOpen] = useState(false);
  const [typeAccessSelected, setTypeAccessSelected] = useState(typeAccess);

  const { getUsersTypeAccess } = useUsersTypeAccess();
  const { width } = useWindowDimensions();

  const isMobile = width < 768;

  useEffect(() => {
    const close = (e: any) => {
      if(e.keyCode === 27){
        setIsModalEditOpen(false);
      }
    }
    window.addEventListener('keydown', close)
    return () => window.removeEventListener('keydown', close)
  },[]);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  }

  const handleTypeAccessSelected = (option: string) => {
    setTypeAccessSelected(option)
    toggleDropdown();
    setIsModalEditOpen(true);
  }

  const handleEditUser = async () => {
    setLoading(true);
    try {
      const data = {
        email: email,
        typeAccess: typeAccessSelected,
        hierarchy
      }

      await api.put(`/user/typeAccess/${id}`, data);
    } catch (error) {
      console.log(error)
    } finally {
      setTimeout(() => {
        setLoading(false);
        setIsModalEditOpen(false)
        setIsModalSuccessOpen(true);
      }, 1000);

      setTimeout(() => {
        setIsModalSuccessOpen(false);
        getUsersTypeAccess();
      }, 2000);
    }
  }

  const renderModalOption = () => {
    return (
      <Dimmer onClick={() => setIsModalEditOpen(false)}>
        <ContainerModalOption onClick={(e:React.MouseEvent) => e.stopPropagation()}>
          <TextTitleModal>
            Tem certeza de que quer alterar o tipo de acesso deste usuário?
          </TextTitleModal>

          <ContainerButton>
            <OptionCancelButton onClick={() => setIsModalEditOpen(false)}>
              Cancelar
            </OptionCancelButton>
          
            <OptionButton onClick={handleEditUser}>
              {loading ? (
                <ClipLoader
                  color='#fff'
                  size={16}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                />
              ) : 'Alterar'}
            </OptionButton>
          </ContainerButton>
        </ContainerModalOption>
      </Dimmer>
    )
  }

  const renderContent = () => {
    return (
      <DropdownContainer>
        {typeAccess}

        {isDropdownOpen ? (
          <HiChevronUp cursor='pointer' color='#6D6E71' size={22} onClick={toggleDropdown} />
        ) : (
          <HiChevronDown cursor='pointer' color='#6D6E71' size={22} onClick={toggleDropdown} />
        )}

        {isDropdownOpen && (
          <DropdownList>
            {TypeAccessOptions.map((option) => {
              return <DropdownItem isSelected={option == typeAccess} key={option} onClick={() => handleTypeAccessSelected(option)}>{option}</DropdownItem>
            })}
          </DropdownList>
        )}

        {isModalEditOpen && renderModalOption()}

        {isModalSuccessOpen && <SuccessModal message='Usuário editado com sucesso' />}
      </DropdownContainer>
    )
  }

  return isMobile ? (
        <Container isMobile>
          {renderContent()}
        </Container>
      ) : renderContent()
}
import { Button } from 'components/Button';
import { MouseEvent, useEffect, useState } from 'react';
import { HiCheck, HiCheckCircle, HiChevronDown } from 'react-icons/hi';
import { 
  AnnotationContainer, 
  AnnotationHeader, 
  ButtonWrapper, 
  Cancel, 
  Checked, 
  CompanionContainer, 
  Container, 
  ControlContainer, 
  Dimmer, 
  Divider, 
  DropdownContainer, 
  DropdownPlaceholder, 
  Item, 
  Label, 
  List, 
  NoteBody, 
  Row, 
  SubTitle, 
  Text, 
  Title, 
  Unchecked 
} from './styles';
import { Toast } from 'components/Toast';
import { ClipLoader } from 'react-spinners';
import { api } from 'config/api';
import useAuth from 'hooks/useAuth';
import useCustomers from 'hooks/useCustomers';

interface ModalProps {
  onClose: () => void;
  customerDocument: string;
}

export function CheckInModal({ onClose, customerDocument }: ModalProps) {
  const [visit, setVisit] = useState(false);
  const [phone, setPhone] = useState(false);
  const [companion, setCompanion] = useState('Sem acompanhamento');
  const [isCompanionDropdownOpen, setIsCompanionDropdownOpen] = useState(false);
  const [annotation, setAnnotation] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);

  const { auth } = useAuth();
  const { getCustomers } = useCustomers();

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const close = async (e: any) => {
      if(e.keyCode === 27){
        onClose();
      }
    }

    window.addEventListener('keydown', close)

    return () => window.removeEventListener('keydown', close)
  },[]);

  useEffect(() => {
    if(error){
      setTimeout(() => {
        setError(false);
      }, 2000)
    }

    if(success){
      setTimeout(() => {
        setSuccess(false);

        onClose();

        getCustomers('');
      }, 2000)
    }
  }, [error, success]);

  const handleNewNote = (message: string) => {
    setAnnotation(message);
    
    if(message.length > 280){
      setAnnotation(message.slice(0, 280));
    }
  }

  const handleSubmit = async () => {
    try {
      setLoading(true);

      await api.put('/checkin', {
        document: customerDocument,
        lastVisit: visit ? new Date().toISOString() : '',
        lastCall: phone ? new Date().toISOString() : '',
        companion,
        annotation,
        checkedBy: auth?.username || '',
        checkedByName: auth?.name || ''
      });

      setSuccess(true);
    } catch (err) {
      setError(true);
    } finally {
      setLoading(false);
    }
  }

  if (error) {
    return (
      <Toast 
        type='error'
        text='Falha no check-in.' 
        secondaryText='Tente novamente.'
        closeable
      />
    )
  }

  return (
    <Dimmer onClick={onClose}>
      <Container onClick={(e: MouseEvent) => e.stopPropagation()}>
        <Title>Selecione o tipo do check-in</Title>

        <Row>
          {!visit ? <Unchecked onClick={() => setVisit(true)} /> : (
            <Checked onClick={() => setVisit(false)}>
              <HiCheck size={14} color='#fff' />
            </Checked>
          )}

          <Label onClick={() => setVisit(!visit)}>Visita ao consultório</Label>
        </Row>

        <Row>
          {!phone ? <Unchecked onClick={() => setPhone(true)} /> : (
            <Checked onClick={() => setPhone(false)}>
              <HiCheck size={14} color='#fff' />
            </Checked>
          )}

          <Label onClick={() => setPhone(!phone)}>Ligação/Whats</Label>
        </Row>

        {visit && (
          <>
            <SubTitle>Acompanhamento de visita</SubTitle>

            <CompanionContainer onClick={() => setIsCompanionDropdownOpen(!isCompanionDropdownOpen)}>
              <DropdownPlaceholder>{companion}</DropdownPlaceholder>

              <HiChevronDown size={18} color='#B9B9BB' />

              {isCompanionDropdownOpen && (
                <DropdownContainer>
                  <List>
                    <Item onClick={() => setCompanion('Sem acompanhamento')}>Sem acompanhamento</Item>
                    <Item onClick={() => setCompanion('Gerente regional')}>Gerente regional</Item>
                    <Item onClick={() => setCompanion('Outros')}>Outros</Item>
                  </List>
                </DropdownContainer>
              )}
            </CompanionContainer>
          </>
        )}

        <AnnotationContainer>
          <AnnotationHeader>
            <SubTitle>Anotações</SubTitle>

            <Text>{annotation.length}/280</Text>
          </AnnotationHeader>

          <Divider />

          <NoteBody 
            onChange={(e) => !loading ? handleNewNote(e.target.value) : null}
            value={annotation}
          />
        </AnnotationContainer>

        <ControlContainer>
          <Cancel onClick={onClose}>Cancelar</Cancel>

          <ButtonWrapper>
            <Button onClick={handleSubmit} disabled={!visit && !phone}>
              {loading ? (
                <ClipLoader
                  color='#fff'
                  size={16}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                />
              ) : success ? (
                <>
                  <span style={{ marginRight: '10px' }}>Feito</span> <HiCheckCircle color='#00E13F' size={16}/>
                </>
              ) : 'Check-in' }
            </Button>
          </ButtonWrapper>
        </ControlContainer>
      </Container>
    </Dimmer>
  )
}

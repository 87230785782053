import styled from 'styled-components';
import { Input } from 'components/Input';
import { Link } from 'react-router-dom';
import { HiLockClosed } from 'react-icons/hi';

export const SearchContainer = styled.div`
  margin-left: 200px;
  width: calc(100% - 200px);
  display: flex;
  flex-direction: column;
  top: 0;
  background: var(--background);
  position: fixed;
  z-index: 3;
  border: 1px solid var(--dividerColor);
`;

export const Title = styled.h1`
  font-weight: 600;
  font-size: 16px;
  color: var(--primaryTextColor);
  line-height: 150%;
  margin-bottom: 8px;
`;

export const MainContainer = styled.main`
  display: flex;
  flex-direction: column;
  padding: 34px 56px;
  margin-left: 200px;
  margin-top: 158px;
  background-color: var(--detailsBackground);
  min-height: calc(100vh - 250px);
`;

export const SearchContent = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 24px 56px;
`;

export const LinkWrapper = styled(Link)`
  display: inline-flex;
  align-items: center;
`;

export const ContainerInput = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
`;

export const StyledInput = styled(Input)`
  min-width: 600px;
`;

export const GoBackLink = styled.span`
  font-family: 'Roboto', sans-serif;
  font-weight: 600;
  text-decoration: underline;
  color: var(--highlightColor);
`;

export const DropdownContainer = styled.div`
  min-width: 660px; /* Define a largura como metade da largura da tela */
  background-color: var(--background);
  box-shadow: 0px 4px 12px rgba(146, 140, 151, 0.25);
  border-radius: 4px;
  position: absolute;
  top: 60px;
`;

export const ContainerCustomersList = styled.div`
`;

export const CustomersList = styled.ul`
  display: flex;
  flex-direction: column;
`;

export const CustomerItem = styled.li`
  padding: 13px 16px;
  color: var(--primaryTextColor);
  cursor: pointer;

  &:hover {
    background-color: var(--searchBackground);
  }
`;

export const CustomerInfo = styled.span`
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
`;

export const Empty = styled.div`
  width: 100px;
  height: 1px;
`;

export const TabsContainer = styled.div`
  height: 60px;
  display: flex;
  justify-content: flex-start;
  border-bottom: 1px solid var(--dividerColor);
`;

export const Tab = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: 180px;
`;

export const TabTitle = styled.div`
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: var(--secondaryTextColor);
`;

export const TabSelected = styled.div`
  height: 2px;
  background: var(--highlightColor);
  width: 100%;
`;

export const StyledImage = styled.img`
  height: 313px;
  align-self: flex-end;
  margin-right:44px;
`;

export const InfoText = styled.div`
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  line-height: 150%;
  color: var(--primaryTextColor);
`;

export const DisabledInfoText = styled(InfoText)`
  color: var(--tertiaryTextColor);
`;

export const TooltipContainer = styled.div`
  visibility: hidden;
  position: absolute;
  color: var(--primaryTextColor); 
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  line-height: 150%;

  &:before {
    content: "";
    width: 0;
    height: 0;
    right: 10px;
    top: 62px;
    position: absolute;
    border: 10px solid transparent;
    transform: rotate(315deg);
    transition: border 0.4s ease-in-out;
  }

  /* Tablet */
  @media (min-width:768px) {
    &:before {
      content: "";
      width: 0;
      height: 0;
      right: 23px;
      top: 62px;
      position: absolute;
      border: 10px solid transparent;
      transform: rotate(315deg);
      transition: border 0.4s ease-in-out;
    }  
  }
`;

export const StyledHiLockClosed = styled(HiLockClosed)`
  cursor: pointer;
`;

export const TooltipWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: relative;

  & ${StyledHiLockClosed}:hover + ${TooltipContainer} {
    visibility: visible;
    background: var(--searchBackground);
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.1);
    padding: 16px;
    width: 150px;
    border-radius: 8px;
    margin-top: -89px;
    margin-right: -10px;

    /* Tablet */
    @media (min-width:768px) {
      margin-right: -20px;
    }
    
    &:before {
      border-color: transparent transparent var(--searchBackground) var(--searchBackground);
    }
  }
`;

export const TooltipText = styled.span``;

export const HighlightedText = styled.span`
  font-weight: bold;
`;
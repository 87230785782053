import { Note } from 'components/Note';
import AddNoteImage from 'assets/pana.svg'
import {
  Column,
  EmptyNotesContainer,
  EmptyProfileContainer,
  Message,
  NotesContainer,
  NotesHeader,
  NotesWrapper,
  ProfileContainer,
  ProfileContent,
  ProfileHeader,
  ProfileInterests,
  ProfileMessage,
  ProfileTitle,
  ScrollableList,
  StyledButton,
  StyledFiEdit,
  StyledImage,
} from './styles';
import { HiOutlinePlus, HiPlus } from 'react-icons/hi';
import { NotesProps } from 'types/NotesProps';
import { useEffect, useState } from 'react';
import { ProfileModal } from 'components/ProfileModal';
import { Chip } from 'components/Chip';

export const Notes = ({ note, noteDeprecated, email, onClickSetShowNotesModal, profile }: NotesProps) => {
  const [isProfileModalOpen, setIsProfileModalOpen] = useState(false);

  const renderProfile = () => {
    return profile && profile?.customer === email ? (
      <ProfileContainer>
        <ProfileHeader>
          <ProfileTitle>Perfil do cliente</ProfileTitle>

          <StyledFiEdit
            size={20}
            color="#6460AA"
            onClick={() => setIsProfileModalOpen(true)}
          />
        </ProfileHeader>

        <ProfileContent>
          {profile?.profile}
        </ProfileContent>

        <ProfileTitle>Resumo de interesses</ProfileTitle>

        <ProfileInterests>
          {profile?.interests.map((interest: any) => {
            return (
              <Chip
                key={interest}
                text={interest}
                removeable={true}
              />
            )
          })}
        </ProfileInterests>
      </ProfileContainer>
    ) : (
      <EmptyProfileContainer>
        <ProfileMessage>Ainda não há informações <br /> de perfil para este cliente</ProfileMessage>

        <StyledButton onClick={() => setIsProfileModalOpen(true)} style={{ marginBottom: 0 }}><HiPlus size={20} color='#fff' />Criar perfil</StyledButton>
      </EmptyProfileContainer>
    )
  }

  if (isProfileModalOpen) {
    return (
      <ProfileModal
        email={email}
        onCloseModal={() => setIsProfileModalOpen(false)}
        currentProfile={profile}
      />
    )
  }

  const reverseHistoryArray = () => {
    const reversedArray = [];

    for (let i = noteDeprecated?.history?.length - 1; i >= 0; i--) {
      reversedArray.push(noteDeprecated.history[i]);
    }

    return reversedArray
  }

  return note.length > 0 || (Object.keys(noteDeprecated).length !== 0) ? (
    <NotesWrapper>
      <NotesContainer>
        <NotesHeader>Anotações recentes</NotesHeader>

        <StyledButton onClick={onClickSetShowNotesModal}>
          <HiOutlinePlus size={24} /> Adicionar
        </StyledButton>

        <ScrollableList>

          {note.map((n, index) => {
            return (
              <Note key={index} id={n.id} annotation={n.annotation} name={n.consultantName} customerName={n.customerName} customerDocument={n.document} createAt={n.createdAt} isNotification={n.isNotification} />
            )
          })}

          {noteDeprecated && noteDeprecated.annotation && <Note annotation={noteDeprecated.annotation} name={noteDeprecated.madeBy} createAt={noteDeprecated.createAt} />}

          {reverseHistoryArray().map((n, index) => {
            return (
              <Note key={index} annotation={n.annotation} name={n.madeBy} createAt={n.createAt} />
            )
          })}

        </ScrollableList>
      </NotesContainer>

      {renderProfile()}
    </NotesWrapper>
  ) : (
    <NotesWrapper>
      <EmptyNotesContainer>
        <Column>
          <Message>Ainda não há anotações</Message>

          <StyledButton onClick={onClickSetShowNotesModal}>
            <HiOutlinePlus size={24} /> Adicionar
          </StyledButton>
        </Column>

        <StyledImage src={AddNoteImage} />
      </EmptyNotesContainer>

      {renderProfile()}
    </NotesWrapper>
  )
}
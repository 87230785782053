/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMsal } from '@azure/msal-react';
import useUsersTypeAccess from 'hooks/useUsersTypeAccess';
import { useWindowDimensions } from 'hooks/useWindowDimensions';
import { useEffect, useState } from 'react';
import { HiOutlineLogout, HiOutlineMenu, HiOutlineX } from 'react-icons/hi';
import { NavLink, useLocation } from 'react-router-dom';
import { HiBell } from 'react-icons/hi2';
import { Account } from 'types/Account';
import {
  BadgeCircle,
  BadgeCount,
  BadgeWrapper,
  Container,
  ContainerItemsHeaderMobileOrTablet,
  Divider,
  ExternalLink,
  Greeting,
  GreetingContainer,
  ItemsMenuContainer,
  ItemsMenuText,
  MenuContainer,
  MenuItem,
  NotificationWrapper,
  StyledLink,
  ToggleIcon,
  UserName
} from './styles';
import useNotes from 'hooks/useNotes';
import { ClipLoader } from 'react-spinners';

export const Header = () => {
  const [account, setAccount] = useState<Account>({} as Account);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [activeMenuItem, setActiveMenuItem] = useState('');
  const location = useLocation();
  const [currentPath, setCurrentPath] = useState(location.pathname);

  const { getNotification, loading, getNotificationLength } = useNotes();


  const { isAdmin, isManager } = useUsersTypeAccess();
  const { setUsername } = useNotes();
  const { instance }: any = useMsal();
  const { width } = useWindowDimensions();

  const handleLogout = () => {
    instance.logoutRedirect();
  }

  const handleMenuItemClick = (menuItem: string) => {
    setActiveMenuItem(menuItem);
    setIsMenuOpen(false);
  }

  const isDesktop = width > 1025;

  useEffect(() => {
    const accountInstance = instance.getActiveAccount();

    if (accountInstance) {
      setAccount(accountInstance);
      setUsername(accountInstance.name);
    }

    setCurrentPath(location.pathname);

    if (currentPath === '/') {
      setActiveMenuItem('Página Inicial');
    } else if (currentPath === '/user') {
      setActiveMenuItem('Usuários');
    } else if (currentPath === '/notification') {
      setActiveMenuItem('Notificações');
    } else if (currentPath === '/report') {
      setActiveMenuItem('Relatórios');
    }
  }, [location.pathname]);

  useEffect(() => {
    getNotification();
  }, []);

  const renderDesktop = () => {
    return (
      <>
        <GreetingContainer>
          <Greeting>Olá,</Greeting>

          <UserName>{account.name}</UserName>
        </GreetingContainer>

        <Divider />

        <StyledLink to='/'>
          <ItemsMenuContainer>
            <ItemsMenuText isActive={activeMenuItem === 'Página Inicial'}>Página Inicial</ItemsMenuText>
          </ItemsMenuContainer>
        </StyledLink>

        <StyledLink to='/notification'>
          <ItemsMenuContainer>
            <ItemsMenuText isActive={activeMenuItem === 'Notificações'}>Notificações</ItemsMenuText>
            {NotificationBadge(getNotificationLength())}
          </ItemsMenuContainer>
        </StyledLink>

        {isAdmin() && (

          <StyledLink to='/user'>
            <ItemsMenuContainer>
              <ItemsMenuText isActive={activeMenuItem === 'Usuários'}>Usuários</ItemsMenuText>
            </ItemsMenuContainer>
          </StyledLink>

        )}

        {(isAdmin() || isManager()) && (isDesktop) && (

          <StyledLink to='/report'>
            <ItemsMenuContainer>
              <ItemsMenuText isActive={activeMenuItem === 'Relatórios'}>Relatórios</ItemsMenuText>
            </ItemsMenuContainer>
          </StyledLink>

        )}

        <ExternalLink href='https://skinstore.atlassian.net/servicedesk/customer/portal/5/group/40' target='_blank'>
          <ItemsMenuContainer>
            <ItemsMenuText>
              Suporte MedSearch
            </ItemsMenuText>
          </ItemsMenuContainer>
        </ExternalLink>

        <ItemsMenuContainer onClick={() => handleLogout()}>
          <ItemsMenuText>Sair</ItemsMenuText>

          <HiOutlineLogout size={24} />
        </ItemsMenuContainer>
      </>
    )
  }

  const NotificationBadge = (count: number) => {
    return (
      <BadgeWrapper>
        <BadgeCircle>
          <BadgeCount>{count}</BadgeCount>
        </BadgeCircle>
      </BadgeWrapper>
    );
  };

  const renderMobileOrTablet = () => {
    return (
      <>
        <ContainerItemsHeaderMobileOrTablet>
          <GreetingContainer>
            <Greeting>Olá,</Greeting>

            <UserName>{account.name}</UserName>
          </GreetingContainer>

          <NotificationWrapper >
            <NavLink to='/notification'>
              {loading ? (
                <ClipLoader
                  color='#fff'
                  size={16}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                />
              ) :
                <>
                  <HiBell color="white" size={22} />
                  {NotificationBadge(getNotificationLength())}
                </>
              }
            </NavLink>

            <ToggleIcon isOpen={isMenuOpen} onClick={() => setIsMenuOpen(!isMenuOpen)}>
              {isMenuOpen ? <HiOutlineX size={20} /> : <HiOutlineMenu size={20} />}
            </ToggleIcon>

          </NotificationWrapper>


        </ContainerItemsHeaderMobileOrTablet>
      </>
    )
  }

  return (
    <Container>
      {isDesktop ?
        renderDesktop() :
        <>
          {renderMobileOrTablet()}

          {isMenuOpen ? (
            <MenuContainer isOpen>
              <StyledLink to='/'>
                <MenuItem
                  onClick={() => handleMenuItemClick('Página Inicial')}
                  borderBottom
                  isActive={activeMenuItem === 'Página Inicial'}
                >
                  Página Inicial
                </MenuItem>
              </StyledLink>

              <StyledLink to='/notification'>
                <MenuItem
                  onClick={() => handleMenuItemClick('Notificações')}
                  borderBottom
                  isActive={activeMenuItem === 'Notificações'}
                >
                  Notificações
                </MenuItem>
              </StyledLink>

              {isAdmin() && (
                <StyledLink to='/user'>
                  <MenuItem
                    onClick={() => handleMenuItemClick('Usuários')}
                    borderBottom
                    isActive={activeMenuItem === 'Usuários'}
                  >
                    Usuários
                  </MenuItem>
                </StyledLink>
              )}

              <ExternalLink href='https://skinstore.atlassian.net/servicedesk/customer/portal/5/group/40' target='_blank'>
                <MenuItem borderBottom>
                  Suporte MedSearch
                </MenuItem>
              </ExternalLink>

              <MenuItem onClick={() => handleLogout()}>
                Sair <HiOutlineLogout size={20} />
              </MenuItem>
            </MenuContainer>

          ) : <MenuContainer />}
        </>
      }

    </Container>
  )
}

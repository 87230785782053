import styled from 'styled-components';

export const Container = styled.main`
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  
  /* Tablet */
  @media (min-width:768px) {
    
  }
  
  /* Desktop */
  @media (min-width:1025px) {
    margin-left: 200px;
    width: calc(100% - 200px);  
  }
`;

export const ContainerLogo = styled.div`
  background-color: var(--background);
  height: 128px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Logo = styled.img`

`;

export const HomeContainer = styled.section`
  background-color: var(--searchBackground);
  
  /* Tablet */
  @media (min-width:768px) {
    
  }
  
  /* Desktop */
  @media (min-width:1025px) {
    padding: 40px 56px;
  }
`;

export const ContentContainer = styled.div`
  background-color: var(--background);
  border-radius: 4px;
  padding: 16px;
  
  /* Tablet */
  @media (min-width:768px) {
    
  }
  
  /* Desktop */
  @media (min-width:1025px) {
    padding: 24px;
    max-width: calc(100vw - 200px);
  }
`;

export const SearchContainer = styled.div`
  margin: 0 -16px;
  margin-top: 56px;
  padding: 24px 16px;
  background-color: var(--searchBackground);

  /* Tablet */
  @media (min-width:768px) {
    padding: 28px 30px;    
  }
  
  /* Desktop */
  @media (min-width:1025px) {
    margin: 0;
    margin-bottom: 44px;
    padding: 0;
    background-color: var(--background);
  }
`;

export const SearchTitle = styled.span`
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  color: var(--primaryTextColor);
  margin-bottom: 8px;
  display: block;

  /* Tablet */
  @media (min-width:768px) {
    font-size: 20px;
  }
  
  /* Desktop */
  @media (min-width:1025px) {
    font-size: 16px;
  }
`;

export const SearchInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  /* Tablet */
  @media (min-width:768px) {
    
  }
  
  /* Desktop */
  @media (min-width:1025px) {
    flex-direction: row;
  }
`;

export const Row = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 24px;
`;

export const InputWrapper = styled.div`
  width: 100%;
  margin-bottom: 12px;

  /* Tablet */
  @media (min-width:768px) {
    
  }
  
  /* Desktop */
  @media (min-width:1025px) {
    width: 640px;
    margin-bottom: 0;
  }
`;

export const TogglerContainer = styled.div`
  display: flex;
  
  /* Tablet */
  @media (min-width:768px) {
    
  }
  
  /* Desktop */
  @media (min-width:1025px) {
    align-items: center;
    justify-content: center;
    margin-left: 40px;
  }
`;

export const ToggleLabel = styled.label`
  font-weight: 400;
  font-size: 14px;
  color: var(--primaryTextColor);
  margin-left: 14px;
  cursor: pointer;
`;

export const FilterWrapper = styled.div`
  width: 340px;

  /* Tablet */
  @media (min-width:768px) {
    
  }
  
  /* Desktop */
  @media (min-width:1025px) {
    
  }
`;

export const VersionContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--background);
  padding-bottom: 20px;
`;

export const Version = styled.span`
  font-size: 14px;
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 400;
  color: #6D6E71;
`;

export const MicContainer = styled.div`
  padding: 8px;
  background: #4A4785;
  border-radius: 50%;
  width: 54px;
  height: 54px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: fixed;
  z-index: 100000;
  bottom: 10px;
  right: 10px;
`;
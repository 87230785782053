import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import mobile from './styles/themes/mobile'
import { GlobalStyle } from 'styles/global';
import App from './App';
import AuthProvider from './contexts/AuthContext';
import { msalInstance } from 'config/msalInstance';
import CustomersProvider from 'contexts/CustomersContext';
import NotesProvider from 'contexts/NotesContext';
import UsersTypeAccessProvider from 'contexts/UsersTypeAccessContext';
import CheckInProvider from 'contexts/CheckInContext/Index';
import ToastProvider from 'contexts/ToastContext';

const container = document.getElementById('root');
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);

root.render(
  <React.StrictMode>
    <Router>
      <AuthProvider>
        <UsersTypeAccessProvider>
          <CheckInProvider>
            <NotesProvider>
              <CustomersProvider>
                <ThemeProvider theme={mobile}>
                  <ToastProvider>
                    <GlobalStyle />
                    <App pca={msalInstance} />
                  </ToastProvider>
                </ThemeProvider>
              </CustomersProvider>
            </NotesProvider>
          </CheckInProvider>
        </UsersTypeAccessProvider>
      </AuthProvider>
    </Router>
  </React.StrictMode>
);


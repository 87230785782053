import { Dimmer } from 'components/AddUserTypeAccessModal/styles';
import { ErrorScreen } from 'components/ErrorScreen';
import { Header } from 'components/Header';
import { Empty } from 'components/Modal/styles';
import { Pagination } from 'components/Pagination';
import { ShowMoreUsers } from 'components/ShowMoreUsers';
import { 
  HighlightedText, 
  StyledHiOutlineInformationCircle, 
  TooltipContainer, 
  TooltipText, 
  TooltipWrapper 
} from 'components/Tooltip/styles';
import { TypeUserDropdownItem } from 'components/TypeUserDropdownItem';
import { api } from 'config/api';
import useAuth from 'hooks/useAuth';
import useUsersTypeAccess from 'hooks/useUsersTypeAccess';
import { useWindowDimensions } from 'hooks/useWindowDimensions';
import { useEffect, useState } from 'react';
import { ClipLoader } from 'react-spinners';
import { UserTypeAccess } from 'types/UsersTypeAccessType';
import { 
  ContainerButton, 
  ContainerModalOption, 
  OptionButton, 
  OptionCancelButton, 
  StyledHiTrash, 
  Table, 
  TableCell, 
  TableCellTitle, 
  TableRow, 
  TableWrapper, 
  TextTitleModal, 
} from './styles';
import { TooltipMessageUtil } from './util';
import { SuccessModal } from 'components/SuccessModal';

interface Props {
  userList: UserTypeAccess[];
  isReportPage?: boolean;
}

export const TableUsers = ({ userList, isReportPage }: Props) => {
  const [loading, setLoading] = useState(false);
  const [isModalSuccessOpen, setIsModalSuccessOpen] = useState(false);
  const [isModalDeleteOpen, setIsModalDeleteOpen] = useState(false);
  const [id, setId] = useState('');

  const { error, usersToShow, getUsersTypeAccess, isAdmin, usersTypeAccess, setUsersToShow } = useUsersTypeAccess();
  const { width } = useWindowDimensions();
  const { auth } = useAuth();
  
  const isMobile = width < 768;

  useEffect(() => {
    const close = (e: any) => {
      if(e.keyCode === 27){
        setIsModalDeleteOpen(false);
      }
    }
    window.addEventListener('keydown', close)
    return () => window.removeEventListener('keydown', close)
  },[]);

  const handleDeleteUser = async () => {
    try {
      setLoading(true);

      await api.delete(`/user/typeAccess/${id}`);
    } catch (error) {
      console.log(error)
    } finally {
      setTimeout(() => {
        setLoading(false);
        setIsModalDeleteOpen(false)
        setIsModalSuccessOpen(true);
      }, 1000);

      setTimeout(() => {
        setIsModalSuccessOpen(false);
        getUsersTypeAccess();
      }, 2000);
    }
  }

  const handleModalOptionOpen = (id: string) => {
    setIsModalDeleteOpen(true);
    setId(id)
  };

  if (error) {
    return (
      <>
        <Header />
        <ErrorScreen
          title="Algo deu errado..."
          message="Mas logo será resolvido."
          status={500} />
      </>
    )
  }

  const renderModalOption = () => {
    return (
      <Dimmer onClick={() => setIsModalDeleteOpen(false)}>
        <ContainerModalOption onClick={(e:React.MouseEvent) => e.stopPropagation()}>
          <TextTitleModal>
            Tem certeza de que quer excluir este usuário?
          </TextTitleModal>

          <ContainerButton>
            <OptionCancelButton onClick={() => setIsModalDeleteOpen(false)}>
              Cancelar
            </OptionCancelButton>
          
            <OptionButton onClick={handleDeleteUser}>
              {loading ? (
                <ClipLoader
                  color='#fff'
                  size={16}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                />
              ) : 'Excluir'}
            </OptionButton>
          </ContainerButton>

        </ContainerModalOption>
      </Dimmer>
    )
  }

  const checkForYourself = (email: string) => {
    return email === auth?.username
  }

  return (
    <>
      <TableWrapper>
        <Table>
          <thead>
            <TableRow>
              <TableCellTitle flex>
                {isAdmin() ? 'Usuário' : 'Usuários na sua hierarquia'}

                {isMobile && isAdmin() && (
                  <TooltipWrapper withoutAlignment>
                    <StyledHiOutlineInformationCircle withoutMargin size={24} color="#6460AA" />
                    
                    <TooltipContainer>
                      {TooltipMessageUtil.map(({ title, text }) => {
                        return (
                          <>
                            <HighlightedText>{title}: </HighlightedText>
                            
                            <TooltipText>
                              {text}
                              <br />
                            </TooltipText>
                          </>
                        )
                      })}
                    </TooltipContainer>
                  </TooltipWrapper>
                )}
              </TableCellTitle>

              {!isMobile && isAdmin() && !isReportPage && (
                <>
                  <TableCellTitle>Hierarquia</TableCellTitle>

                  <TableCellTitle alignRight>Tipo de Acesso</TableCellTitle>

                  <TableCell paddingRight>
                    <TooltipWrapper withoutAlignment>
                      <StyledHiOutlineInformationCircle withoutMargin size={24} color="#6460AA" />
                      <TooltipContainer>
                        {TooltipMessageUtil.map(({ title, text }) => {
                          return (
                            <>
                              <HighlightedText>{title}: </HighlightedText>
                              
                              <TooltipText>
                                {text}
                                <br />
                              </TooltipText>
                            </>
                          )
                        })}
                      </TooltipContainer>
                    </TooltipWrapper>
                  </TableCell>
                </>
              )}

              <TableCell />
            </TableRow>
          </thead>
          
          <tbody>
            {usersToShow.map(({ id, email, typeAccess, hierarchy }) => {
              return (
                <TableRow key={id}>
                  {isMobile ? (
                    <>
                      <TableCell column style={{ paddingLeft: '12px' }}>
                        <span>{email}</span>
                        {isAdmin() && !checkForYourself(email) && (
                          <div style={{ marginTop: '8px' }}>
                            <TypeUserDropdownItem id={id} email={email} typeAccess={typeAccess} hierarchy={hierarchy} />
                            
                            <TableCell>Hierarquia: {hierarchy}</TableCell>
                          </div>
                          )}
                      </TableCell>

                      {isAdmin() && !checkForYourself(email) && (
                        <TableCell alignRight>
                          <StyledHiTrash onClick={() => handleModalOptionOpen(id)} color='#6460AA' size={20} />
                        </TableCell>
                      )}
                    </>
                  ) : (
                    <>
                      <TableCell style={{ paddingLeft: '12px' }}>{email}</TableCell>

                      {isAdmin() && !isReportPage &&  <TableCell>{hierarchy}</TableCell>}

                      {isAdmin() && !isReportPage && !checkForYourself(email) ? (
                        <>
                          <TableCell relative alignRight>
                            <TypeUserDropdownItem id={id} email={email} typeAccess={typeAccess} hierarchy={hierarchy} />
                          </TableCell>
                          
                          <TableCell alignRight>
                            <StyledHiTrash onClick={() => handleModalOptionOpen(id)} color='#6460AA' size={20} />
                          </TableCell>
                        </>
                      ) : (
                        <Empty />
                      )}
                    </>
                  )}
                </TableRow>
              )
            })}
          </tbody>
        </Table>
      </TableWrapper>

      {isMobile ? (
        <ShowMoreUsers 
          pageSize={7} 
          usersTypeAccess={usersTypeAccess} 
          setUsersToShow={setUsersToShow} 
        /> 
      ) : (
        <Pagination 
          isCheckIn={false}
          lastPage={Math.ceil(userList.length / 7)} 
          pageSize={7} 
          usersTypeAccess={usersTypeAccess} 
          setUsersToShow={setUsersToShow}
        />
      )}

      {isModalDeleteOpen && renderModalOption()}
      {isModalSuccessOpen && <SuccessModal message='Usuário excluído com sucesso' />}
    </>
  );
}
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-top: 30px;
  margin-bottom: 30px;
`;

export const RadioInput = styled.input.attrs({ type: 'radio' })`
  appearance: none;
  cursor: pointer;
  width: 16px;
  height: 16px;
  border: 2px solid #6460AA;
  border-radius: 50%;
  margin-right: 8px;
  outline: none;
  
  &:checked {
    background-color: #6460AA;
  }
`;

export const Label = styled.label`
  font-family: 'Roboto';
  cursor: pointer;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 150%;
  color: #6460AA;
  display: flex;
  align-items: center;
  text-decoration: underline;
`;
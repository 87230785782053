/* eslint-disable @typescript-eslint/no-explicit-any */
import { api } from 'config/api';
import { differenceInDays, format, parseISO } from 'date-fns'

export const calculateDifferenceInDays = (date: string) => {
  return differenceInDays(new Date(), new Date(date))
}

export const getNormalizedDate = (date: string, withTime?: boolean) => {
  const formattedDate = format(parseISO(date), 'yyyy-MM-dd')
  
  const [year, month, day] = formattedDate.split('-');
  const months = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'];

  return withTime ? `${format(parseISO(date), 'dd/MM/yyyy')} às ${format(parseISO(date), 'HH:mm')}` : `${day} ${months[Number(month) - 1]} ${year}`;
}

export const phoneMask = (value: string) => {
  return value
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '($1) $2')
    .replace(/(\d{4})(\d)/, '$1-$2')
    .replace(/(\d{4})-(\d)(\d{4})/, '$1$2-$3')
    .replace(/(-\d{4})\d+?$/, '$1');
}

export const formatCPF = (value: string) => {
  return value
    .replace(/\D/g, '')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d{1,2})/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1');
}

export const formatCNPJ = (value: string) => {
  return value
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1/$2')
    .replace(/(\d{4})(\d)/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1');
}

export const sanitizeString = (str: string) => {
  if (str.includes('/') || str.includes('.') || str.includes('-')) {
    return str.replace(/[/.-]/g, '');
  }

  return str;
}

export const getUsersAllowed = async () => {
  try {
    const response = await api.get('user/typeAccess');
    
    return response.data;
  } catch (err) {
    console.log(err)
  } finally {
    console.log('finnaly')
  }
}

export const handleName = (name: string) => {
  if (name) {
    const names = name.split(' ');
    const firstName = names[0];
    const lastName = names[names.length - 1];
  
    return names.length > 1 ? `${firstName} ${lastName}` : firstName;
  }

  return ''
}

export const capitalize = (str: string) => {
  const words = str.split(' ');

  return words.map(word => `${word.charAt(0).toUpperCase()}${word.slice(1)}`).join(' ');
}

export const truncate = (str: string, limit = 17) => {
  return str.length > limit ? `${str.substring(0, limit)}...` : str;
}
import { HiX } from 'react-icons/hi';
import styled, { keyframes } from 'styled-components';

const rightToLeft = keyframes`
  0%  { right: -500px; top:0px; }
  50% { right:0px; top:0px; }
`;

const topToBottom = keyframes`
  0%  { top: -500px; }
  50% { top:0px; }
`;

export const Container = styled.div<{ type: 'success' | 'info' | 'alert' | 'error' }>`
  animation-name: ${topToBottom};
  animation-duration: 2s;
  width: calc(100vw - 75px);
  border-radius: 8px;
  background-color: ${({ type }) => type === 'success' ?  '#EDFBD8' : type === 'info' ? '#D7F1FD' : type === 'alert' ? '#FEF7D1' : '#FFF4F4'};
  padding: 13px 17px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: 'Source Sans Pro';
  position: fixed;
  top: 0;
  right: 0;
  z-index: 100;
  margin-right: 20px;
  margin-top: 20px;

  /* Tablet */
  @media (min-width:768px) {
    width: calc(350px - 34px);
    animation-name: ${rightToLeft};
  }
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 9px;
`;

export const Title = styled.span<{ type: 'success' | 'info' | 'alert' | 'error' }>`
  /* width: 300px; */
  font-weight: 600;
  font-size: 14px;
  color: ${({ type }) => type === 'success' ?  '#2B641E' : type === 'info' ? '#0C2A75' : type === 'alert' ? '#9C5E00' : '#BA1600'};
`;

export const Message = styled.span<{ type: 'success' | 'info' | 'alert' | 'error' }>`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  margin-top: 8px;
  color: ${({ type }) => type === 'success' ?  '#2B641E' : type === 'info' ? '#0C2A75' : type === 'alert' ? '#9C5E00' : '#BA1600'};
`;

export const Empty = styled.div`

`;

export const StyledHiX = styled(HiX)`
  cursor: pointer;
`;
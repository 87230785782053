import { HiOutlineDocumentDuplicate } from 'react-icons/hi';
import styled from 'styled-components';

export const Container = styled.div`
  margin-bottom: 32px;
  cursor: pointer;
  z-index: 1;
`;

export const Date = styled.span`
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  color: var(--primaryTextColor);
  display: block;
  margin-bottom: 8px;
`;

export const ItemCard = styled.div`
  border: 1px solid #F5F5F5;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 16px;
  background-color: var(--background);

  /* Tablet */
  @media (min-width:768px) {
    padding: 24px 32px;
  }

  /* Desktop */
  @media (min-width:1025px) {

  }
`;

export const ItemCardHeader = styled.div`
  border-bottom: 1px solid #ECECEC;
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;

  /* Tablet */
  @media (min-width:768px) {
    justify-content: flex-start;
  }

  /* Desktop */
  @media (min-width:1025px) {
    justify-content: space-between;
  }
`;

export const DescriptionRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  /* Desktop */
  @media (min-width:1025px) {
    justify-content: space-between;
  }
`;

export const CodeRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;

  /* Tablet */
  @media (min-width:768px) {
    
  }

  /* Desktop */
  @media (min-width:1025px) {
    justify-content: space-between;
  }
`;

export const SaleRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
`;

export const ItemCode = styled.span`
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: var(--primaryTextColor);
`;

export const TrackingCode = styled.span`
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  color: var(--primaryTextColor);
  text-decoration: underline;
`;

export const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  
  justify-content: space-between;
  margin-top: 12px;
`;

export const TitleWrapper = styled.div`
  display: flex;
`;

export const ItemText = styled.span`
  margin-left: 8px;
  font-size: 16px;
  color: var(--secondaryTextColor);
`;

export const MoreItems = styled.span`
  display: block;
  margin-top: 12px;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  color: var(--secondaryTextColor);
`;

export const StyledHiOutlineDocumentDuplicate = styled(HiOutlineDocumentDuplicate)`
  z-index: 2;
  
 /* Tablet */
 @media (min-width:768px) {
    margin-left: 36px;
  }

  /* Desktop */
  @media (min-width:1025px) {
    cursor: pointer;
  }
`;

export const TotalSaleText = styled.span`
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: var(--primaryTextColor);
  max-width: 100px;

  /* Tablet */
  @media (min-width:768px) {
    max-width: 100%;
  }
`;

export const TotalSaleValue = styled.span`
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: var(--primaryTextColor);

  /* Tablet */
  @media (min-width:768px) {
    max-width: 100%;
  }
`;